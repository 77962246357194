import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';

class DialogoAsociarCultivo extends Component {

    constructor (props) {
        super(props);
        this.state = {
            grupoDeCultivoSeleccionado: '',
            cultivoSeleccionado: '',
            estadoFenologicoSeleccionado: '',
        };
    }

    onChangeGrupoDeCultivo = e => {
        this.setState({
            grupoDeCultivoSeleccionado: Number(e.nativeEvent.target.value),
            cultivoSeleccionado: '',
            estadoFenologicoSeleccionado: ''
        });
    }

    onChangeCultivo = e => {
        this.setState({
            cultivoSeleccionado: Number(e.nativeEvent.target.value),
            estadoFenologicoSeleccionado: ''
        });
    }

    onChangeEstadoFenologico = e => {
        if (0 !== e.nativeEvent.target.value){
            this.setState({
                estadoFenologicoSeleccionado: Number(e.nativeEvent.target.value)
            });
        }
    }

    ordenaEstadosFenologicos = (a,b) => {
        if (a.orden > b.orden) return 1;
        else if (a.orden < b.orden) return -1;
        else return 0;
    }

    ordenaGruposDeCultivo = (a,b) => {
        const locale = APP_CONFIG.DEFAULT_SELECTED_LOCALE;
        if (a.nombre[locale] > b.nombre[locale]) return 1;
        else if (a.nombre[locale] < b.nombre[locale]) return -1;
        return 0;
    }

    // On dialog aceptar
    onDialogAceptar = () => {
        // Guardamos la nueva asociación
        if (('' !== this.state.estadoFenologicoSeleccionado) && (0 !== this.state.estadoFenologicoSeleccionado)){
            this.props.onSelect(this.state.estadoFenologicoSeleccionado);
        }
        // Cerramos el dialog
        this.props.onClose();
    }

    render(){

        // Si dialogo cerrado
        if (!this.props.show) return '';

        const grupoDeCultivo = this.state.grupoDeCultivoSeleccionado || '';
        const cultivo = this.state.cultivoSeleccionado || '';
        const estadoFenologico = this.state.estadoFenologicoSeleccionado || '';

        return (
            <div className='dialogo-asociar-cultivo-container'>
                <div className='dialogo-asociar-cultivo-modal'>
                    <div className='dialogo-asociar-cultivo-content'>
                        <div className='dialogo-asociar-cultivo-content-titulo'>
                            <HeaderBiovert type='t1'>Asociar cultivo</HeaderBiovert>
                            <div className='dialogo-asociar-cultivo-content-close' onClick={() => this.props.onClose()}>
                                <span className='dialogo-asociar-cultivo-content-close-icon'>&times;</span>
                            </div>
                        </div>

                        <div className='dialogo-asociar-cultivo-vertical-spacer'></div>

                        <div className='dialogo-asociar-cultivo-content-selects'>


                            {/* grupos de cultivo */}
                            <select
                                id='grupo_de_cultivo'
                                onChange={this.onChangeGrupoDeCultivo}
                                onClick={this.onChangeGrupoDeCultivo}
                                value={grupoDeCultivo}
                                className='dialogo-asociar-cultivo-select'
                                title='Grupo de cultivo'>
                                <option key={'gdc'} value={''}></option>
                                {this.props.gruposDeCultivo.sort(this.ordenaGruposDeCultivo).map(
                                    item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                )}
                            </select>

                            {/* cultivo */}
                            <select
                                id='cultivo'
                                onChange={this.onChangeCultivo}
                                onClick={this.onChangeCultivo}
                                value={cultivo}
                                className='dialogo-asociar-cultivo-select'
                                title='Cultivo'>
                                <option key={'cultivo'} value={''}></option>
                                {this.props.cultivos.filter(item => item.grupo_de_cultivo === grupoDeCultivo).map(
                                    item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                )}
                            </select>

                            {/* estado fenologico */}
                            <select
                                id='estado-fenologico'
                                onChange={this.onChangeEstadoFenologico}
                                onClick={this.onChangeEstadoFenologico}
                                value={estadoFenologico}
                                className='dialogo-asociar-cultivo-select'
                                title='Estado fenologico'>
                                <option key={'estadofenologico'} value={''}></option>
                                {this.props.estadosFenologicos.filter(item => item.cultivo === cultivo).sort(this.ordenaEstadosFenologicos).map(
                                    item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                )}
                            </select>

                        </div>

                        <div className='dialogo-asociar-cultivo-vertical-spacer'></div>

                        <div className='dialogo-asociar-cultivo-button-right'>
                            <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                        </div>

                    </div>
                </div>
            </div>
        );

    }

}


export default DialogoAsociarCultivo;
