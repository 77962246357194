import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasSolucionesPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeSoluciones: null,
            idSolucion: null,
            solucion: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                descripcion: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de soluciones
        this.getSoluciones();
    }

    // Get soluciones de la base de datos
    getSoluciones(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.SOLUCIONES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeSoluciones: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });

    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idSolucion: null,
        solucion: {
            nombre: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
            descripcion: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
        },
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario gestionar cambios
    handleChangeNombre = event => {
        const s = this.state;
        s.solucion.nombre[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    handleChangeDescripcion = event => {
        const s = this.state;
        s.solucion.descripcion[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    // Guardar solucion
    guardarFormularioSolucion = () => {
        // Comprobamos campo no vacio
        if (this.state.solucion.nombre[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.solucion.nombre[this.state.idiomaSeleccionado].length) return true;

        // Llamamos al endpoint
        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.SOLUCIONES,
            method: '',
            authorized: true,
            contentType: 'application/json',
            data: this.state.solucion
        };

        if (null === this.state.idSolucion){
            // Si es nuevo
            requestData.method = 'POST';
        } else {
            // Si estamos editando
            requestData.endpoint = requestData.endpoint + this.state.idSolucion + '/';
            requestData.method = 'PUT';
        }
        ApiService.request(requestData)
            .then(() => {
                this.resultadoGuardarOk();
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar la solución</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Resultado de guardar
    resultadoGuardarOk = () => {
        this.getSoluciones();
        toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Solución guardada con éxito</span></div>);
        this.setState({
            idSolucion: null,
            solucion: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                descripcion: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
        });
    }

    // Editar solucion
    editarSolucionCallback = (event) => {return this.editarSolucion(Number(event.target.id));}

    editarSolucion = (id) => {
        // Cargamos los datos de la solucion
        let auxSolucion = null;
        for (let i = 0; i < this.state.listaDeSoluciones.length; ++i){
            if (this.state.listaDeSoluciones[i].id === id){
                auxSolucion = JSON.parse(JSON.stringify(this.state.listaDeSoluciones[i]));
                break;
            }
        }
        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idSolucion: id,
            solucion: auxSolucion,
        });
    }

    // Eliminar solucion
    eliminarSolucionCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let nombre = null;
        for (let i = 0; i < this.state.listaDeSoluciones.length; ++i){
            if (this.state.listaDeSoluciones[i].id === Number(id)){
                nombre = this.state.listaDeSoluciones[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === nombre) return true;
        if (window.confirm('¿Desea eliminar la solución ' + nombre + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.SOLUCIONES + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Solución eliminada con éxito</span></div>);
                    this.getSoluciones();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar la solución</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeSoluciones == null) return [];
        // Contenido
        return this.state.listaDeSoluciones.map(item =>
            ({
                id: item.id,
                fields: [ item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] ],
                acciones: [
                    { label: 'Editar', callback: this.editarSolucionCallback },
                    { label: 'Eliminar', callback: this.eliminarSolucionCallback }
                ]
            })
        );
    }

    renderListaDeElementos(){
        return ([
            <div key='elemento_lista' className='solucion-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Soluciones' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Soluciones</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir solución' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Nombre' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarSolucion} noShorten={true}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.solucion) return (<div key='eci'> Error cargando la solucion</div>);

        // Values
        return ([
            <div key='solucion_add_edit' className='solucion-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='solucion-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Soluciones', 'Nueva solución' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Soluciones', 'Editar solución' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nueva solución</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.solucion.nombre[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='solucion-formulario'>
                        <div className='solucion-form-lbl-nombre'>
                            Nombre solución
                        </div>
                        <div className='solucion-form-nombre'>
                            <input
                                id='solucion-add-edit'
                                type='text'
                                value={this.state.solucion.nombre[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeNombre}
                                size='50'
                                className='solucion-add-edit-text-input'
                            />
                        </div>
                        <div className='solucion-form-lbl-nombre'>
                            Descripción solución
                        </div>
                        <div className='solucion-form-nombre'>
                            <textarea
                                type='text'
                                value={this.state.solucion.descripcion[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeDescripcion}
                                className='solucion-add-edit-textarea-input'
                                placeholder='Descripción'
                            />
                        </div>
                    </div>
                </GreyboxBiovert>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarFormularioSolucion}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasSolucionesPage;
