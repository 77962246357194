// Global application setup and settings

// Config
const APP_CONFIG = {
    RUTAS: {
        MAESTRAS_MAGNITUDES: '/maestras/magnitudes',
        MAESTRAS_ICONOS: '/maestras/iconos',
        MAESTRAS_FAMILIAS: '/maestras/familias',
        MAESTRAS_ENVASES: '/maestras/envases',
        MAESTRAS_COMPONENTESQUIMICOS: '/maestras/componentes_quimicos',
        MAESTRAS_CULTIVOS: '/maestras/cultivos',
        MAESTRAS_ESTADOSFENOLOGICOS: '/maestras/estados_fenologicos',
        MAESTRAS_SOLUCIONES: '/maestras/soluciones',
        PRODUCTOS_GENERICOS_ADD: '/productos/genericos/new',
        PRODUCTOS_GENERICOS_VIEW: '/productos/genericos/view',
        PRODUCTOS_ACABADOS_ADD: '/productos/acabados/new',
        PRODUCTOS_ACABADOS_VIEW: '/productos/acabados/view',
        PRODUCTOS_EDIT: '/productos/edit',
        CULTIVOS_VIEW: '/cultivos/ver',
        CULTIVOS_DETAIL: '/cultivos/detalle',
        ENSAYOS_ADD: '/ensayos/new',
        ENSAYOS_VIEW: '/ensayos/ver',
        ENSAYOS_DETAIL: '/ensayos/detalle',
        SOLUCIONES_VIEW: '/soluciones/ver',
        INFORME_VIEW: '/informe/ver',
        SOLUCIONES_DETAIL: '/soluciones/detalle',
        USUARIOS: '/account/usuarios',
        LOGIN_CLAVE_RESTAURAR: '/account/clave/restaurar',
        LOGIN_CLAVE_RECUPERAR: '/account/clave/recuperar',
        PERFIL: '/account/perfil',
        LOGOUT: '/logout',
        LOGIN: '/login',
        HOME: '/',
    },
    ENDPOINTS: {
        AUTH_LOGIN: '/api/auth/login/',
        AUTH_LOGOUT: '/api/auth/logout/',
        AUTH_USER: '/api/auth/user/',
        AUTH_CHANGE_PASSWORD: '/api/auth/password/change/',
        COMPONENTES_QUIMICOS: '/api/componentes/',
        ICONOS: '/api/iconos/',
        MAGNITUDES: '/api/magnitudes/',
        ENVASES: '/api/envases/',
        FAMILIAS: '/api/familias/',
        CULTIVOS: '/api/cultivos/',
        ENSAYOS: '/api/ensayos/',
        RESULTADOSENSAYO: '/api/resultado_ensayo/',
        ADJUNTOSENSAYO: '/api/adjunto_ensayo/',
        GRUPOS_DE_CULTIVO: '/api/grupos_de_cultivo/',
        ESTADOS_FENOLOGICOS: '/api/estados_fenologicos/',
        PRODUCTOS_GENERICOS: '/api/productos_genericos/',
        PRODUCTOS_GENERICOS_LIGHT: '/api/productos_genericos_light/',
        PRODUCTOS_ACABADOS: '/api/productos_acabados/',
        PRODUCTOS_ACABADOS_LIGHT: '/api/productos_acabados_light/',
        LINEAS_DE_COMPOSICION: '/api/lineas_de_composicion/',
        PAISES: '/api/paises/',
        COMO_SE_APLICA: '/api/como_se_aplica/',
        SOLUCIONES: '/api/soluciones/',
        INFORME_PRODUCTOS_ACABADOS: '/api/informe-productos-acabados/',
        INFORME_PRODUCTOS_GENERICOS: '/api/informe-productos-genericos/',
        COMBINACION_DE_PRODUCTOS: '/api/combinaciones_de_productos/',
    },
    // API_BASE_URL: 'http://localhost:8000',
    // API_BASE_URL: 'https://api-pre.biovert.es',
    API_BASE_URL: 'https://api.biovert.es',
    VERSION: 'v.0.4.9',
    DEFAULT_SELECTED_LOCALE: 'es_ES',
    MIN_PASSWORD_LENGTH: 6,
    MAX_UPLOAD_MEDIA_SIZE_BYTES: 5 * 1000000,
    NUMERO_DECIMALES: 5,
};

// Enum accion seleccionada
const ACCION_SELECCIONADA = {
    ACCION_LIST: 10,
    ACCION_ADD: 20,
    ACCION_EDIT: 30,
    ACCION_DELETE: 40,
};

module.exports = {
    APP_CONFIG,
    ACCION_SELECCIONADA,
};
