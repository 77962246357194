import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import '../index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import SelectMultiplePastilla from '../../../components/select-multiple-pastilla';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';
import ResultadosEnsayo from '../../../components/resultados-ensayo';
import AdjuntoEnsayo from '../../../components/adjuntos-ensayo';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class EnsayosDetailPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            ensayo: null,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            redirect: false,
            loading: false,
            listaDeCultivos: null,
            listaDePaises: null,
            listaDeProductosGenericos: null,
            imagenCatalogoAux: null,
            resultadosEnsayo: [],
            resultadosEnsayoAEliminar: [],
            adjuntosEnsayo: [],
            adjuntosEnsayoAEliminar: [],
        };
    }

    componentDidMount(){
        // Obtenemos el ensayo
        if ((null !== this.state.id) && (undefined !== this.state.id)) {
            this.getEnsayo();
            this.getCultivos();
            this.getPaises();
            this.getProductosGenericos();
        }
    }

    getEnsayo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS + this.state.id + '/',
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };

        ApiService.request(requestData)
            .then((response) => {
                let ensayo = JSON.parse(response);

                // Correcciones
                if (null === ensayo.nombre) ensayo.nombre = { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' };
                if (null === ensayo.descripcion) ensayo.descripcion = { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' };
                if (null === ensayo.dosis) ensayo.dosis = { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' };
                if (null === ensayo.momento_de_aplicacion) ensayo.momento_de_aplicacion = { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' };

                this.setState({ ensayo });
            })
            .catch((error) => {
                this.setState({ redirect: true });
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get cultivos de la base de datos
    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeCultivos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get paises de la base de datos
    getPaises(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PAISES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDePaises: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get productos genericos de la base de datos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS_LIGHT,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductosGenericos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    ordenaCultivos = (a,b) => {
        if ((a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] || '') > (b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] || '')) return 1;
        if ((a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] || '') < (b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] || '')) return -1;
        return 0;
    }

    // Calcular select cultivo
    calculaSelectCultivo = () => {
        if (Array.isArray(this.state.listaDeCultivos) && this.state.listaDeCultivos.length > 0){
            return (
                this.state.listaDeCultivos.sort(this.ordenaCultivos).map(
                    item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                )
            );
        } else {
            return '';
        }
    }

    // Calcular select pais
    calculaSelectPais = () => {
        if (Array.isArray(this.state.listaDePaises) && this.state.listaDePaises.length > 0){
            return (
                this.state.listaDePaises.map(
                    item => <option key={item.codigo_iso_alfa_2} value={item.codigo_iso_alfa_2}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                )
            );
        } else {
            return '';
        }
    }

    handleChangeCultivo = e => {
        const s = this.state;
        s.ensayo.cultivo = Number(e.nativeEvent.target.value);
        if (0 === s.ensayo.cultivo) s.ensayo.cultivo = null;
        this.setState(s);
    }

    handleChangePais = e => {
        const s = this.state;
        s.ensayo.pais = e.nativeEvent.target.value;
        if ('' === s.ensayo.pais) s.ensayo.pais = null;
        this.setState(s);
    }

    // Guardar ensayo
    guardarEnsayo = () => {

        // Comprobamos campos
        if (this.state.ensayo.fecha.length < 8){
            toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Debe indicar una fecha correcta</span></div>);
            return true;
        }

        // Loading
        this.setState({ loading: true });

        // Llamamos al endpoint
        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS + this.state.ensayo.id + '/',
            method: 'PUT',
            authorized: true,
            contentType: 'application/json',
            data: this.state.ensayo
        };

        ApiService.request(requestData)
            .then(() => {
                this.guardarResultados();
            })
            .catch((error) => {
                this.setState({ loading: false });
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el ensayo</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    guardarResultados = () => {
        // Guardamos los resultados nuevos
        this.state.resultadosEnsayo.forEach(item => {

            // Borramos el id temporal
            delete item.id;

            // Guardamos el resultado
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.RESULTADOSENSAYO,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: item
            };

            // Ensayo
            requestData.data.ensayo = this.state.ensayo.id;

            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        // Eliminamos los resultados antiguos
        this.state.resultadosEnsayoAEliminar.forEach(item => {
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.RESULTADOSENSAYO + item + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json',
            };
            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        this.guardarAdjuntos();
    }

    guardarAdjuntos = () => {
        // Creamos nuevos adjuntos si id negativo
        this.state.adjuntosEnsayo.filter(item => item.id < 0).forEach(item => {
            let imagenTemporal = null;

            // Borramos el id temporal
            delete item.id;

            // Guardamos la imagen temporal
            if (undefined !== item.imagen) {
                imagenTemporal = item.imagen;
                delete item.imagen;
            }

            // Guardamos el resultado
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ADJUNTOSENSAYO,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: item
            };

            // Ensayo
            requestData.data.ensayo = this.state.ensayo.id;

            ApiService.request(requestData)
                .then(response => {
                    // Guardamos la imagen del adjunto recien creado
                    if (null !== imagenTemporal){
                        const formData = new FormData();
                        formData.append('imagen', imagenTemporal);

                        let requestData = {
                            endpoint: APP_CONFIG.ENDPOINTS.ADJUNTOSENSAYO + JSON.parse(response).id + '/upload_imagen/',
                            method: 'POST',
                            authorized: true,
                            contentType: '',
                            data: formData
                        };

                        ApiService.request(requestData)
                            .then(() => {})
                            .catch((error) => {
                                console.log('Error: ' + JSON.stringify(error));
                            });
                    }
                })
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        // Actualizamos adjuntos con id > 0
        this.state.adjuntosEnsayo.filter(item => item.id > 0).forEach(item => {

            // Actualizamos el adjunto
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ADJUNTOSENSAYO + item.id + '/',
                method: 'PUT',
                authorized: true,
                contentType: 'application/json',
                data: {
                    orden: item.orden,
                    descripcion: item.descripcion
                }
            };

            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        // Eliminamos adjuntos marcados para eliminar
        this.state.adjuntosEnsayoAEliminar.forEach(item => {
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ADJUNTOSENSAYO + item + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json',
            };
            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        this.guardarImagen();
    }

    guardarImagen = () => {
        // Imagen catalogo
        if ('delete' === this.state.imagenCatalogoAux){

            // Llamamos al endpoint para borrar imagen
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS + this.state.ensayo.id + '/delete_imagen/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };

            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Error: ' + JSON.stringify(error));
                });

        } else if (null !== this.state.imagenCatalogoAux) {

            // Upload imagen
            const formData = new FormData();
            formData.append('imagen', this.state.imagenCatalogoAux);

            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS + this.state.ensayo.id + '/upload_imagen/',
                method: 'POST',
                authorized: true,
                contentType: '',
                data: formData
            };

            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });

        }
        else this.resultadoGuardarOk();
    }

    // Resultado de guardar
    resultadoGuardarOk = () => {
        toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Ensayo guardado con éxito</span></div>);
        this.setState({ redirect: true, loading: false });
    }

    // Productos
    handleChangeProductos = productos => {
        let ensayo = this.state.ensayo;
        ensayo.productos = productos;
        this.setState({ ensayo });
    }

    // Imagenes
    handleChangeImagenCatalogo = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            this.setState({ imagenCatalogoAux: file });
        }
    }

    handleChangeEliminarImagenCatalogo = () => {
        this.setState({ imagenCatalogoAux: 'delete' });
    }

    // Calcula imagen catalogo a mostrar
    calculaImagenCatalogoMostrar(){
        if ('delete' === this.state.imagenCatalogoAux) return '';
        if (null === this.state.imagenCatalogoAux) return this.state.ensayo.imagen;
    }

    render() {

        // Si no hay ensayo volvemos al view
        if (this.state.redirect) return (<Redirect to={APP_CONFIG.RUTAS.ENSAYOS_VIEW}/>);

        // Si aun no tenemos datos
        if (null === this.state.ensayo
            || null === this.state.listaDeCultivos
            || null === this.state.listaDePaises
            || null === this.state.listaDeProductosGenericos)
            return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Si estamos cargando
        if (this.state.loading) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Ensayo
        return (
            <PaginaBiovert>
                <div className='ensayo-detail-container'>
                    {/* Breadcrumb  + select idioma */}

                    <div className='ensayo-breadcrumb-idioma'>
                        <BreadCrumbBiovert value = {[
                            'Ensayos',
                            this.state.ensayo.codigo
                        ]}/>
                        <SelectIdioma onSelect={idioma => this.setState({ idiomaSeleccionado: idioma })}/>
                    </div>

                    {/* Titulo */}
                    <HeaderBiovert type='t1'>Ensayo {this.state.ensayo.codigo}</HeaderBiovert>

                    {/* Formulario */}
                    <GreyboxBiovert>
                        <HeaderBiovert type='t1'>Datos generales</HeaderBiovert>

                        <div className='ensayo-formulario'>

                            {/* Nombre y codigo de ensayo */}
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Titular</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.ensayo.nombre[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.ensayo.nombre[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='ensayo-text-input'
                                        />
                                    </div>
                                </div>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Código</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.ensayo.codigo || ''}
                                            onChange={e => {const s = this.state;s.ensayo.codigo = e.target.value;this.setState(s);}}
                                            className='ensayo-text-input'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Fecha + ROI */}
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Fecha (aaaa-mm-dd)</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.ensayo.fecha}
                                            onChange={e => {const s = this.state;s.ensayo.fecha = e.target.value;this.setState(s);}}
                                            className='ensayo-text-input'
                                        />
                                    </div>
                                </div>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>ROI (N.NN)</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <input
                                            type='number'
                                            min={-9999.99}
                                            max={9999.99}
                                            step={0.01}
                                            value={this.state.ensayo.roi || ''}
                                            onChange={e => {const s = this.state;s.ensayo.roi = e.target.value;this.setState(s);}}
                                            className='ensayo-text-input'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Cultivo + Pais */}
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Cultivo</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <select
                                            onChange={this.handleChangeCultivo}
                                            onClick={this.handleChangeCultivo}
                                            defaultValue={this.state.ensayo.cultivo || ''}
                                            className='ensayo-text-input'>
                                            <option key={'default-ensayo'} value={''}></option>
                                            {this.calculaSelectCultivo()}
                                        </select>
                                    </div>
                                </div>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Pais</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <select
                                            onChange={this.handleChangePais}
                                            onClick={this.handleChangePais}
                                            defaultValue={this.state.ensayo.pais || ''}
                                            className='ensayo-text-input'>
                                            <option key={'default-ensayo'} value={''}></option>
                                            {this.calculaSelectPais()}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Descripcion */}
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Descripción</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <textarea
                                            type='text'
                                            value={this.state.ensayo.descripcion[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.ensayo.descripcion[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='ensayo-textarea-input'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* dosis + momento de aplicacion */}
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Dosis</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <textarea
                                            type='text'
                                            value={this.state.ensayo.dosis[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.ensayo.dosis[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='ensayo-textarea-input'
                                        />
                                    </div>
                                </div>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Momento de aplicación</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <textarea
                                            type='text'
                                            value={this.state.ensayo.momento_de_aplicacion[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.ensayo.momento_de_aplicacion[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='ensayo-textarea-input'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* cultivo + pais */}

                            <div className='ensayo-formulario-vertical-spacer'></div>

                        </div>

                    </GreyboxBiovert>

                    {/* Productos */}
                    <div className='combinacion-de-productos-biovert-select-multiple-cultivos'>
                        <SelectMultiplePastilla
                            titulo='Productos'
                            tituloAsociar='Asociar producto'
                            tituloSeleccionar='Seleccione un producto'
                            elementosSeleccionados={this.state.ensayo.productos}
                            listaDeElementos={this.state.listaDeProductosGenericos.map(producto => ({
                                id: producto.id,
                                value: producto.nombre ? producto.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] : '',
                                description: producto.codigo
                            }))}
                            onChange={this.handleChangeProductos}
                        />
                    </div>

                    {/* Resultados ensayo */}
                    <ResultadosEnsayo
                        ensayo={this.state.ensayo.id}
                        idioma={this.state.idiomaSeleccionado}
                        onChange={(r,re) => this.setState({ resultadosEnsayo: r, resultadosEnsayoAEliminar: re })}
                    />

                    {/* Adjuntos ensayo */}
                    <AdjuntoEnsayo
                        ensayo={this.state.ensayo.id}
                        idioma={this.state.idiomaSeleccionado}
                        onChange={(a,ae) => this.setState({ adjuntosEnsayo: a, adjuntosEnsayoAEliminar: ae })}
                    />

                    {/* Imagen catalogo */}
                    <SeccionImagenBiovert
                        titulo='Imagen catálogo'
                        urlImagen={this.calculaImagenCatalogoMostrar()}
                        onDelete={this.handleChangeEliminarImagenCatalogo}
                        onUpload={this.handleChangeImagenCatalogo}/>

                    {/* Aceptar cancelar */}
                    <SeccionAceptarCancelarBiovert onCancel={() => {this.setState({ redirect: true });}} onAccept={this.guardarEnsayo}/>

                </div>
            </PaginaBiovert>
        );
    }
}

export default EnsayosDetailPage;
