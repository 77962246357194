// Available locales

const LOCALES = {
    es_ES: 'Espa\u00f1ol (Espa\u00f1a)',
    en_US: 'Ingl\u00e9s (Estados Unidos)',
    fr_FR: 'Franc\u00e9s (Francia)',
    pt_BR: 'Portugu\u00e9s (Brasil)',
    ddddd: '----',
    af_NA: 'Afrik\u00e1ans (Namibia)',
    af_ZA: 'Afrik\u00e1ans (Sud\u00e1frica)',
    ak_GH: 'Akan (Ghana)',
    sq_AL: 'Alban\u00e9s (Albania)',
    sq_XK: 'Alban\u00e9s (Kosovo)',
    sq_MK: 'Alban\u00e9s (Macedonia)',
    de_DE: 'Alem\u00e1n (Alemania)',
    de_AT: 'Alem\u00e1n (Austria)',
    de_BE: 'Alem\u00e1n (B\u00e9lgica)',
    de_LI: 'Alem\u00e1n (Liechtenstein)',
    de_LU: 'Alem\u00e1n (Luxemburgo)',
    de_CH: 'Alem\u00e1n (Suiza)',
    am_ET: 'Am\u00e1rico (Etiop\u00eda)',
    ar_SA: '\u00C1rabe (Arabia Saud\u00ed)',
    ar_DZ: '\u00C1rabe (Argelia)',
    ar_BH: '\u00C1rabe (Bar\u00e9in)',
    ar_QA: '\u00C1rabe (Catar)',
    ar_TD: '\u00C1rabe (Chad)',
    ar_KM: '\u00C1rabe (Comoras)',
    ar_EG: '\u00C1rabe (Egipto)',
    ar_AE: '\u00C1rabe (Emiratos \u00c1rabes Unidos)',
    ar_ER: '\u00C1rabe (Eritrea)',
    ar_IQ: '\u00C1rabe (Iraq)',
    ar_IL: '\u00C1rabe (Israel)',
    ar_JO: '\u00C1rabe (Jordania)',
    ar_KW: '\u00C1rabe (Kuwait)',
    ar_LB: '\u00C1rabe (L\u00edbano)',
    ar_LY: '\u00C1rabe (Libia)',
    ar_MA: '\u00C1rabe (Marruecos)',
    ar_MR: '\u00C1rabe (Mauritania)',
    ar_OM: '\u00C1rabe (Om\u00e1n)',
    ar_EH: '\u00C1rabe (S\u00e1hara Occidental)',
    ar_SY: '\u00C1rabe (Siria)',
    ar_SO: '\u00C1rabe (Somalia)',
    ar_SS: '\u00C1rabe (Sud\u00e1n del Sur)',
    ar_SD: '\u00C1rabe (Sud\u00e1n)',
    ar_PS: '\u00C1rabe (Territorios Palestinos)',
    ar_TN: '\u00C1rabe (T\u00fanez)',
    ar_YE: '\u00C1rabe (Yemen)',
    ar_DJ: '\u00C1rabe (Yibuti)',
    hy_AM: 'Armenio (Armenia)',
    as_IN: 'Asam\u00e9s (India)',
    az_AZ: 'Azer\u00ed (Azerbaiy\u00e1n)',
    bn_BD: 'Bengal\u00ed (Banglad\u00e9s)',
    bn_IN: 'Bengal\u00ed (India)',
    be_BY: 'Bielorruso (Bielorrusia)',
    my_MM: 'Birmano (Myanmar (Birmania))',
    nb_NO: 'Bokmal noruego (Noruega)',
    nb_SJ: 'Bokmal noruego (Svalbard y Jan Mayen)',
    bs_BA: 'Bosnio (Bosnia-Herzegovina)',
    br_FR: 'Bret\u00f3n (Francia)',
    bg_BG: 'B\u00falgaro (Bulgaria)',
    ks_IN: 'Cachemiro (India)',
    kn_IN: 'Canar\u00e9s (India)',
    ca_AD: 'Catal\u00e1n (Andorra)',
    ca_ES: 'Catal\u00e1n (Espa\u00f1a)',
    ca_FR: 'Catal\u00e1n (Francia)',
    ca_IT: 'Catal\u00e1n (Italia)',
    cs_CZ: 'Checo (Rep\u00fablica Checa)',
    zh_CN: 'Chino (China)',
    zh_HK: 'Chino (RAE de Hong Kong (China))',
    zh_MO: 'Chino (RAE de Macao (China))',
    zh_SG: 'Chino (Singapur)',
    zh_TW: 'Chino (Taiw\u00e1n)',
    si_LK: 'Cingal\u00e9s (Sri Lanka)',
    ko_KP: 'Coreano (Corea del Norte)',
    ko_KR: 'Coreano (Corea del Sur)',
    kw_GB: 'C\u00f3rnico (Reino Unido)',
    hr_BA: 'Croata (Bosnia-Herzegovina)',
    hr_HR: 'Croata (Croacia)',
    da_DK: 'Dan\u00e9s (Dinamarca)',
    da_GL: 'Dan\u00e9s (Groenlandia)',
    dz_BT: 'Dzongkha (But\u00e1n)',
    sk_SK: 'Eslovaco (Eslovaquia)',
    sl_SI: 'Esloveno (Eslovenia)',
    es_AR: 'Espa\u00f1ol (Argentina)',
    es_BO: 'Espa\u00f1ol (Bolivia)',
    es_EA: 'Espa\u00f1ol (Ceuta y Melilla)',
    es_CL: 'Espa\u00f1ol (Chile)',
    es_CO: 'Espa\u00f1ol (Colombia)',
    es_CR: 'Espa\u00f1ol (Costa Rica)',
    es_CU: 'Espa\u00f1ol (Cuba)',
    es_EC: 'Espa\u00f1ol (Ecuador)',
    es_SV: 'Espa\u00f1ol (El Salvador)',
    es_US: 'Espa\u00f1ol (Estados Unidos)',
    es_PH: 'Espa\u00f1ol (Filipinas)',
    es_GT: 'Espa\u00f1ol (Guatemala)',
    es_GQ: 'Espa\u00f1ol (Guinea Ecuatorial)',
    es_HN: 'Espa\u00f1ol (Honduras)',
    es_IC: 'Espa\u00f1ol (islas Canarias)',
    es_MX: 'Espa\u00f1ol (M\u00e9xico)',
    es_NI: 'Espa\u00f1ol (Nicaragua)',
    es_PA: 'Espa\u00f1ol (Panam\u00e1)',
    es_PY: 'Espa\u00f1ol (Paraguay)',
    es_PE: 'Espa\u00f1ol (Per\u00fa)',
    es_PR: 'Espa\u00f1ol (Puerto Rico)',
    es_DO: 'Espa\u00f1ol (Rep\u00fablica Dominicana)',
    es_UY: 'Espa\u00f1ol (Uruguay)',
    es_VE: 'Espa\u00f1ol (Venezuela)',
    et_EE: 'Estonio (Estonia)',
    eu_ES: 'Euskera (Espa\u00f1a)',
    ee_GH: 'Ew\u00e9 (Ghana)',
    ee_TG: 'Ew\u00e9 (Togo)',
    fo_FO: 'Fero\u00e9s (Islas Feroe)',
    fi_FI: 'Fin\u00e9s (Finlandia)',
    fr_DZ: 'Franc\u00e9s (Argelia)',
    fr_BE: 'Franc\u00e9s (B\u00e9lgica)',
    fr_BJ: 'Franc\u00e9s (Ben\u00edn)',
    fr_BF: 'Franc\u00e9s (Burkina Faso)',
    fr_BI: 'Franc\u00e9s (Burundi)',
    fr_CM: 'Franc\u00e9s (Camer\u00fan)',
    fr_CA: 'Franc\u00e9s (Canad\u00e1)',
    fr_TD: 'Franc\u00e9s (Chad)',
    fr_KM: 'Franc\u00e9s (Comoras)',
    fr_CI: 'Franc\u00e9s (Costa de Marfil)',
    fr_GA: 'Franc\u00e9s (Gab\u00f3n)',
    fr_GP: 'Franc\u00e9s (Guadalupe)',
    fr_GF: 'Franc\u00e9s (Guayana Francesa)',
    fr_GQ: 'Franc\u00e9s (Guinea Ecuatorial)',
    fr_GN: 'Franc\u00e9s (Guinea)',
    fr_HT: 'Franc\u00e9s (Hait\u00ed)',
    fr_LU: 'Franc\u00e9s (Luxemburgo)',
    fr_MG: 'Franc\u00e9s (Madagascar)',
    fr_ML: 'Franc\u00e9s (Mali)',
    fr_MA: 'Franc\u00e9s (Marruecos)',
    fr_MQ: 'Franc\u00e9s (Martinica)',
    fr_MU: 'Franc\u00e9s (Mauricio)',
    fr_MR: 'Franc\u00e9s (Mauritania)',
    fr_YT: 'Franc\u00e9s (Mayotte)',
    fr_MC: 'Franc\u00e9s (M\u00f3naco)',
    fr_NE: 'Franc\u00e9s (N\u00edger)',
    fr_NC: 'Franc\u00e9s (Nueva Caledonia)',
    fr_PF: 'Franc\u00e9s (Polinesia Francesa)',
    fr_CF: 'Franc\u00e9s (Rep\u00fablica Centroafricana)',
    fr_CG: 'Franc\u00e9s (Rep\u00fablica del Congo)',
    fr_CD: 'Franc\u00e9s (Rep\u00fablica Democr\u00e1tica del Congo)',
    fr_RE: 'Franc\u00e9s (Reuni\u00f3n)',
    fr_RW: 'Franc\u00e9s (Ruanda)',
    fr_BL: 'Franc\u00e9s (San Bartolom\u00e9)',
    fr_MF: 'Franc\u00e9s (San Mart\u00edn)',
    fr_PM: 'Franc\u00e9s (San Pedro y Miquel\u00f3n)',
    fr_SN: 'Franc\u00e9s (Senegal)',
    fr_SC: 'Franc\u00e9s (Seychelles)',
    fr_SY: 'Franc\u00e9s (Siria)',
    fr_CH: 'Franc\u00e9s (Suiza)',
    fr_TG: 'Franc\u00e9s (Togo)',
    fr_TN: 'Franc\u00e9s (T\u00fanez)',
    fr_VU: 'Franc\u00e9s (Vanuatu)',
    fr_WF: 'Franc\u00e9s (Wallis y Futuna)',
    fr_DJ: 'Franc\u00e9s (Yibuti)',
    fy_NL: 'Fris\u00f3n occidental (Pa\u00edses Bajos)',
    ff_CM: 'Fula (Camer\u00fan)',
    ff_GN: 'Fula (Guinea)',
    ff_MR: 'Fula (Mauritania)',
    ff_SN: 'Fula (Senegal)',
    gd_GB: 'Ga\u00e9lico escoc\u00e9s (Reino Unido)',
    gv_IM: 'Ga\u00e9lico man\u00e9s (Isla de Man)',
    cy_GB: 'Gal\u00e9s (Reino Unido)',
    gl_ES: 'Gallego (Espa\u00f1a)',
    lg_UG: 'Ganda (Uganda)',
    ka_GE: 'Georgiano (Georgia)',
    el_CY: 'Griego (Chipre)',
    el_GR: 'Griego (Grecia)',
    kl_GL: 'Groenland\u00e9s (Groenlandia)',
    gu_IN: 'Gujarati (India)',
    ha_GH: 'Hausa (Ghana)',
    ha_NE: 'Hausa (N\u00edger)',
    ha_NG: 'Hausa (Nigeria)',
    he_IL: 'Hebreo (Israel)',
    hi_IN: 'Hindi (India)',
    hu_HU: 'H\u00fangaro (Hungr\u00eda)',
    ig_NG: 'Igbo (Nigeria)',
    id_ID: 'Indonesio (Indonesia)',
    en_AI: 'Ingl\u00e9s (Anguila)',
    en_AG: 'Ingl\u00e9s (Antigua y Barbuda)',
    en_AU: 'Ingl\u00e9s (Australia)',
    en_BS: 'Ingl\u00e9s (Bahamas)',
    en_BB: 'Ingl\u00e9s (Barbados)',
    en_BE: 'Ingl\u00e9s (B\u00e9lgica)',
    en_BZ: 'Ingl\u00e9s (Belice)',
    en_BM: 'Ingl\u00e9s (Bermudas)',
    en_BW: 'Ingl\u00e9s (Botsuana)',
    en_CM: 'Ingl\u00e9s (Camer\u00fan)',
    en_CA: 'Ingl\u00e9s (Canad\u00e1)',
    en_DG: 'Ingl\u00e9s (Diego Garc\u00eda)',
    en_DM: 'Ingl\u00e9s (Dominica)',
    en_ER: 'Ingl\u00e9s (Eritrea)',
    en_PH: 'Ingl\u00e9s (Filipinas)',
    en_FJ: 'Ingl\u00e9s (Fiyi)',
    en_GM: 'Ingl\u00e9s (Gambia)',
    en_GH: 'Ingl\u00e9s (Ghana)',
    en_GI: 'Ingl\u00e9s (Gibraltar)',
    en_GD: 'Ingl\u00e9s (Granada)',
    en_GU: 'Ingl\u00e9s (Guam)',
    en_GG: 'Ingl\u00e9s (Guernesey)',
    en_GY: 'Ingl\u00e9s (Guyana)',
    en_IN: 'Ingl\u00e9s (India)',
    en_IE: 'Ingl\u00e9s (Irlanda)',
    en_CX: 'Ingl\u00e9s (Isla Christmas)',
    en_IM: 'Ingl\u00e9s (Isla de Man)',
    en_NU: 'Ingl\u00e9s (Isla Niue)',
    en_NF: 'Ingl\u00e9s (Isla Norfolk)',
    en_KY: 'Ingl\u00e9s (Islas Caim\u00e1n)',
    en_CC: 'Ingl\u00e9s (Islas Cocos)',
    en_CK: 'Ingl\u00e9s (Islas Cook)',
    en_FK: 'Ingl\u00e9s (Islas Malvinas)',
    en_MP: 'Ingl\u00e9s (Islas Marianas del Norte)',
    en_MH: 'Ingl\u00e9s (Islas Marshall)',
    en_UM: 'Ingl\u00e9s (Islas menores alejadas de EE. UU.)',
    en_PN: 'Ingl\u00e9s (Islas Pitcairn)',
    en_SB: 'Ingl\u00e9s (Islas Salom\u00f3n)',
    en_TC: 'Ingl\u00e9s (Islas Turcas y Caicos)',
    en_VG: 'Ingl\u00e9s (Islas V\u00edrgenes Brit\u00e1nicas)',
    en_VI: 'Ingl\u00e9s (Islas V\u00edrgenes de EE. UU.)',
    en_JM: 'Ingl\u00e9s (Jamaica)',
    en_JE: 'Ingl\u00e9s (Jersey)',
    en_KE: 'Ingl\u00e9s (Kenia)',
    en_KI: 'Ingl\u00e9s (Kiribati)',
    en_LS: 'Ingl\u00e9s (Lesoto)',
    en_LR: 'Ingl\u00e9s (Liberia)',
    en_MG: 'Ingl\u00e9s (Madagascar)',
    en_MY: 'Ingl\u00e9s (Malasia)',
    en_MW: 'Ingl\u00e9s (Malaui)',
    en_MT: 'Ingl\u00e9s (Malta)',
    en_MU: 'Ingl\u00e9s (Mauricio)',
    en_FM: 'Ingl\u00e9s (Micronesia)',
    en_MS: 'Ingl\u00e9s (Montserrat)',
    en_NA: 'Ingl\u00e9s (Namibia)',
    en_NR: 'Ingl\u00e9s (Nauru)',
    en_NG: 'Ingl\u00e9s (Nigeria)',
    en_NZ: 'Ingl\u00e9s (Nueva Zelanda)',
    en_PK: 'Ingl\u00e9s (Pakist\u00e1n)',
    en_PW: 'Ingl\u00e9s (Palau)',
    en_PG: 'Ingl\u00e9s (Pap\u00faa Nueva Guinea)',
    en_PR: 'Ingl\u00e9s (Puerto Rico)',
    en_HK: 'Ingl\u00e9s (RAE de Hong Kong (China))',
    en_MO: 'Ingl\u00e9s (RAE de Macao (China))',
    en_GB: 'Ingl\u00e9s (Reino Unido)',
    en_RW: 'Ingl\u00e9s (Ruanda)',
    en_AS: 'Ingl\u00e9s (Samoa Americana)',
    en_WS: 'Ingl\u00e9s (Samoa)',
    en_KN: 'Ingl\u00e9s (San Crist\u00f3bal y Nieves)',
    en_VC: 'Ingl\u00e9s (San Vicente y las Granadinas)',
    en_SH: 'Ingl\u00e9s (Santa Elena)',
    en_LC: 'Ingl\u00e9s (Santa Luc\u00eda)',
    en_SC: 'Ingl\u00e9s (Seychelles)',
    en_SL: 'Ingl\u00e9s (Sierra Leona)',
    en_SG: 'Ingl\u00e9s (Singapur)',
    en_SX: 'Ingl\u00e9s (Sint Maarten)',
    en_SZ: 'Ingl\u00e9s (Suazilandia)',
    en_ZA: 'Ingl\u00e9s (Sud\u00e1frica)',
    en_SS: 'Ingl\u00e9s (Sud\u00e1n del Sur)',
    en_SD: 'Ingl\u00e9s (Sud\u00e1n)',
    en_TZ: 'Ingl\u00e9s (Tanzania)',
    en_IO: 'Ingl\u00e9s (Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico)',
    en_TK: 'Ingl\u00e9s (Tokelau)',
    en_TO: 'Ingl\u00e9s (Tonga)',
    en_TT: 'Ingl\u00e9s (Trinidad y Tobago)',
    en_TV: 'Ingl\u00e9s (Tuvalu)',
    en_UG: 'Ingl\u00e9s (Uganda)',
    en_VU: 'Ingl\u00e9s (Vanuatu)',
    en_ZM: 'Ingl\u00e9s (Zambia)',
    en_ZW: 'Ingl\u00e9s (Zimbabue)',
    ga_IE: 'Irland\u00e9s (Irlanda)',
    is_IS: 'Island\u00e9s (Islandia)',
    it_IT: 'Italiano (Italia)',
    it_SM: 'Italiano (San Marino)',
    it_CH: 'Italiano (Suiza)',
    ja_JP: 'Japon\u00e9s (Jap\u00f3n)',
    km_KH: 'Jemer (Camboya)',
    kk_KZ: 'Kazajo (Kazajist\u00e1n)',
    ki_KE: 'Kikuyu (Kenia)',
    rw_RW: 'Kinyarwanda (Ruanda)',
    ky_KG: 'Kirgu\u00eds (Kirguist\u00e1n)',
    rn_BI: 'Kiroundi (Burundi)',
    lo_LA: 'Laosiano (Laos)',
    lv_LV: 'Let\u00f3n (Letonia)',
    ln_AO: 'Lingala (Angola)',
    ln_CF: 'Lingala (Rep\u00fablica Centroafricana)',
    ln_CG: 'Lingala (Rep\u00fablica del Congo)',
    ln_CD: 'Lingala (Rep\u00fablica Democr\u00e1tica del Congo)',
    lt_LT: 'Lituano (Lituania)',
    lu_CD: 'Luba-katanga (Rep\u00fablica Democr\u00e1tica del Congo)',
    lb_LU: 'Luxemburgu\u00e9s (Luxemburgo)',
    mk_MK: 'Macedonio (Macedonia)',
    ml_IN: 'Malayalam (India)',
    ms_BN: 'Malayo (Brun\u00e9i)',
    ms_MY: 'Malayo (Malasia)',
    ms_SG: 'Malayo (Singapur)',
    mg_MG: 'Malgache (Madagascar)',
    mt_MT: 'Malt\u00e9s (Malta)',
    mr_IN: 'Marathi (India)',
    mn_MN: 'Mongol (Mongolia)',
    nd_ZW: 'Ndebele septentrional (Zimbabue)',
    nl_AW: 'Neerland\u00e9s (Aruba)',
    nl_BE: 'Neerland\u00e9s (B\u00e9lgica)',
    nl_BQ: 'Neerland\u00e9s (Caribe neerland\u00e9s)',
    nl_CW: 'Neerland\u00e9s (Curazao)',
    nl_NL: 'Neerland\u00e9s (Pa\u00edses Bajos)',
    nl_SX: 'Neerland\u00e9s (Sint Maarten)',
    nl_SR: 'Neerland\u00e9s (Surinam)',
    ne_IN: 'Nepal\u00ed (India)',
    ne_NP: 'Nepal\u00ed (Nepal)',
    no_NO: 'Noruego (Noruega)',
    nn_NO: 'Nynorsk noruego (Noruega)',
    or_IN: 'Oriya (India)',
    om_ET: 'Oromo (Etiop\u00eda)',
    om_KE: 'Oromo (Kenia)',
    os_GE: 'Os\u00e9tico (Georgia)',
    os_RU: 'Os\u00e9tico (Rusia)',
    ps_AF: 'Past\u00fan (Afganist\u00e1n)',
    fa_AF: 'Persa (Afganist\u00e1n)',
    fa_IR: 'Persa (Ir\u00e1n)',
    pl_PL: 'Polaco (Polonia)',
    pt_AO: 'Portugu\u00e9s (Angola)',
    pt_CV: 'Portugu\u00e9s (Cabo Verde)',
    pt_GW: 'Portugu\u00e9s (Guinea-Bis\u00e1u)',
    pt_MZ: 'Portugu\u00e9s (Mozambique)',
    pt_PT: 'Portugu\u00e9s (Portugal)',
    pt_MO: 'Portugu\u00e9s (RAE de Macao (China))',
    pt_ST: 'Portugu\u00e9s (Santo Tom\u00e9 y Pr\u00edncipe)',
    pt_TL: 'Portugu\u00e9s (Timor Oriental)',
    pa_IN: 'Punjab\u00ed (India)',
    pa_PK: 'Punjab\u00ed (Pakist\u00e1n)',
    qu_BO: 'Quechua (Bolivia)',
    qu_EC: 'Quechua (Ecuador)',
    qu_PE: 'Quechua (Per\u00fa)',
    rm_CH: 'Retorrom\u00e1nico (Suiza)',
    ro_MD: 'Rumano (Moldavia)',
    ro_RO: 'Rumano (Ruman\u00eda)',
    ru_BY: 'Ruso (Bielorrusia)',
    ru_KZ: 'Ruso (Kazajist\u00e1n)',
    ru_KG: 'Ruso (Kirguist\u00e1n)',
    ru_MD: 'Ruso (Moldavia)',
    ru_RU: 'Ruso (Rusia)',
    ru_UA: 'Ruso (Ucrania)',
    se_FI: 'Sami septentrional (Finlandia)',
    se_NO: 'Sami septentrional (Noruega)',
    se_SE: 'Sami septentrional (Suecia)',
    sg_CF: 'Sango (Rep\u00fablica Centroafricana)',
    sr_BA: 'Serbio (Bosnia-Herzegovina)',
    sr_XK: 'Serbio (Kosovo)',
    sr_ME: 'Serbio (Montenegro)',
    sr_RS: 'Serbio (Serbia)',
    sh_BA: 'Serbocroata (Bosnia-Herzegovina)',
    sn_ZW: 'Shona (Zimbabue)',
    so_ET: 'Somal\u00ed (Etiop\u00eda)',
    so_KE: 'Somal\u00ed (Kenia)',
    so_SO: 'Somal\u00ed (Somalia)',
    so_DJ: 'Somal\u00ed (Yibuti)',
    sv_FI: 'Sueco (Finlandia)',
    sv_AX: 'Sueco (Islas \u00c5land)',
    sv_SE: 'Sueco (Suecia)',
    sw_KE: 'Swahili (Kenia)',
    sw_TZ: 'Swahili (Tanzania)',
    sw_UG: 'Swahili (Uganda)',
    tl_PH: 'Tagalo (Filipinas)',
    th_TH: 'Tailand\u00e9s (Tailandia)',
    ta_IN: 'Tamil (India)',
    ta_MY: 'Tamil (Malasia)',
    ta_SG: 'Tamil (Singapur)',
    ta_LK: 'Tamil (Sri Lanka)',
    te_IN: 'Telugu (India)',
    bo_CN: 'Tibetano (China)',
    bo_IN: 'Tibetano (India)',
    ti_ER: 'Tigri\u00f1a (Eritrea)',
    ti_ET: 'Tigri\u00f1a (Etiop\u00eda)',
    to_TO: 'Tongano (Tonga)',
    tr_CY: 'Turco (Chipre)',
    tr_TR: 'Turco (Turqu\u00eda)',
    uk_UA: 'Ucraniano (Ucrania)',
    ug_CN: 'Uigur (China)',
    ur_IN: 'Urdu (India)',
    ur_PK: 'Urdu (Pakist\u00e1n)',
    uz_AF: 'Uzbeko (Afganist\u00e1n)',
    uz_UZ: 'Uzbeko (Uzbekist\u00e1n)',
    vi_VN: 'Vietnamita (Vietnam)',
    ii_CN: 'Yi de Sichu\u00e1n (China)',
    yo_BJ: 'Yoruba (Ben\u00edn)',
    yo_NG: 'Yoruba (Nigeria)',
    zu_ZA: 'Zul\u00fa (Sud\u00e1frica)'

};

module.exports = {
    LOCALES
};
