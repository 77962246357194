import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { APP_CONFIG } from '../../utils/globales.js';
import ApiService from '../../services/Api.js';
import AuthService from '../../services/AuthService.js';

class LogoutPage extends Component {

    logoutEndpoint = () => {

        //Logout from endpoint
        ApiService.request({
            endpoint: APP_CONFIG.ENDPOINTS.AUTH_LOGOUT,
            method: 'POST',
            authorized: true,
            contentType: 'application/json'
        });
        AuthService.reset();
        return true;
    }

    render() {
        this.logoutEndpoint();
        return <Redirect to={ APP_CONFIG.RUTAS.LOGIN } />;
    }
}

export default LogoutPage;
