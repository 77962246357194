import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import ButtonBiovert from '../button-biovert';
import HeaderBiovert from '../header-biovert';
import ButtonDropdown from '../button-dropdown';
import SelectIdioma from '../select-idioma';

class ResultadosEnsayo extends Component {

    constructor (props) {
        super(props);
        this.state = {
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            resultados: [],
            resultadosAEliminar: [],
            resultadoAEditar: null,
            mostrarDialogo: false,
        };
    }

    componentDidMount(){
        // Obtenemos los resultados del ensayo
        this.getResultados();
    }

    getResultados(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.RESULTADOSENSAYO + '?ensayo=' + this.props.ensayo,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                const resultados = JSON.parse(response);
                this.setState({
                    resultados: resultados,
                    resultadosAEliminar: resultados.map(item => item.id),
                });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    ordenaResultados = (a,b) => {
        if (a.orden > b.orden) return 1;
        else if (a.orden < b.orden) return -1;
        return 0;
    }

    resultadoEnBlanco() {
        return ({
            orden: 0,
            valor: '',
            magnitud: '',
            descripcion: { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' }
        });
    }

    // On move up
    onMoveUp = event => {
        const id = event.target.id;
        let aux;
        let resultados = this.state.resultados;

        resultados.sort(this.ordenaResultados);

        for (let i = 0; i < resultados.length; ++i){
            if (String(resultados[i].id) === id){
                // Si intentamos subir la primera linea
                if (i === 0) break;

                // Si tenemos una linea por arriba
                if (null !== resultados[i - 1]) {
                    aux = resultados[i].orden;
                    resultados[i].orden = resultados[i - 1].orden;
                    resultados[i - 1].orden = aux;
                }
                break;
            }
        }

        this.setState({ resultados });

        // Actualizamos la resultados en el ensayo
        this.props.onChange(resultados, this.state.resultadosAEliminar);
    }

    // On move down
    onMoveDown = event => {
        const id = event.target.id;
        let aux;
        let resultados = this.state.resultados;

        resultados.sort(this.ordenaResultados);

        for (let i = 0; i < resultados.length; ++i){
            if (String(resultados[i].id) === id){
                // Si intentamos bajar la ultima linea
                if (i === resultados.length - 1) break;

                // Si tenemos una linea por debajo
                if (null !== resultados[i + 1]) {
                    aux = resultados[i].orden;
                    resultados[i].orden = resultados[i + 1].orden;
                    resultados[i + 1].orden = aux;
                }

                break;
            }
        }

        this.setState({ resultados });

        // Actualizamos los resultados en el ensayo
        this.props.onChange(resultados, this.state.resultadosAEliminar);
    }

    // On delete
    onDelete = event => {
        const id = event.target.id;

        const resultados = this.state.resultados.filter(item => String(item.id) !== id);
        this.setState({ resultados });

        // Actualizamos la resultados en el ensayo
        this.props.onChange(resultados, this.state.resultadosAEliminar);

    }

    // On edit
    onEdit = event => {
        const id = event.target.id;

        // Localizamos la resultado a editar
        const resultado = this.state.resultados.filter(item => String(item.id) === id)[0];
        this.setState({
            resultadoAEditar: resultado,
            mostrarDialogo: true,
        });
    }

    // On accept
    onDialogAceptar = () => {
        const resultado = this.state.resultadoAEditar;
        let nuevosResultados = [];

        // Si no tiene id es un resultado nuevo
        if (undefined === resultado.id){
            resultado.id = Math.round(Math.random() * 10000) * -1;
            nuevosResultados = [].concat(this.state.resultados);
            nuevosResultados.push(resultado);

        } else {
            nuevosResultados = this.state.resultados.filter(res => res.id !== resultado.id);
            nuevosResultados.push(resultado);
        }

        // Ordenamos y reseteamos el orden de los resultados
        nuevosResultados.sort(this.ordenaResultados);
        for (let i = 0; i < nuevosResultados.length; ++i){
            nuevosResultados[i].orden = i;
        }

        this.setState({ mostrarDialogo: false, resultados: nuevosResultados, idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE });

        // Actualizamos la resultados en el ensayo
        this.props.onChange(nuevosResultados, this.state.resultadosAEliminar);
    }

    renderResultados(){
        let resultados = [].concat(this.state.resultados);
        let output = [];

        // Si no hay resultados
        if (resultados.length <= 0) return '';

        // Ordenamos la resultados
        resultados.sort(this.ordenaResultados);

        for (let i = 0; i < resultados.length; ++i){
            let item = resultados[i];

            // Correciones
            if (null === item.descripcion)
                item.descripcion = { [this.state.idiomaSeleccionado]: '' };

            output.push(
                <div key={item.id + '-' + Math.round(Math.random() * 10000)} id={item.id} className='resultado-ensayo-linea'>

                    {/* valor */}
                    <div className='resultado-ensayo-linea-elemento-small'>{item.valor}</div>
                    <div className='resultado-ensayo-small-spacer'></div>

                    {/* magnitud */}
                    <div className='resultado-ensayo-linea-elemento-small'>{item.magnitud}</div>
                    <div className='resultado-ensayo-small-spacer'></div>

                    {/* descripcion */}
                    <div className='resultado-ensayo-linea-elemento-large'>{item.descripcion[this.props.idioma]}</div>
                    <div className='resultado-ensayo-small-spacer'></div>
                    <div className='resultado-ensayo-small-spacer'></div>
                    <div className='resultado-ensayo-small-spacer'></div>
                    <div className='resultado-ensayo-small-spacer'></div>

                    <ButtonDropdown
                        opciones={[
                            {
                                id: item.id,
                                label: 'Subir',
                                callback: this.onMoveUp
                            },
                            {
                                id: item.id,
                                label: 'Bajar',
                                callback: this.onMoveDown
                            },
                            {
                                id: item.id,
                                label: 'Editar',
                                callback: this.onEdit
                            },
                            {
                                id: item.id,
                                label: 'Eliminar',
                                callback: this.onDelete
                            }
                        ]}>Acción</ButtonDropdown>

                </div>
            );
        }

        return output;

    }

    render() {
        // No hay ensayo
        if ((null === this.props.ensayo) || (undefined === this.props.ensayo))
            return (<GreyboxBiovert><HeaderBiovert type='t1'>Resultados</HeaderBiovert></GreyboxBiovert>);

        // Comprovamos que tenemos todos los datos
        if (null === this.state.resultados)
            return (<GreyboxBiovert><HeaderBiovert type='t1'>Resultados ...</HeaderBiovert></GreyboxBiovert>);

        if (this.state.mostrarDialogo){
            return (
                <div className='resultado-ensayo-dialogo-container'>
                    <div className='resultado-ensayo-dialogo-modal'>
                        <div className='resultado-ensayo-dialogo-content'>

                            <div className='resultado-ensayo-dialogo-content-titulo'>
                                <HeaderBiovert type='t1'>Resultado</HeaderBiovert>
                                <div className='resultado-ensayo-dialogo-content-close' onClick={() => this.setState({
                                    mostrarDialogo: false, idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
                                })}>
                                    <span className='resultado-ensayo-dialogo-content-close-icon'>&times;</span>
                                </div>
                            </div>

                            {/* Seleccion de idioma */}
                            <div className='resultado-ensayo-dialogo-content-bloque'>
                                <div className='resultado-ensayo-dialogo-select-idioma'>
                                    <SelectIdioma onSelect={idioma => this.setState({ idiomaSeleccionado: idioma })}/>
                                </div>
                            </div>


                            <div className={'resultado-ensayo-dialogo-content-opcion'}>

                                {/* valor */}
                                <div className='resultado-ensayo-dialogo-content-bloque'>
                                    <div className='resultado-ensayo-dialogo-content-label'>Valor:</div>
                                    <div className='resultado-ensayo-dialogo-content-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.resultadoAEditar.valor || ''}
                                            onChange={e => {const s = this.state;s.resultadoAEditar.valor = e.target.value;this.setState(s);}}
                                            className='resultado-ensayo-dialog-text-input'
                                        />
                                    </div>
                                </div>

                                {/* magnitud */}
                                <div className='resultado-ensayo-dialogo-content-bloque'>
                                    <div className='resultado-ensayo-dialogo-content-label'>Magnitud:</div>
                                    <div className='resultado-ensayo-dialogo-content-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.resultadoAEditar.magnitud || ''}
                                            onChange={e => {const s = this.state;s.resultadoAEditar.magnitud = e.target.value;this.setState(s);}}
                                            className='resultado-ensayo-dialog-text-input'
                                        />
                                    </div>
                                </div>

                                {/* descripcion */}
                                <div className='resultado-ensayo-dialogo-content-bloque'>
                                    <div className='resultado-ensayo-dialogo-content-label'>Descripción:</div>
                                    <div className='resultado-ensayo-dialogo-content-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.resultadoAEditar.descripcion[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.resultadoAEditar.descripcion[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='resultado-ensayo-dialog-text-input'
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='resultado-ensayo-dialogo-buttons'>
                                <div></div>
                                <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

        return (
            <GreyboxBiovert>
                <div className='resultado-ensayo-container'>
                    <div className='resultado-ensayo-titulo'>
                        <HeaderBiovert type='t1'>Resultados</HeaderBiovert>
                        <div className='resultado-ensayo-titulo-boton'>
                            <ButtonBiovert
                                type='primary'
                                label='Nuevo resultado'
                                handleClick={() => this.setState({ mostrarDialogo: true, resultadoAEditar: this.resultadoEnBlanco() })}
                            />
                        </div>
                    </div>
                    <div className='resultado-ensayo-lista'>
                        {this.renderResultados()}
                    </div>
                    <div className='resultado-ensayo-small-vertical-spacer'></div>
                </div>
            </GreyboxBiovert>
        );

    }

}

export default ResultadosEnsayo;
