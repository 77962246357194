import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Fontawesome
import FontAwesome from 'react-fontawesome';

// Images
import logoManvert from '../../static/manvert-logo.png';
import bigAddButton from '../../static/big-add-button.png';
import iconBottle from '../../static/icon-bottle.png';
import iconUsers from '../../static/icon-users.png';
import iconEnsayos from '../../static/icon-ensayos.png';

class BarraSuperior extends Component {

    constructor (props) {
        super(props);
        this.state = {
            openDropdownBigAddButton: false,
            openDropDownUser: false,
            usuario: null,
        };
    }

    componentDidMount(){
        this.getUsuario();
    }

    // Obtenesmos el usuario que ha iniciado sesión
    getUsuario(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.AUTH_USER,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ usuario: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    toggleDropdownBigAddButton = () => {this.setState({ openDropdownBigAddButton: !this.state.openDropdownBigAddButton });}

    toggleDropdownBigAddButtonClose = () => {this.setState({ openDropdownBigAddButton: false });}

    toggleDropDownUser = () => {this.setState({ openDropDownUser: !this.state.openDropDownUser });}

    toggleDropDownUserClose = () => {this.setState({ openDropDownUser: false });}

    render() {

        // Si aun no tenemos usuario
        let nombreDeUsuario = '';
        if (null !== this.state.usuario) nombreDeUsuario = this.state.usuario.nombre + ' ' + this.state.usuario.apellidos;

        return (
            <div key='barra-superior-biovert' className='barra-superior-container'>
                <div key='barra-superior-biovert-left'>
                    {/* Logo */}
                    <Link to={APP_CONFIG.RUTAS.HOME}><img src={logoManvert} alt='Biovert' className='barra-superior-logo'/></Link>
                </div>
                <div key='barra-superior-biovert-right' className='barra-superior-right'>
                    {/* Add button dropdown */}
                    <div className='barra-superior-big-add-button-dropdown'>
                        <img src={bigAddButton} alt='Nuevo' className='barra-superior-big-add-button' onClick={this.toggleDropdownBigAddButton}/>
                        <div className={ this.state.openDropdownBigAddButton === true ? 'big-add-button-dropdown-content-show' : 'big-add-button-dropdown-content-hide' } onClick={this.toggleDropdownBigAddButtonClose}>
                            <Link className='barra-superior-dropdown-item' to={APP_CONFIG.RUTAS.PRODUCTOS_GENERICOS_ADD}>
                                <div className='barra-superior-dropdown-item-content'>
                                    <img src={iconBottle} alt='Biovert'/>
                                    <div>&nbsp;Crear nuevo producto genérico</div>
                                </div>
                            </Link>
                            <Link className='barra-superior-dropdown-item' to={APP_CONFIG.RUTAS.PRODUCTOS_ACABADOS_ADD}>
                                <div className='barra-superior-dropdown-item-content'>
                                    <img src={iconBottle} alt='Biovert'/>
                                    <div>&nbsp;Crear nuevo producto acabado</div>
                                </div>
                            </Link>
                            <Link className='barra-superior-dropdown-item' to={APP_CONFIG.RUTAS.ENSAYOS_ADD}>
                                <div className='barra-superior-dropdown-item-content'>
                                    <img src={iconEnsayos} alt='Biovert'/>
                                    <div>&nbsp;Crear nuevo ensayo</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* User dropdown */}
                    <div className='barra-superior-user-dropdown'>
                        <div className='barra-superior-usuario' onClick={this.toggleDropDownUser}>
                            <div className='barra-superior-usuario-nombre'>
                                {nombreDeUsuario}
                            </div>
                            <div className='barra-superior-usuario-down-arrow'>
                                <FontAwesome name='chevron-down'/>
                            </div>
                        </div>
                        <div className={ this.state.openDropDownUser === true ? 'barra-superior-user-dropdown-content-show' : 'barra-superior-user-dropdown-content-hide' } onClick={this.toggleDropDownUserClose}>
                            <Link className='barra-superior-dropdown-item' to={APP_CONFIG.RUTAS.PERFIL}>
                                <div className='barra-superior-dropdown-item-content'>
                                    <img src={iconUsers} alt='Biovert'/>
                                    <div>&nbsp;Perfil</div>
                                </div>
                            </Link>
                            <Link className='barra-superior-dropdown-item' to={APP_CONFIG.RUTAS.LOGOUT}>
                                <div className='barra-superior-dropdown-item-content'>
                                    <FontAwesome name='sign-out-alt' style={ { color: 'black' } }/>
                                    <div>&nbsp;Salir</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default BarraSuperior;
