import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import '../index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import HeaderBiovert from '../../../components/header-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class EnsayosAddPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            codigoDeEnsayo: null,
            redirectId: null,
        };
    }

    guardaNuevoEnsayo = () => {

        // Comprobamos campo no vacio
        if (this.state.codigoDeEnsayo == null
          || 0 >= this.state.codigoDeEnsayo.length) return true;

        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS,
            method: 'POST',
            authorized: true,
            contentType: 'application/json',
            data: { codigo: this.state.codigoDeEnsayo }
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({
                    codigoDeEnsayo: '',
                    redirectId: Number(JSON.parse(response).id),
                });
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el ensayo</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });

    };

    render() {

        // Redirect
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.ENSAYOS_DETAIL + '/' + this.state.redirectId}/>);

        // Add
        return (
            <PaginaBiovert>
                <div className='ensayo-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Ensayos', 'Nuevo' ]}/>
                    <HeaderBiovert type='t1'>Nuevo ensayo</HeaderBiovert>
                    {/* Formulario */}
                    <GreyboxBiovert>
                        <div className='ensayo-formulario'>
                            <div className='ensayo-formulario-fila'>
                                <div className='ensayo-formulario-elemento'>
                                    <div className='ensayo-formulario-elemento-titulo'>Código de ensayo</div>
                                    <div className='ensayo-formulario-elemento-contenido'>
                                        <input
                                            id='ensayo-text-input'
                                            type='text'
                                            value={this.state.codigoDeEnsayo || ''}
                                            onChange={event => this.setState({ codigoDeEnsayo: event.target.value })}
                                            className='ensayo-text-input'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </GreyboxBiovert>
                    <SeccionAceptarCancelarBiovert onAccept={this.guardaNuevoEnsayo}/>
                </div>
            </PaginaBiovert>
        );
    }

}

export default EnsayosAddPage;
