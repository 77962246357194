import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

// Activate sentry logging

Sentry.init({
    dsn: 'https://848d1f9b08a3131054ab0fb01a8b5035@sentry.eunoia.es/7',
    environment: process.env.NODE_ENV,
});

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// registerServiceWorker();
unregister();
