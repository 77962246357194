import React, { Component } from 'react';
import './index.css';

class RawHTML extends Component {

    render() {
        if (null !== this.props.children){
            return (
                <div id={this.props.id} dangerouslySetInnerHTML={ { __html: this.props.children.replace(/\n/g, '<br />') } } />
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }

}

export default RawHTML;
