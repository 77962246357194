import React, { Component } from 'react';
import './index.css';

class DropdownTablaBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            openDropdown: false
        };
    }

    toggleDropdown = () => {this.setState({ openDropdown: !this.state.openDropdown });}

    toggleDropdownClose = () => {this.setState({ openDropdown: false });}

    render() {
        return (
            <div className='button-dropdown'>
                <button onClick={this.toggleDropdown} className='button-dropbtn'>{this.props.children}</button>
                <div className={ this.state.openDropdown === true ? 'button-dropdown-content show' : 'button-dropdown-content' } onClick={this.toggleDropdownClose}>
                    {Array.isArray(this.props.opciones)
                        ? this.props.opciones.map(
                            item => <button className='dropdown-content-item' id={item.id} key={btoa(item.id + item.label)} onClick={item.callback}>{item.label}</button>
                        )
                        : ''}
                </div>
            </div>
        );
    }

}

export default DropdownTablaBiovert;
