import React, { Component } from 'react';
import './index.css';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class ButtonBiovert extends Component {

    render() {
        // Primary
        if ('primary' === this.props.type){
            return (
                <button id={this.props.id || ''} type='button' className='button-biovert-primary' onClick={this.props.handleClick}>
                    <span className='button-biovert-primary-text'>{this.props.label}</span>
                </button>
            );
        } else if ('primary-outline-only' === this.props.type) {
            return (
                <button id={this.props.id || ''} type='button' className='button-biovert-primary-outline-only' onClick={this.props.handleClick}>
                    <span>{this.props.label}</span>
                </button>
            );
        } else if ('primary-fa-icon' === this.props.type) {
            return (
                <button id={this.props.id || ''} type='button' className='button-biovert-primary-fa-icon' onClick={this.props.handleClick}>
                    <FontAwesome name={this.props.label}/>
                </button>
            );
        }
        else if ('secondary' === this.props.type){
            return (
                <button id={this.props.id || ''} type='button' className='button-biovert-secondary' onClick={this.props.handleClick}>
                    <span className='button-biovert-secondary-text'>{this.props.label}</span>
                </button>
            );
        }
        else {
            return (
                <button id={this.props.id || ''} type='button' className='button-biovert-default' onClick={this.props.handleClick}>
                    <span className='button-biovert-default-text'>{this.props.label}</span>
                </button>
            );
        }
    }

}

export default ButtonBiovert;
