import React, { Component } from 'react';
import './index.css';

class InputFileBiovert extends Component {

    render() {
        return (
            <input type='file' onChange={this.props.handleChange} className='input-file-biovert-container'/>
        );
    }
}

export default InputFileBiovert;
