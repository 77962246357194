import React, { Component } from 'react';
import './index.css';

import DropdownTablaBiovert from '../dropdown-tabla-biovert';
import RawHTML from '../raw-html';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class TablaBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            searchedTerm: localStorage.getItem(this.props.paginaFiltro) || '',
            columnaOrdenacion: 0,
            ordenacionAsc: true,
            scrollTop: localStorage.getItem(`${this.props.paginaFiltro}-scrollPosition`) || 0 ,

        };

        // Limite caracteres
        this.limiteCaracteres = 25;
        this.handleScroll = this.handleScroll.bind(this);

    }

    handleSearchOnChange = (event) => {this.setState({ searchedTerm: event.target.value }); localStorage.setItem(this.props.paginaFiltro,event.target.value);}

    filtraContenido = contenido => {

        const palabrasBuscadas = this.state.searchedTerm.split(' ');

        // Por cada palabra de la busqueda filtramos el contenido
        palabrasBuscadas.forEach(pb =>
            contenido = contenido.filter(item => {
                for (let i = 0; i < item.fields.length; ++i){
                    if ((null !== item.fields[i]) && (undefined !== item.fields[i])){
                        if (item.fields[i].toUpperCase().includes(pb.toUpperCase())) return true;
                    }
                }
                return false;
            })
        );

        return contenido;
    }

    onCustomClick = (event) => {
        let id = event.target.id;
        if (undefined !== id && null !== id && '' !== id) {
            return this.props.onRowClick(Number(id));
        }
    }

    onClickCabecera = i => {
        let ordenacion = this.state.ordenacionAsc;
        // Si la ordenación ya estaba aquñi cambiamos el orden
        if (this.state.columnaOrdenacion === i) ordenacion = !ordenacion;
        this.setState({
            columnaOrdenacion: Number(i),
            ordenacionAsc: ordenacion,
        });
    }

    ordenaContenido = (a,b) => {
        let result = 0;

        if (a.fields[this.state.columnaOrdenacion] > b.fields[this.state.columnaOrdenacion]) result = 1;
        else if (a.fields[this.state.columnaOrdenacion] < b.fields[this.state.columnaOrdenacion]) result = -1;

        if (this.state.ordenacionAsc) return result;
        else return result * -1;
    }

    sText = s => {
        // Si tenemos propiedad no shorten no hacemos nada
        if (this.props.noShorten) return s;

        if ((null !== s) && (undefined !== s)){
            if (s.length > this.limiteCaracteres){
                return s.substring(0, this.limiteCaracteres - 2) + '...';
            } else {
                return s;
            }
        } else return '';
    }

    calculaCabeceras(){
        let cabeceras = [];

        //Añadimos las cabeceras
        for (let i = 0; i < this.props.cabeceras.length; ++i){
            let item = this.props.cabeceras[i];
            cabeceras.push(
                <div className='tabla-biovert-elemento-cabecera' key={'h-' + btoa(item)} id={i} onClick={() => this.onClickCabecera(i)}>
                    {item}
                    {this.state.columnaOrdenacion === i
                        ? <div className='tabla-biovert-elemento-cabecera-arrow'><FontAwesome name={ this.state.ordenacionAsc ? 'arrow-down' : 'arrow-up'}/></div>
                        : ''
                    }
                </div>
            );
        }

        // Añadimos buscador
        cabeceras.push(
            <div key='th-accion' className='tabla-biovert-elemento-cabecera-align-right'>
                <input
                    type='text'
                    placeholder='Buscar ...'
                    className='tabla-biovert-buscar'
                    onChange={this.handleSearchOnChange}
                    value={this.state.searchedTerm}
                />
            </div>
        );

        return cabeceras;
    }

    calculaAcciones(id, acciones){
        // Si no hay acciones
        if (acciones.length <= 0){
            return (
                <div className='tabla-biovert-elemento-fila-align-right'></div>
            );
        }

        let opciones = [];
        for (let i = 0; i < acciones.length; ++i){
            opciones.push({
                id: id,
                label: acciones[i].label,
                callback: acciones[i].callback,
            });
        }

        return (
            <div className='tabla-biovert-elemento-fila-align-right'>{<DropdownTablaBiovert opciones={opciones}>Acción &nbsp;<FontAwesome name='chevron-down'/></DropdownTablaBiovert>}</div>
        );
    }

    calculaCampos(item){
        let fields = [];
        for (let i = 0; i < item.fields.length; ++i){

            // Comprobamos que no sea null
            if (null === item.fields[i]) {
                fields.push(
                    <div id={item.id} key={item.id + '-' + i} className='tabla-biovert-elemento-fila' onClick={this.onCustomClick}>
                        <div id={item.id} className='tabla-biovert-elemento-fila-content'>
                            &nbsp;
                        </div>
                    </div>
                );
            } else if ('http' === item.fields[i].substring(0, 4)){
                // Detectamos si es una imagen/documento
                fields.push(
                    <div id={item.id} key={item.id + '-' + i} className='tabla-biovert-elemento-fila' onClick={this.onCustomClick}>
                        <div id={item.id} className='tabla-biovert-elemento-fila-content-imagen'>
                            <img src={item.fields[i]} alt='' className='tabla-biovert-elemento-fila-imagen'/>
                        </div>
                    </div>
                );
            } else {
                fields.push(
                    <div id={item.id} key={item.id + '-' + i} className='tabla-biovert-elemento-fila' onClick={this.onCustomClick}>
                        <div id={item.id} className='tabla-biovert-elemento-fila-content'>
                            <RawHTML id={item.id}>{this.sText(item.fields[i])}</RawHTML>
                        </div>
                    </div>
                );
            }
        }
        return fields;
    }

    calculaContenido(){
        let contenido = [];
        let contenidoFiltrado = [];
        let item = null;

        // Filtramos
        if (this.state.searchedTerm.length > 0) contenidoFiltrado = this.filtraContenido(this.props.contenido);
        else contenidoFiltrado = this.props.contenido;

        // Ordenamos
        contenidoFiltrado.sort(this.ordenaContenido);

        for (let i = 0; i < contenidoFiltrado.length; ++i){
            item = contenidoFiltrado[i];
            if (0 < i) contenido.push(<div key={item.id + 'div'} className='tabla-biovert-divider'></div>);
            contenido.push(
                <div key={item.id} className='tabla-biovert-fila'>
                    {this.calculaCampos(item)}
                    {this.calculaAcciones(item.id, item.acciones)}
                </div>
            );
        }
        return contenido;
    }

    componentDidMount(){

        if (this.props.saveScrollValue === true){
            console.log('componentDidMount');

            // Check for a saved scrollTop value in localStorage
            const savedScrollTop = localStorage.getItem(`${this.props.paginaFiltro}-scrollPosition`);
            if (savedScrollTop !== null) {
            // Update the component state with the saved scrollTop value
                window.scrollTo(0, savedScrollTop);
            }

            // Add the event listener for the 'scroll' event
            window.addEventListener('scroll', this.handleScroll);
        }
    }
    componentWillUnmount() {
        if (this.props.saveScrollValue === true){
        // Remove the event listener for the 'scroll' event
            window.removeEventListener('scroll', this.handleScroll); }

    }

      handleScroll = () => {
          // Update the component state with the current scroll position

          // Update the state with the current scroll position
          this.setState({ scrollPosition: window.scrollY });

          // Store the scroll position in localStorage
          localStorage.setItem(`${this.props.paginaFiltro}-scrollPosition`, window.scrollY);

      }

      render() {

          // No hay elementos en la tabla
          if (!Array.isArray(this.props.contenido) || this.props.contenido.length <= 0){
              return (
                  <div className='tabla-biovert-container'>
                      <div className='tabla-biovert-cabecera'>
                          <div className='tabla-biovert-no-hay-datos'>
                              <div className='tabla-biovert-no-hay-datos-texto'>
                                  <FontAwesome name='info-circle'/>&nbsp;&nbsp;&nbsp;No hay elementos que mostrar en la tabla
                              </div>
                          </div>
                      </div>
                  </div>
              );
          }

          // Mostramos tabla
          return (
              <div className='tabla-biovert-container'>
                  <div className='tabla-biovert-cabecera'>
                      {this.calculaCabeceras()}
                  </div>
                  <div className='tabla-biovert-divider'></div>
                  {this.calculaContenido()}
              </div>
          );




      }

}

export default TablaBiovert;
