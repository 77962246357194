import React, { Component } from 'react';
import './index.css';

// Componentes
import PaginaBiovert from '../../components/pagina-biovert';

// Images
import logoManvert from '../../static/logo-manvert-mwa.jpg';

class PrincipalPage extends Component {

    render() {
        return (<PaginaBiovert><div className='page-principal-container'><img src={logoManvert} alt='Biovert' height='300px'/></div></PaginaBiovert>);
    }
}

export default PrincipalPage;
