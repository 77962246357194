import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import '../index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import HeaderBiovert from '../../../components/header-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class EnsayosViewPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeEnsayos: null,
            redirectId: null,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de ensayos
        this.getEnsayos();
    }

    // Get ensayos de la base de datos
    getEnsayos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                let listaDeEnsayos = JSON.parse(response);

                // Inicializamos nombres
                listaDeEnsayos = listaDeEnsayos.map(ensayo => {
                    if (null === ensayo.nombre){
                        ensayo.nombre = { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' };
                    }
                    return ensayo;
                });

                this.setState({ listaDeEnsayos });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Editar
    editarEnsayoCallback = event => {return this.editarEnsayo(Number(event.target.id));}

    editarEnsayo = id => {this.setState({ redirectId: id });}

    eliminarEnsayoCallback = event => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let codigo = null;
        for (let i = 0; i < this.state.listaDeEnsayos.length; ++i){
            if (this.state.listaDeEnsayos[i].id === Number(id)){
                codigo = this.state.listaDeEnsayos[i].codigo;
                break;
            }
        }
        if (null === codigo) return true;
        if (window.confirm('¿Desea eliminar el ensayo ' + codigo + '? Atención, este cambio es irreversible.')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENSAYOS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Ensayo eliminado con éxito</span></div>);
                    this.getEnsayos();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el ensayo</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    }

    calculaContenidoParaTabla(){
        // Vars
        let contenido = [];

        // Si no hay datos
        if (this.state.listaDeEnsayos == null) return [];

        // Contenido
        for (let i = 0; i < this.state.listaDeEnsayos.length; ++i){
            let item = this.state.listaDeEnsayos[i];
            contenido.push(
                {
                    id: item.id,
                    fields: [ item.codigo, item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE], item.fecha, item.pais ],
                    acciones: [
                        { label: 'Editar', callback: this.editarEnsayoCallback },
                        { label: 'Eliminar', callback: this.eliminarEnsayoCallback }
                    ]
                }
            );
        }
        return contenido;
    }

    render() {

        // Editar
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.ENSAYOS_DETAIL + '/' + this.state.redirectId}/>);

        // Si aun no tenemos datos
        if (null === this.state.listaDeEnsayos) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        return (
            <PaginaBiovert>
                <div key='elemento_lista' className='ensayo-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Ensayos' ]}/>

                    {/* Titulo + boton add */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                        <HeaderBiovert type='t1'>Ensayos</HeaderBiovert>
                    </div>

                    <br></br>
                    <TablaBiovert
                        cabeceras={[ 'Código', 'Titular', 'Fecha', 'País' ]}
                        contenido={this.calculaContenidoParaTabla()}
                        onRowClick={id => this.setState({ redirectId: id })}/>
                </div>
            </PaginaBiovert>
        );

    }

}

export default EnsayosViewPage;
