import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../../services/Api.js';
import { APP_CONFIG } from '../../../../utils/globales.js';
import '../../index.css';

// Componentes
import PaginaBiovert from '../../../../components/pagina-biovert';
import LoadingBiovert from '../../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../../components/tabla-biovert';
import HeaderBiovert from '../../../../components/header-biovert';
import GreyboxBiovert from '../../../../components/greybox-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class ProductosAcabadosAddPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeProductos: null,
            listaDeFamilias: null,
            listaDeComoSeAplica: null,
            listaDeLineasDeComposicion: null,
            redirectId: null,
            loading: false,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de productos, familias y lineas de composicion
        this.getProductosGenericos();
        this.getFamilias();
        this.getLineasDeComposicion();
        this.getComoSeAplica();
    }

    // Get productos genericos de la base de datos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get familias de la base de datos
    getFamilias(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeFamilias: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get lineas de composicion de la base de datos
    getLineasDeComposicion(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeLineasDeComposicion: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get como se aplica de la base de datos
    getComoSeAplica(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeComoSeAplica: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Calcula familia a mostrar
    calculaFamiliaAMostrar(item){
        if (null === item.familia) return '';
        if (null === this.state.listaDeFamilias) return '';
        for (let i = 0; i < this.state.listaDeFamilias.length; ++i){
            if (Number(this.state.listaDeFamilias[i].id) === Number(item.familia)) return (this.state.listaDeFamilias[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
        }
        return '';
    }

    // Calcula estado a mostrar
    calculaEstadoAMostrar(item){
        if (item.activo) return 'Activo';
        else return '--';
    }

    // Calcula ecologico a mostrar
    calculaEcologicoAMostrar(item){
        if (item.ecologico) return 'Ecológico';
        else return '--';
    }

    // Calcula nombre a mostrar
    calculaNombreAMostrar(item){
        if (null === item.nombre) return '';
        return item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
    }

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeProductos == null) return [];
        // Contenido
        return this.state.listaDeProductos.map(item =>
            ({
                id: item.id,
                fields: [
                    item.codigo, this.calculaNombreAMostrar(item),
                    this.calculaFamiliaAMostrar(item),
                    this.calculaEstadoAMostrar(item),
                    this.calculaEcologicoAMostrar(item)
                ],
                acciones: []
            })
        );
    }

    getNombreProdcutoFromId = id => {
        const productoGenerico = this.state.listaDeProductos.filter(item => item.id === id)[0];
        if (null !== productoGenerico.nombre){
            return productoGenerico.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] + ' - ' + productoGenerico.codigo;
        }
        return productoGenerico.codigo;
    }

    guardaNuevoProductoAcabado = id => {

        // Dialogo de confirmación
        if (!window.confirm('¿Desea crear un nuevo producto acabado a partir de los datos del producto ' + this.getNombreProdcutoFromId(id) + '?')) return true;

        // Loading
        this.setState({ loading: true });

        // Obtenemos los datos del producto generico
        const pg = this.state.listaDeProductos.filter(item => item.id === id)[0];

        // Creamos un nuevo producto acabado a partir del producto generico
        const nuevoProducto = {
            codigo: pg.codigo,
            nombre: pg.nombre != null ? pg.nombre : { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' },
            descripcion: pg.descripcion,
            descripcion2: pg.descripcion2,
            caracteristicas: pg.caracteristicas,
            aplicaciones: pg.aplicaciones,
            observaciones: pg.observaciones,
            modo_de_accion: pg.modo_de_accion,
            producto_generico: id,
            estados_fenologicos: pg.estados_fenologicos,
            familia: pg.familia,
            iconos: pg.iconos,
            activo: true,
            ecologico: pg.ecologico,
        };

        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS,
            method: 'POST',
            authorized: true,
            contentType: 'application/json',
            data: nuevoProducto
        };
        ApiService.request(requestData)
            .then((response) => {
                //toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Producto guardado con éxito</span></div>);
                this.copiarLineasDeComposicion(Number(JSON.parse(response).id), id);
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el producto acabado</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    copiarLineasDeComposicion = (idAcabado, idGenerico) => {

        // Copiamos las lineas de composición del producto generico origen
        this.state.listaDeLineasDeComposicion.filter(item => item.producto_generico === idGenerico).forEach(item => {
            const requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: {
                    componente: item.componente,
                    cantidad_1: item.cantidad_1,
                    magnitud_1: item.magnitud_1,
                    cantidad_2: item.cantidad_2,
                    magnitud_2: item.magnitud_2,
                    solubilidad: item.solubilidad,
                    valor: item.valor,
                    orden: item.orden,
                    producto_generico: null,
                    producto_acabado: idAcabado,
                }
            };
            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        // Copiamos los como se aplica
        this.copiarComoSeAplica(idAcabado, idGenerico);

    }

    copiarComoSeAplica = (idAcabado, idGenerico) => {

        // Copiamos los como se aplica de producto generico al producto acabado
        this.state.listaDeComoSeAplica.filter(item => Number(item.producto_generico) === Number(idGenerico)).forEach(comoSeAplica => {
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: {
                    modo_de_aplicacion: comoSeAplica.modo_de_aplicacion,
                    momento_de_aplicacion: comoSeAplica.momento_de_aplicacion,
                    dosis: comoSeAplica.dosis,
                    objetivo: comoSeAplica.objetivo,
                    estado_fenologico: comoSeAplica.estado_fenologico,
                    producto_generico: null,
                    producto_acabado: idAcabado,
                }
            };
            ApiService.request(requestData)
                .then(() => {})
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });
        });

        this.setState({
            redirectId: idAcabado,
        });

    }

    render() {

        // Redirect
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.PRODUCTOS_EDIT + '/a/' + this.state.redirectId}/>);

        // Si estamos cargando
        if (this.state.loading) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Si aun no tenemos datos
        if (
            (null === this.state.listaDeProductos)
            || (null === this.state.listaDeFamilias)
            || (null === this.state.listaDeLineasDeComposicion)
            || (null === this.state.listaDeComoSeAplica)
        ) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Add
        return (
            <PaginaBiovert>
                <div className='producto-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Productos acabados', 'Nuevo' ]}/>
                    <HeaderBiovert type='t1'>Nuevo producto acabado</HeaderBiovert>

                    {/* Tabla de productos genericos */}
                    <GreyboxBiovert>
                        <div className='producto-formulario'>
                            <div className='producto-formulario-fila'>
                                <div className='producto-formulario-elemento'>
                                    <div className='producto-formulario-elemento-titulo'>Seleccione el producto genérico origen</div>
                                </div>
                            </div>
                        </div>
                    </GreyboxBiovert>

                    <TablaBiovert
                        cabeceras={[ 'Código de artículo', 'Nombre', 'Familia', 'Estado', 'Ecológico' ]}
                        contenido={this.calculaContenidoParaTabla()}
                        onRowClick={this.guardaNuevoProductoAcabado}/>

                </div>
            </PaginaBiovert>
        );
    }


}

export default ProductosAcabadosAddPage;
