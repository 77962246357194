import React, { Component } from 'react';
import './index.css';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class LoadingBiovert extends Component {

    render() {
        return (
            <div className='loading-biovert-container'>
                <div className='loading-biovert-element'>
                    <FontAwesome name='sync-alt' spin/>&nbsp;&nbsp;Cargando ...
                </div>
            </div>
        );
    }

}

export default LoadingBiovert;
