import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import ButtonBiovert from '../button-biovert';
import HeaderBiovert from '../header-biovert';
import ButtonDropdown from '../button-dropdown';
import SelectIdioma from '../select-idioma';
import InputFileBiovert from '../input-file-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class AdjuntosEnsayo extends Component {

    constructor (props) {
        super(props);
        this.state = {
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            adjuntos: [],
            adjuntosAEliminar: [],
            adjuntoAEditar: null,
            mostrarDialogo: false,
        };
    }

    componentDidMount(){
        // Obtenemos los adjuntos del ensayo
        this.getAdjuntos();
    }

    getAdjuntos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ADJUNTOSENSAYO + '?ensayo=' + this.props.ensayo,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                const adjuntos = JSON.parse(response);
                this.setState({ adjuntos });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    ordenaAdjuntos = (a,b) => {
        if (a.orden > b.orden) return 1;
        else if (a.orden < b.orden) return -1;
        return 0;
    }

    adjuntoEnBlanco() {
        return ({
            orden: 0,
            descripcion: { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '' }
        });
    }

    // On move up
    onMoveUp = event => {
        const id = event.target.id;
        let aux;
        let adjuntos = this.state.adjuntos;

        adjuntos.sort(this.ordenaAdjuntos);

        for (let i = 0; i < adjuntos.length; ++i){
            if (String(adjuntos[i].id) === id){
                // Si intentamos subir la primera linea
                if (i === 0) break;

                // Si tenemos una linea por arriba
                if (null !== adjuntos[i - 1]) {
                    aux = adjuntos[i].orden;
                    adjuntos[i].orden = adjuntos[i - 1].orden;
                    adjuntos[i - 1].orden = aux;
                }
                break;
            }
        }

        this.setState({ adjuntos });

        // Actualizamos los adjuntos en el ensayo
        this.props.onChange(adjuntos, this.state.adjuntosAEliminar);
    }

    // On move down
    onMoveDown = event => {
        const id = event.target.id;
        let aux;
        let adjuntos = this.state.adjuntos;

        adjuntos.sort(this.ordenaAdjuntos);

        for (let i = 0; i < adjuntos.length; ++i){
            if (String(adjuntos[i].id) === id){
                // Si intentamos bajar la ultima linea
                if (i === adjuntos.length - 1) break;

                // Si tenemos una linea por debajo
                if (null !== adjuntos[i + 1]) {
                    aux = adjuntos[i].orden;
                    adjuntos[i].orden = adjuntos[i + 1].orden;
                    adjuntos[i + 1].orden = aux;
                }

                break;
            }
        }

        this.setState({ adjuntos });

        // Actualizamos los adjuntos en el ensayo
        this.props.onChange(adjuntos, this.state.adjuntosAEliminar);
    }

    // On delete
    onDelete = event => {
        const id = event.target.id;
        const adjuntosAEliminar = [].concat(this.state.adjuntosAEliminar);

        // Si no es un adjunto temporal
        if (id > 0) {
            adjuntosAEliminar.push(id);
        }

        // eliminamos adjunto del estado
        const adjuntos = this.state.adjuntos.filter(item => String(item.id) !== id);

        this.setState({ adjuntos, adjuntosAEliminar });

        // Actualizamos la adjuntos en el ensayo
        this.props.onChange(adjuntos, adjuntosAEliminar);

    }

    // On edit
    onEdit = event => {
        const id = event.target.id;

        // Localizamos la adjunto a editar
        const adjunto = this.state.adjuntos.filter(item => String(item.id) === id)[0];
        this.setState({
            adjuntoAEditar: adjunto,
            mostrarDialogo: true,
        });
    }

    // On accept
    onDialogAceptar = () => {
        const adjunto = this.state.adjuntoAEditar;
        let nuevosAdjuntos = [];

        // Si no tiene id es un adjunto nuevo
        if (undefined === adjunto.id){
            adjunto.id = Math.round(Math.random() * 10000) * -1;
            nuevosAdjuntos = [].concat(this.state.adjuntos);
            nuevosAdjuntos.push(adjunto);

        } else {
            nuevosAdjuntos = this.state.adjuntos.filter(res => res.id !== adjunto.id);
            nuevosAdjuntos.push(adjunto);
        }

        // Ordenamos y reseteamos el orden de los adjuntos
        nuevosAdjuntos.sort(this.ordenaAdjuntos);
        for (let i = 0; i < nuevosAdjuntos.length; ++i){
            nuevosAdjuntos[i].orden = i;
        }

        this.setState({ mostrarDialogo: false, adjuntos: nuevosAdjuntos, idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE });

        // Actualizamos los adjuntos en el ensayo
        this.props.onChange(nuevosAdjuntos, this.state.adjuntosAEliminar);
    }

    // Imagen dialog
    handleChangeImagen = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            const nuevoAdjuntoAEditar = this.state.adjuntoAEditar;
            nuevoAdjuntoAEditar.imagen = file;
            this.setState({ adjuntoAEditar: nuevoAdjuntoAEditar });
        }
    }

    pintaImagen(item){
        let output = <div className='adjunto-ensayo-imagen-placeholder'>&nbsp;</div>;

        if (undefined !== item.imagen && null !== item.imagen && 'object' !== typeof item.imagen) {
            output = <img src={item.imagen} alt='Biovert' className='adjunto-ensayo-imagen'/>;
        }

        if ('object' === typeof item.imagen && null !== item.imagen) {
            output = <div className='adjunto-ensayo-imagen-placeholder'>Guarda el ensayo para ver la imagen</div>;
        }

        return <div className='adjunto-ensayo-imagen-container'>{output}</div>;
    }

    renderAdjuntos(){
        let adjuntos = [].concat(this.state.adjuntos);
        let output = [];

        // Si no hay adjuntos
        if (adjuntos.length <= 0) return '';

        // Ordenamos la adjuntos
        adjuntos.sort(this.ordenaAdjuntos);

        for (let i = 0; i < adjuntos.length; ++i){
            let item = adjuntos[i];

            // Correciones
            if (null === item.descripcion)
                item.descripcion = { [this.state.idiomaSeleccionado]: '' };

            output.push(
                <div key={item.id + '-' + Math.round(Math.random() * 10000)} id={item.id} className='adjunto-ensayo-linea'>

                    {/* descripcion */}
                    {this.pintaImagen(item)}
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-linea-elemento'>{item.descripcion[this.props.idioma]}</div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>
                    <div className='adjunto-ensayo-small-spacer'></div>

                    <ButtonDropdown
                        opciones={[
                            {
                                id: item.id,
                                label: 'Subir',
                                callback: this.onMoveUp
                            },
                            {
                                id: item.id,
                                label: 'Bajar',
                                callback: this.onMoveDown
                            },
                            {
                                id: item.id,
                                label: 'Editar',
                                callback: this.onEdit
                            },
                            {
                                id: item.id,
                                label: 'Eliminar',
                                callback: this.onDelete
                            }
                        ]}>Acción</ButtonDropdown>

                </div>
            );
        }

        return output;

    }

    render() {
        // No hay ensayo
        if ((null === this.props.ensayo) || (undefined === this.props.ensayo))
            return (<GreyboxBiovert><HeaderBiovert type='t1'>Adjuntos</HeaderBiovert></GreyboxBiovert>);

        // Comprovamos que tenemos todos los datos
        if (null === this.state.adjuntos)
            return (<GreyboxBiovert><HeaderBiovert type='t1'>Adjuntos ...</HeaderBiovert></GreyboxBiovert>);

        if (this.state.mostrarDialogo){
            return (
                <div className='adjunto-ensayo-dialogo-container'>
                    <div className='adjunto-ensayo-dialogo-modal'>
                        <div className='adjunto-ensayo-dialogo-content'>

                            <div className='adjunto-ensayo-dialogo-content-titulo'>
                                <HeaderBiovert type='t1'>Adjunto</HeaderBiovert>
                                <div className='adjunto-ensayo-dialogo-content-close' onClick={() => this.setState({
                                    mostrarDialogo: false, idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
                                })}>
                                    <span className='adjunto-ensayo-dialogo-content-close-icon'>&times;</span>
                                </div>
                            </div>

                            {/* Seleccion de idioma */}
                            <div className='adjunto-ensayo-dialogo-content-bloque'>
                                <div className='adjunto-ensayo-dialogo-select-idioma'>
                                    <SelectIdioma onSelect={idioma => this.setState({ idiomaSeleccionado: idioma })}/>
                                </div>
                            </div>


                            <div className={'adjunto-ensayo-dialogo-content-opcion'}>

                                {/* descripcion */}
                                <div className='adjunto-ensayo-dialogo-content-bloque'>
                                    <div className='adjunto-ensayo-dialogo-content-label'>Descripción:</div>
                                    <div className='adjunto-ensayo-dialogo-content-contenido'>
                                        <input
                                            type='text'
                                            value={this.state.adjuntoAEditar.descripcion[this.state.idiomaSeleccionado] || ''}
                                            onChange={e => {const s = this.state;s.adjuntoAEditar.descripcion[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                            className='adjunto-ensayo-dialog-text-input'
                                        />
                                    </div>
                                </div>

                                <div className='adjunto-ensayo-small-vertical-spacer'>&nbsp;</div>
                                <div className='adjunto-ensayo-small-vertical-spacer'>&nbsp;</div>

                                {/* seleccion de imagen */}
                                {this.state.adjuntoAEditar.imagen === undefined
                                    ? <div>
                                        <InputFileBiovert handleChange={this.handleChangeImagen}/>
                                    </div>
                                    : <div>{this.pintaImagen(this.state.adjuntoAEditar)}</div>
                                }
                            </div>
                            <div className='adjunto-ensayo-dialogo-buttons'>
                                <div></div>
                                <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

        return (
            <GreyboxBiovert>
                <div className='adjunto-ensayo-container'>
                    <div className='adjunto-ensayo-titulo'>
                        <HeaderBiovert type='t1'>Adjuntos</HeaderBiovert>
                        <div className='adjunto-ensayo-titulo-boton'>
                            <ButtonBiovert
                                type='primary'
                                label='Nuevo adjunto'
                                handleClick={() => this.setState({ mostrarDialogo: true, adjuntoAEditar: this.adjuntoEnBlanco() })}
                            />
                        </div>
                    </div>
                    <div className='adjunto-ensayo-lista'>
                        {this.renderAdjuntos()}
                    </div>
                    <div className='adjunto-ensayo-small-vertical-spacer'></div>
                </div>
            </GreyboxBiovert>
        );

    }

}

export default AdjuntosEnsayo;
