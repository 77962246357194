import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import ButtonBiovert from '../button-biovert';
import HeaderBiovert from '../header-biovert';
import DialogoLineaDeComposicion from '../dialogo-linea-de-composicion';
import RawHTML from '../raw-html';
import ButtonDropdown from '../button-dropdown';

class LineasDeComposicionBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeComponentes: null,
            listaDeMagnitudes: null,
            lineasDeComposicion: [],
            lineasDeComposicionAEliminar: [],
            lineaAEditar: null,
            dialogoLineaDeComposicionShow: false,
        };
    }

    componentDidMount(){

        // Obtenemos componentes
        this.getComponentes();
        // Obtenemos magnitudes
        this.getMagnitudes();
        // Obtenemos las lineas de composicion
        this.getLineasDeComposicion();

    }

    getComponentes(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMPONENTES_QUIMICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeComponentes: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getMagnitudes(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeMagnitudes: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getLineasDeComposicion(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                const lineas = JSON.parse(response);
                this.setState({
                    lineasDeComposicion: lineas.filter(item => item[this.props.tipo] === this.props.producto),
                    lineasDeComposicionAEliminar: lineas.filter(item => item[this.props.tipo] === this.props.producto).map(item => item.id),
                });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getComponenteFromId = id => {
        const componente = this.state.listaDeComponentes.filter(item => item.id === id)[0];
        if (null != componente) return componente.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
        else return '';
    };
    getSolubilidadFromId = id => {
        if (0 === id) return '--';
        else if (1 === id) return '* Soluble en agua y complejado';
        else if (2 === id) return '** Soluble en agua';
        else if (3 === id) return '*** Soluble en agua y en citrato amónico neutro';
        else return '**** Soluble en agua y quelatado';
    }
    getMagnitudFromId = id => {
        const magnitud = this.state.listaDeMagnitudes.filter(item => item.id === id)[0];
        if (null != magnitud) return magnitud.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
        else return '';
    }

    ordenaLineas = (a,b) => {
        if (a.orden > b.orden) return 1;
        else if (a.orden < b.orden) return -1;
        return 0;
    }

    // On move up linea de composicion
    onMoveUp = event => {
        const id = event.target.id;
        let aux;
        let lineas = this.state.lineasDeComposicion;

        lineas.sort(this.ordenaLineas);

        for (let i = 0; i < lineas.length; ++i){
            if (String(lineas[i].id) === id){
                // Si intentamos subir la primera linea
                if (i === 0) break;

                // Si tenemos una linea por arriba
                if (null !== lineas[i - 1]) {
                    aux = lineas[i].orden;
                    lineas[i].orden = lineas[i - 1].orden;
                    lineas[i - 1].orden = aux;
                }

                break;
            }
        }

        this.setState({
            lineasDeComposicion: lineas,
        });

        // Actualizamos la lineas en el producto
        this.props.onChange(lineas, this.state.lineasDeComposicionAEliminar);
    }

    // On move down linea de composicion
    onMoveDown = event => {
        const id = event.target.id;
        let aux;
        let lineas = this.state.lineasDeComposicion;

        lineas.sort(this.ordenaLineas);

        for (let i = 0; i < lineas.length; ++i){
            if (String(lineas[i].id) === id){
                // Si intentamos bajar la ultima linea
                if (i === lineas.length - 1) break;

                // Si tenemos una linea por debajo
                if (null !== lineas[i + 1]) {
                    aux = lineas[i].orden;
                    lineas[i].orden = lineas[i + 1].orden;
                    lineas[i + 1].orden = aux;
                }

                break;
            }
        }

        this.setState({
            lineasDeComposicion: lineas,
        });

        // Actualizamos la lineas en el producto
        this.props.onChange(lineas, this.state.lineasDeComposicionAEliminar);
    }

    // On delete linea de composicion
    onDelete = event => {
        const id = event.target.id;

        const lineas = this.state.lineasDeComposicion.filter(item => String(item.id) !== id);
        this.setState({
            lineasDeComposicion: lineas,
        });

        // Actualizamos la lineas en el producto
        this.props.onChange(lineas, this.state.lineasDeComposicionAEliminar);

    }

    // On edit linea de composicion
    onEdit = event => {
        const id = event.target.id;

        // Localizamos la linea a editar
        const linea = this.state.lineasDeComposicion.filter(item => String(item.id) === id)[0];
        this.setState({
            lineaAEditar: linea,
            dialogoLineaDeComposicionShow: true,
        });
    }

    // On accept linea de composicion
    onAccept = linea => {
        // Vars
        let lineas = [].concat(this.state.lineasDeComposicion);

        // Si estamos editando substituimos la linea
        if (null !== this.state.lineaAEditar) {
            for (let i = 0; i < lineas.length; ++i){
                if (lineas[i].id === linea.id){
                    lineas[i] = JSON.parse(JSON.stringify(linea));
                    lineas[i].orden = this.state.lineaAEditar.orden;
                    break;
                }
            }
        } else lineas.push(JSON.parse(JSON.stringify(linea)));

        // Ordenamos y reseteamos el orden de las lineas
        lineas.sort(this.ordenaLineas);
        for (let i = 0; i < lineas.length; ++i){
            lineas[i].orden = i;
        }

        this.setState({
            lineasDeComposicion: lineas,
            lineaAEditar: null,
        });

        // Actualizamos la lineas en el producto
        this.props.onChange(lineas, this.state.lineasDeComposicionAEliminar);

    }

    // Formato mismos decimales
    formatoDecimales = n => {
        // forzar siempre n decimales detras de la coma.
        return Number(n).toFixed(APP_CONFIG.NUMERO_DECIMALES);
    }

    renderLineasDeComposicion(){
        let lineas = [].concat(this.state.lineasDeComposicion);
        let resultado = [];

        // Si no hay lineas
        if (lineas.length <= 0) return '';

        // Ordenamos la lineas
        lineas.sort(this.ordenaLineas);

        for (let i = 0; i < lineas.length; ++i){
            let item = lineas[i];

            // Texto libre SN
            if ((undefined !== item.valor) && (undefined !== item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]) && (item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE].length > 0)){
                resultado.push(
                    <div key={item.id + '-' + Math.round(Math.random() * 10000)} id={item.id} className='lineas-de-composicion-linea'>

                        {/* Texto libre */}
                        <div className='lineas-de-composicion-linea-elemento-large'>
                            {item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}
                        </div>

                        <div className='lineas-de-composicion-small-spacer'></div>
                        <div className='lineas-de-composicion-small-spacer'></div>

                        <ButtonDropdown
                            opciones={[
                                {
                                    id: item.id,
                                    label: 'Subir',
                                    callback: this.onMoveUp
                                },
                                {
                                    id: item.id,
                                    label: 'Bajar',
                                    callback: this.onMoveDown
                                },
                                {
                                    id: item.id,
                                    label: 'Editar',
                                    callback: this.onEdit
                                },
                                {
                                    id: item.id,
                                    label: 'Eliminar',
                                    callback: this.onDelete
                                }
                            ]}>Acción</ButtonDropdown>

                    </div>
                );
            } else {
                resultado.push(
                    <div key={item.id + '-' + Math.round(Math.random() * 10000)} id={item.id} className='lineas-de-composicion-linea'>

                        {/* Componente */}
                        <div className='lineas-de-composicion-linea-elemento'>
                            <RawHTML>{this.getComponenteFromId(item.componente)}</RawHTML>
                        </div>

                        {/* Solubilidad */}
                        <div className='lineas-de-composicion-linea-elemento'>
                            {this.getSolubilidadFromId(item.solubilidad)}
                        </div>

                        {/* cantidad 1 */}
                        <div className='lineas-de-composicion-linea-elemento-small-left'>
                            {this.formatoDecimales(item.cantidad_1)}
                            <div className='lineas-de-composicion-small-spacer'></div>
                            <div className='lineas-de-composicion-small-spacer'></div>
                        </div>

                        {/* magnitud 1 */}
                        <div className='lineas-de-composicion-linea-elemento-small-right'>
                            {this.getMagnitudFromId(item.magnitud_1)}
                        </div>

                        <div className='lineas-de-composicion-small-spacer'></div>

                        {/* cantidad 2 */}
                        <div className='lineas-de-composicion-linea-elemento-small-left'>
                            {this.formatoDecimales(item.cantidad_2)}
                            <div className='lineas-de-composicion-small-spacer'></div>
                            <div className='lineas-de-composicion-small-spacer'></div>
                        </div>

                        {/* magnitud 2 */}
                        <div className='lineas-de-composicion-linea-elemento-small-right'>
                            {this.getMagnitudFromId(item.magnitud_2)}
                        </div>

                        <div className='lineas-de-composicion-small-spacer'></div>
                        <div className='lineas-de-composicion-small-spacer'></div>


                        <ButtonDropdown
                            opciones={[
                                {
                                    id: item.id,
                                    label: 'Subir',
                                    callback: this.onMoveUp
                                },
                                {
                                    id: item.id,
                                    label: 'Bajar',
                                    callback: this.onMoveDown
                                },
                                {
                                    id: item.id,
                                    label: 'Editar',
                                    callback: this.onEdit
                                },
                                {
                                    id: item.id,
                                    label: 'Eliminar',
                                    callback: this.onDelete
                                }
                            ]}>Acción</ButtonDropdown>

                    </div>
                );
            }

        }

        return resultado;

    }

    render() {

        // No hay producto
        if ((null === this.props.producto) || (undefined === this.props.producto))
            return (<GreyboxBiovert><HeaderBiovert type='t1'>Composición</HeaderBiovert></GreyboxBiovert>);

        // Comprovamos que tenemos todos los datos
        if (null === this.state.listaDeComponentes ||
            null === this.state.listaDeMagnitudes ||
            null === this.state.lineasDeComposicion
        ) return (<GreyboxBiovert><HeaderBiovert type='t1'>Composición ...</HeaderBiovert></GreyboxBiovert>);

        return (
            <GreyboxBiovert>
                <div className='lineas-de-composicion-biovert-container'>
                    {/* Dialog linea de composicion */}
                    <DialogoLineaDeComposicion
                        show={this.state.dialogoLineaDeComposicionShow}
                        onClose={() => this.setState({ dialogoLineaDeComposicionShow: false })}
                        componentes={this.state.listaDeComponentes}
                        magnitudes={this.state.listaDeMagnitudes}
                        onAccept={this.onAccept}
                        lineaAEditar={this.state.lineaAEditar}/>

                    <div className='lineas-de-composicion-biovert-titulo'>
                        <HeaderBiovert type='t1'>Composición</HeaderBiovert>
                        <div className='lineas-de-composicion-biovert-titulo-boton'>
                            <ButtonBiovert
                                type='primary'
                                label='Nueva composición'
                                handleClick={() => this.setState({ dialogoLineaDeComposicionShow: true, lineaAEditar: null })}
                            />
                        </div>
                    </div>

                    <div className='lineas-de-composicion-lista'>
                        {this.renderLineasDeComposicion()}
                    </div>

                    <div className='lineas-de-composicion-small-vertical-spacer'></div>

                </div>
            </GreyboxBiovert>
        );

    }

}

export default LineasDeComposicionBiovert;
