import React, { Component } from 'react';
import './index.css';

// Componentes
import ButtonBiovert from '../button-biovert';
import GreyboxBiovert from '../greybox-biovert';

class SeccionAceptarCancelarBiovert extends Component {

    render() {

        // Solo aceptar
        if ((undefined !== this.props.onAccept) && (undefined === this.props.onCancel)){
            return (
                <GreyboxBiovert>
                    <div className='aceptar-cancelar-biovert-container'>
                        <div className='aceptar-cancelar-biovert'>
                            <ButtonBiovert type='primary' label='Guardar' handleClick={this.props.onAccept}/>
                        </div>
                    </div>
                </GreyboxBiovert>
            );
        }

        // Solo Cancelar
        if ((undefined === this.props.onAccept) && (undefined !== this.props.onCancel)){
            return (
                <GreyboxBiovert>
                    <div className='aceptar-cancelar-biovert-container'>
                        <div className='aceptar-cancelar-biovert'>
                            <ButtonBiovert label='Cancelar' handleClick={this.props.onCancel}/>
                        </div>
                    </div>
                </GreyboxBiovert>
            );
        }

        // Aceptar y cancelar
        return (
            <GreyboxBiovert>
                <div className='aceptar-cancelar-biovert-container'>
                    <div className='aceptar-cancelar-biovert'>
                        <ButtonBiovert label='Cancelar' handleClick={this.props.onCancel}/>
                        <div className='aceptar-cancelar-biovert-spacer'></div>
                        <ButtonBiovert type='primary' label='Guardar' handleClick={this.props.onAccept}/>
                    </div>
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SeccionAceptarCancelarBiovert;
