import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasCultivosPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeCultivos: null,
            listaDeGruposDeCultivo: null,
            idCultivo: null,
            cultivo: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                grupo_de_cultivo: null,
                imagen: null,
                imagen_icono: null,
            },
            imagenAux: null,
            imagenIconoAux: null,
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de cultivos
        this.getCultivos();
        this.getGruposDeCultivo();
    }

    // Get cultivos de la base de datos
    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeCultivos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get grupo de cultivo de la base de datos
    getGruposDeCultivo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.GRUPOS_DE_CULTIVO,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeGruposDeCultivo: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });

    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idCultivo: null,
        cultivo: {
            nombre: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
        },
        imagenAux: null,
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario
    handleChangeNombre = e => {
        const s = this.state;
        s.cultivo.nombre[this.state.idiomaSeleccionado] = e.target.value;
        this.setState(s);
    }

    handleChangeGrupoDeCultivo = e => {
        const s = this.state;
        s.cultivo.grupo_de_cultivo = Number(e.nativeEvent.target.value);
        this.setState(s);
    }

    handleChangeImagen = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            this.setState({ imagenAux: file });
        }
    }

    handleChangeImagenIcono = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            this.setState({ imagenIconoAux: file });
        }
    }

    handleChangeEliminarImagen = () => {
        this.setState({ imagenAux: 'delete' });
    }

    handleChangeEliminarImagenIcono = () => {
        this.setState({ imagenIconoAux: 'delete' });
    }

    // Guardar cultivo
    guardarFormularioCultivo = () => {
        let data = this.state.cultivo;

        // Comprobamos campo no vacio
        if (this.state.cultivo.nombre[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.cultivo.nombre[this.state.idiomaSeleccionado].length) return true;

        // Comprobamos si hay grupo de cultivo, sino assignamos el primero
        if ((undefined === data.grupo_de_cultivo) || (null === data.grupo_de_cultivo)) {
            data.grupo_de_cultivo = this.state.listaDeGruposDeCultivo[0].id;
        }

        // Llamamos al endpoint
        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: '',
            authorized: true,
            contentType: 'application/json',
            data: data
        };

        if (null === this.state.idCultivo){
            // Si es nuevo
            requestData.method = 'POST';
        } else {
            // Si estamos editando
            requestData.endpoint = requestData.endpoint + this.state.idCultivo + '/';
            requestData.method = 'PUT';
        }
        ApiService.request(requestData)
            .then(response => {
                this.guardarImagen1(Number(JSON.parse(response).id));
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el cultivo</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });

    }

    guardarImagen1 = id => {
        // Comprobamos si hay que eliminar
        if ('delete' === this.state.imagenAux){

            // Llamamos al endpoint para borrar imagen
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + id + '/delete_imagen/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    this.guardarImagen2(id);
                })
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });

        } else if (null !== this.state.imagenAux) {

            // Upload imagen
            const formData = new FormData();
            formData.append('imagen', this.state.imagenAux);

            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + id + '/upload_imagen/',
                method: 'POST',
                authorized: true,
                contentType: '',
                data: formData
            };
            ApiService.request(requestData)
                .then(() => {
                    this.guardarImagen2(id);
                })
                .catch((error) => {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        } else this.guardarImagen2(id);
    }

    guardarImagen2 = id => {
        // Comprobamos si hay que eliminar
        if ('delete' === this.state.imagenIconoAux){

            // Llamamos al endpoint para borrar imagen
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + id + '/delete_imagen_icono/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });

        } else if (null !== this.state.imagenIconoAux) {

            // Upload imagen
            const formData = new FormData();
            formData.append('imagen_icono', this.state.imagenIconoAux);

            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + id + '/upload_imagen_icono/',
                method: 'POST',
                authorized: true,
                contentType: '',
                data: formData
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        } else this.resultadoGuardarOk();
    }

    // Resultado de guardar
    resultadoGuardarOk = () => {
        this.getCultivos();
        toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Cultivo guardado con éxito</span></div>);
        this.setState({
            idCultivo: null,
            cultivo: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                imagen: null,
                imagenIcono: null,
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
        });
    }

    ordenaGruposDeCultivo = (a,b) => {
        const locale = APP_CONFIG.DEFAULT_SELECTED_LOCALE;
        if (a.nombre[locale] > b.nombre[locale]) return 1;
        else if (a.nombre[locale] < b.nombre[locale]) return -1;
        return 0;
    }

    // Calcular select grupos de cultivo
    calculaOpcionGruposDeCultivo = () => {
        if (Array.isArray(this.state.listaDeGruposDeCultivo) && this.state.listaDeGruposDeCultivo.length > 0){
            return (
                this.state.listaDeGruposDeCultivo.sort(this.ordenaGruposDeCultivo).map(
                    item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                )
            );
        } else {
            return '';
        }
    }

    // Editar cultivo
    editarCultivoCallback = (event) => {this.editarCultivo(Number(event.target.id));}

    editarCultivo = (id) => {
        // Cargamos los datos del cultivo
        let auxCultivo = null;
        for (let i = 0; i < this.state.listaDeCultivos.length; ++i){
            if (this.state.listaDeCultivos[i].id === id){
                auxCultivo = JSON.parse(JSON.stringify(this.state.listaDeCultivos[i]));
                break;
            }
        }

        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idCultivo: id,
            cultivo: auxCultivo,
            imagenAux: null,
            imagenIconoAux: null,
        });
    }

    // Eliminar cultivo
    eliminarCultivoCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let nombre = null;
        for (let i = 0; i < this.state.listaDeCultivos.length; ++i){
            if (this.state.listaDeCultivos[i].id === Number(id)){
                nombre = this.state.listaDeCultivos[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === nombre) return true;
        if (window.confirm('¿Desea eliminar el cultivo ' + nombre + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Cultivo eliminado con éxito</span></div>);
                    this.getCultivos();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el cultivo</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaGrupoDeCultivoAMostrar(item){
        if (null === item.grupo_de_cultivo) return '';
        for (let i = 0; i < this.state.listaDeGruposDeCultivo.length; ++i){
            if (Number(this.state.listaDeGruposDeCultivo[i].id) === Number(item.grupo_de_cultivo)) return (this.state.listaDeGruposDeCultivo[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
        }
        return '';
    }

    calculaContenidoParaTabla(){
        // Vars
        let contenido = [];

        // Si no hay datos
        if (this.state.listaDeCultivos == null) return [];
        if (this.state.listaDeGruposDeCultivo == null) return [];

        // Contenido
        for (let i = 0; i < this.state.listaDeCultivos.length; ++i){
            let item = this.state.listaDeCultivos[i];
            contenido.push(
                {
                    id: item.id,
                    fields: [ item.imagen, item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE], this.calculaGrupoDeCultivoAMostrar(item) ],
                    acciones: [
                        { label: 'Editar', callback: this.editarCultivoCallback },
                        { label: 'Eliminar', callback: this.eliminarCultivoCallback }
                    ]
                }
            );
        }
        return contenido;
    }

    calculaImagenMostrar(){
        if ('delete' === this.state.imagenAux) return '';
        if (null === this.state.imagenAux) return this.state.cultivo.imagen;
    }

    calculaImagenIconoMostrar(){
        if ('delete' === this.state.imagenIconoAux) return '';
        if (null === this.state.imagenIconoAux) return this.state.cultivo.imagen_icono;
    }

    renderListaDeElementos(){
        return ([
            <div key='elemento_lista' className='cultivo-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Cultivos' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Cultivos</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir cultivo' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Imagen', 'Nombre', 'Grupo de cultivo' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarCultivo}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.cultivo) return (<div key='ecee'> Error cargando el cultivo</div>);

        // Values
        return ([
            <div key='cultivo_add_edit' className='cultivo-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='cultivo-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Cultivos', 'Nuevo cultivo' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Cultivos', 'Editar cultivo' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nuevo cultivo</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.cultivo.nombre[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='cultivo-formulario'>
                        <div className='cultivo-form-bloque'>
                            <div className='cultivo-form-lbl-nombre'>Nombre cultivo</div>
                            <div className='cultivo-form-nombre'>
                                <input
                                    id='cultivo-add-edit'
                                    type='text'
                                    value={this.state.cultivo.nombre[this.state.idiomaSeleccionado] || ''}
                                    onChange={this.handleChangeNombre}
                                    className='cultivo-add-edit-text-input'
                                />
                            </div>
                        </div>
                        <div className='cultivo-form-bloque'>
                            <div className='cultivo-form-lbl-nombre'>Grupo de cultivo</div>
                            <div className='cultivo-form-nombre'>
                                <select
                                    onChange={this.handleChangeGrupoDeCultivo}
                                    onClick={this.handleChangeGrupoDeCultivo}
                                    defaultValue={this.state.cultivo.grupo_de_cultivo || ''}
                                    className='cultivo-add-edit-grupo-de-cultivo-select'>
                                    {this.calculaOpcionGruposDeCultivo()}
                                </select>
                            </div>
                        </div>
                    </div>
                </GreyboxBiovert>

                <SeccionImagenBiovert
                    titulo='Imagen del cultivo'
                    urlImagen={this.calculaImagenMostrar()}
                    onDelete={this.handleChangeEliminarImagen}
                    onUpload={this.handleChangeImagen}/>

                <SeccionImagenBiovert
                    titulo='Icono'
                    urlImagen={this.calculaImagenIconoMostrar()}
                    onDelete={this.handleChangeEliminarImagenIcono}
                    onUpload={this.handleChangeImagenIcono}/>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarFormularioCultivo}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasCultivosPage;
