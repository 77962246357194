
class AuthService {
    constructor() {
        this.sessionTokenKey = 'session_token';
    }

    setToken(token) {
        localStorage.setItem(this.sessionTokenKey, token);
    }

    getToken() {
        return localStorage.getItem(this.sessionTokenKey);
    }

    reset() {
        localStorage.removeItem(this.sessionTokenKey);
    }

    isAuthenticated() {
        return this.getToken() !== null && this.getToken() !== undefined;
    }

}

export default new AuthService();
