import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';
import DialogoAsociarPais from '../dialogo-asociar-pais';

class SelectPaisesBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDePaises: null,
            paisesSeleccionados: this.props.paises,
            dialogoAsociarPaisShow: false,
        };

    }

    componentDidMount(){
        //Obtenemos la lista de paises
        this.getPaises();
    }


    getPaises(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PAISES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDePaises: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getPaisFromId = id => {
        const pais = this.state.listaDePaises.filter(item => item.codigo_iso_alfa_2 === id)[0];
        if ((null !== pais) && (undefined !== pais)) return pais.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
        return '';
    }

    getPaisFromIdCompleto = id => {
        const pais = this.state.listaDePaises.filter(item => item.codigo_iso_alfa_2 === id)[0];
        if ((null !== pais) && (undefined !== pais)){
            return pais.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] + ' (' + pais.codigo_iso_alfa_2 + ') - (' + pais.codigo_iso_alfa_3 + ') - ' + pais.codigo_iso_numerico;


        } else return '';
    }

    renderPaisesAsociados(){
        const paises = this.state.paisesSeleccionados;
        let result = [];
        for (let i = 0; i < paises.length; ++i){
            result.push(
                <div className='select-paises-biovert-elemento' key={'p' + paises[i]}>

                    {/* Nombre */}
                    <div className='select-paises-biovert-elemento-texto' title={this.getPaisFromIdCompleto(paises[i])}>{this.getPaisFromId(paises[i])}</div>

                    {/* Eliminar */}
                    <div className='select-paises-biovert-elemento-eliminar'><span id={paises[i]} onClick={this.onPaisDelete}>&times;</span></div>

                </div>
            );
        }

        return result;

    }

    // Nuevo pais asociado
    onSelectNuevoPais = p => {
        // Undefined
        if (undefined === p) return;

        let paises = [].concat(this.state.paisesSeleccionados);

        // Por cada pais añadido
        p.forEach(item => {
            // Si el pais ya estaba asociado no hacemos nada
            if (!paises.includes(item)){
                paises.push(item);
            }
        });

        this.setState({
            paisesSeleccionados: paises,
        });

        // onChange
        this.props.onChange(paises);
    }

    // Eliminar pais
    onPaisDelete = e => {
        let id = e.target.id;
        if (this.state.paisesSeleccionados.includes(id)){
            // Si esta lo eliminamos
            let paises = [].concat(this.state.paisesSeleccionados);
            paises = paises.filter(item => item !== id);
            this.setState({
                paisesSeleccionados: paises,
            });
            // onChange
            this.props.onChange(paises);
        }
    }
    clearPaisesSeleccionados = () => {
        const paiseEmpty = [];
        this.setState({ paisesSeleccionados: paiseEmpty });

        this.props.onChange(paiseEmpty);
    }

    render() {
        // Si no hay array datos
        if ((!Array.isArray(this.props.paises))
            || (null === this.state.listaDePaises)
        ) return (<GreyboxBiovert><HeaderBiovert type='t1'>Países...</HeaderBiovert></GreyboxBiovert>);

        return (
            <GreyboxBiovert>
                <div className='select-paises-biovert-container'>
                    {/* Dialog asociar nuevo pais */}
                    <DialogoAsociarPais
                        show={this.state.dialogoAsociarPaisShow}
                        onClose={() => this.setState({ dialogoAsociarPaisShow: false })}
                        paises={this.state.listaDePaises}
                        onClearPaises={this.clearPaisesSeleccionados} // Agrega esta línea
                        onSelect={this.onSelectNuevoPais}
                    />

                    <div className='select-paises-biovert-titulo'>
                        <HeaderBiovert type='t1'>Países</HeaderBiovert>
                        <div className='select-paises-biovert-titulo-boton'>
                            <ButtonBiovert
                                type='primary'
                                label='Asociar país'
                                handleClick={() => this.setState({ dialogoAsociarPaisShow: true })}
                            />
                        </div>
                    </div>

                    <div className='select-paises-biovert-lista'>
                        {this.renderPaisesAsociados()}
                    </div>

                    <div className='select-paises-biovert-small-vertical-spacer'></div>
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SelectPaisesBiovert;
