import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { APP_CONFIG } from '../../utils/globales.js';
import ApiService from '../../services/Api.js';
import AuthService from '../../services/AuthService.js';
import './index.css';

// Componentes
import ButtonLogin from '../button-login';
import InputLogin from '../input-login';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

// Images
import logoWhite from '../../static/manvert-white.png';

class FormLogin extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            emailValue: '',
            passwordValue: '',
            validEmail: false,
            validPassword: false
        };
    }

    isValidEmail = (email) => {
        if (email === ''){
            this.setState({ validEmail: false });
            return true;
        }
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let result = re.test(email);
        this.setState({ validEmail: result });
        return result;
    }

    isValidPassword = (password) => {
        let result = ((password.length >= 6) || (password.length === 0));
        this.setState({ validPassword: result });
        return result;
    }

    emailValueChange = (value) => {
        this.setState({ emailValue: value });
    }

    passwordValueChange = (value) => {
        this.setState({ passwordValue: value });
    }

    iniciarSesion = () => {
        // Comprobamos campos no vacios
        if (
            this.state.emailValue !== '' &&
            this.state.passwordValue !== '' &&
            this.state.validEmail &&
            this.state.validPassword
        ){
            // Autenticamos la sesión
            this.setState({ isLoading: true, loginError: false });

            // Intentamos autenticar
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.AUTH_LOGIN,
                method: 'POST',
                authorized: false,
                contentType: 'application/json',
                data: {
                    email: this.state.emailValue,
                    password: this.state.passwordValue
                }
            };

            ApiService.request(requestData)
                .then((response) => {
                    AuthService.setToken(JSON.parse(response).key);
                    this.setState({ isLoading: false });
                })
                .catch((error) => {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.non_field_errors}</span></div>);
                    //console.log('Error: ' + JSON.stringify(error));
                    this.setState({ isLoading: false });
                });


            /*
            let url = 'http://' + APP_CONFIG.NETWORKING.BACKEND_URL + APP_CONFIG.ENDPOINTS.AUTH_LOGIN;
            fetch(url, {
                method: 'post',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({email: this.state.emailValue, password: this.state.passwordValue})
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error en la autenticacion');
                }
            })
            .then(data => this.setState({ token: data.key, isLoading: false }))
            .catch(error => this.setState({ error, token: '', isLoading: false }));
            */

        }
    }

    render() {

        /* Is authenticated */
        if (AuthService.isAuthenticated()){
            return <Redirect to={ APP_CONFIG.RUTAS.HOME } />;
        }

        /* Is loading */
        if (this.state.isLoading){
            return (
                <div className='cmpt-form-login-contendor'>
                    <div className='cmpt-form-login-centrar'>
                        <FontAwesome name='sync' size='5x' spin style={{ color: 'white', textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }} />
                    </div>
                </div>
            );
        }

        /* Introduccion de datos */
        return (
            <div className='cmpt-form-login-contendor'>
                <div className='cmpt-form-login-centrar'>
                    {/* Imagen */}
                    <img src={logoWhite} alt='Biovert'/>

                    <div className='cmpt-form-login-vertical-spacer'></div>

                    <InputLogin
                        name='email'
                        type='text'
                        placeholder='Email'
                        onValueChange={this.emailValueChange}
                        isValidFunction={this.isValidEmail} />

                    <InputLogin
                        name='password'
                        type='password'
                        placeholder='Contraseña'
                        onValueChange={this.passwordValueChange}
                        isValidFunction={this.isValidPassword} />

                    {/* <Link to={APP_CONFIG.RUTAS.LOGIN_CLAVE_RECUPERAR} className='cmpt-form-login-texto1'>¿Has olvidado la contraseña?</Link> */}


                    <ButtonLogin
                        label='Iniciar sesión'
                        handleClick={this.iniciarSesion}
                        isEnabled={this.state.emailValue !== '' && this.state.passwordValue !== '' && this.state.validEmail && this.state.validPassword}
                    />
                </div>
            </div>
        );

    }

}

export default FormLogin;
