import React, { Component } from 'react';
import './index.css';

class GreyboxBiovert extends Component {

    render() {
        return (
            <div className='greybox-biovert-container'>
                <div className='greybox-biovert'>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

export default GreyboxBiovert;
