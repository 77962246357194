import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';

class DialogoAsociarPais extends Component {

    constructor (props) {
        super(props);
        this.state = {
            paisSeleccionado: null,
        };
    }

    // Seleccionar grupo
    selectGrupoEU = () => this.selectGrupo([
        'BE', 'GR', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'CZ', 'FR', 'HU', 'SI', 'DK', 'HR',
        'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV', 'PL', 'GB'
    ]);
    selectGrupoAELC = () => this.selectGrupo([ 'IS', 'LI', 'NO', 'CH' ]);
    selectGrupoUNASUR = () => this.selectGrupo([ 'AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE' ]);
    selectGrupoTodosSinUE = () => this.selectGrupo([
        'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY',
        'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF',
        'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CD', 'CG', 'CK', 'CR', 'CI', 'CU', 'CW', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV',
        'GQ', 'ER', 'ET', 'FK', 'FO', 'FJ', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'GH', 'GI', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG',
        'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IM', 'IL', 'JM', 'JP', 'JE', 'JO', 'KZ',
        'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LB', 'LS', 'LR', 'LY', 'LI', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MH',
        'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NC', 'NZ', 'NI',
        'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PR', 'QA', 'RE', 'RU',
        'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SB', 'SO',
        'ZA', 'GS', 'SS', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN',
        'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'UM', 'US', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM',
        'ZW'
    ]);
    selectGrupoNinguno = () => this.selectGrupo([
    ]);

    /*
    Tots els paisos
    [
        'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB',
        'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM',
        'CA', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CD', 'CG', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ',
        'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA',
        'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK',
        'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW',
        'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ',
        'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI',
        'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA',
        'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG',
        'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ',
        'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'UM', 'US', 'UY', 'UZ', 'VU',
        'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'
    ]
    */

    selectGrupo = grupo => {

        // Guardamos la nueva asociación
        this.props.onSelect(grupo);

        // Cerramos el dialog
        this.props.onClose();
    }

    // On dialog aceptar
    onDialogAceptar = () => {
        // Guardamos la nueva asociación
        if ((null !== this.state.paisSeleccionado) && (0 !== this.state.paisSeleccionado)){
            this.props.onSelect([ this.state.paisSeleccionado ]);
        }
        // Cerramos el dialog
        this.props.onClose();
    }


        // On dialog aceptar
        ClearAllPaises = () => {
            this.props.onClearPaises(); // Llama a la función onClearPaises en lugar de setState


            // Cerramos el dialog
            this.props.onClose();

        }


        render(){

            // Si dialogo cerrado
            if (!this.props.show) return '';

            const pais = this.state.paisSeleccionado || '';

            return (
                <div className='dialogo-asociar-pais-container'>
                    <div className='dialogo-asociar-pais-modal'>
                        <div className='dialogo-asociar-pais-content'>
                            <div className='dialogo-asociar-pais-content-titulo'>
                                <HeaderBiovert type='t1'>Asociar país</HeaderBiovert>
                                <div className='dialogo-asociar-pais-content-close' onClick={() => this.props.onClose()}>
                                    <span className='dialogo-asociar-pais-content-close-icon'>&times;</span>
                                </div>
                            </div>

                            <div className='dialogo-asociar-pais-vertical-spacer'></div>

                            {/* Seleccionar grupo de paises */}
                            <div className='dialogo-asociar-pais-content-select-pais'>Seleccione un grupo de países</div>
                            <div className='dialogo-asociar-pais-content-grupo' onClick={this.selectGrupoEU}>EU - Unión Europea</div>
                            <div className='dialogo-asociar-pais-content-grupo' onClick={this.selectGrupoAELC}>AELC - Asociación Europea de Libre Comercio</div>
                            <div className='dialogo-asociar-pais-content-grupo' onClick={this.selectGrupoUNASUR}>UNASUR - Unión de Naciones Suramericanas</div>
                            <div className='dialogo-asociar-pais-content-grupo' onClick={this.selectGrupoTodosSinUE}>Todos excluyendo UE</div>

                            <div className='dialogo-asociar-pais-vertical-spacer'></div>
                            <div className='dialogo-asociar-pais-vertical-spacer'></div>

                            {/* Seleccionar pais individual*/}
                            <div className='dialogo-asociar-pais-content-select-pais'>Seleccione un país</div>

                            <div className='dialogo-asociar-pais-content-selects'>

                                {/* paises */}
                                <select
                                    id='paises'
                                    onChange={e => this.setState({ paisSeleccionado: e.nativeEvent.target.value })}
                                    onClick={e => this.setState({ paisSeleccionado: e.nativeEvent.target.value })}
                                    value={pais}
                                    className='dialogo-asociar-pais-select'
                                    title='Pais'>
                                    {this.props.paises.map(
                                        item => <option
                                            key={item.codigo_iso_alfa_2}
                                            value={item.codigo_iso_alfa_2}>
                                            {item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]} ({item.codigo_iso_alfa_2})
                                        </option>
                                    )}
                                </select>

                            </div>

                            <div className='dialogo-asociar-pais-vertical-spacer'></div>
                            <div className='dialogo-asociar-pais-button'>
                                <ButtonBiovert type='secondary' label='Desasociar todos los países' handleClick={() => {this.ClearAllPaises();}}/>

                                <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                            </div>
                        </div>
                    </div>
                </div>
            );

        }

}


export default DialogoAsociarPais;
