import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import BarraLateralBiovert from '../barra-lateral-biovert';
import BarraSuperior from '../barra-superior';

class PaginaBiovert extends Component {

    render() {
        return (
            <div className='pagina-biovert-container'>
                {/* Beta bottom bar */}
                <div className='pagina-biovert-beta-bar'> <b>{APP_CONFIG.VERSION} &nbsp; </b></div>

                {/* Barra superior */}
                <BarraSuperior/>
                {/* Contenido */}
                <div className='pagina-biovert-contenido'>
                    <BarraLateralBiovert/>
                    <div className='pagina-biovert-contenido-derecha'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}

export default PaginaBiovert;
