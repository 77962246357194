import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import CombinacionDeProductosBiovert from '../../../components/combinacion-de-productos-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class SolucionesDetailPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            solucion: null,
            listaDeCombinacionesDeProductos: null,
            listaDeProductosGenericos: null,
            listaDeCultivos: null,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            redirect: false,
        };
    }

    componentDidMount(){
        // Obtenemos la solucion
        if ((null !== this.state.id) && (undefined !== this.state.id)) {
            this.getSolucion();
            this.getCombinacionesDeProductos();
            this.getProductosGenericos();
            this.getCultivos();
        }
    }

    // Get solucion de la base de datos
    getSolucion(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.SOLUCIONES + this.state.id + '/',
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };

        ApiService.request(requestData)
            .then((response) => {
                this.setState({ solucion: JSON.parse(response) });
            })
            .catch((error) => {
                this.setState({ redirect: true });
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get combinaciones de productos de la base de datos
    getCombinacionesDeProductos() {
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMBINACION_DE_PRODUCTOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };

        ApiService.request(requestData)
            .then((response) => {
                const listaDeCombinaciones = JSON.parse(response);
                this.setState({ listaDeCombinacionesDeProductos: listaDeCombinaciones.filter(item => Number(item.solucion) === Number(this.state.id)) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get productos genericos de la base de datos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductosGenericos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get cultivos base de datos
    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeCultivos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    addCombinacionDeProductos = () => {
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMBINACION_DE_PRODUCTOS,
            method: 'POST',
            authorized: true,
            contentType: 'application/json',
            data: {
                solucion: this.state.id,
                observaciones: { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '', },
                productos_genericos: [],
                productos_acabados: []
            }
        };

        ApiService.request(requestData)
            .then(() => {
                this.getCombinacionesDeProductos();
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al generar la combinación de productos</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    onChangeCombinacionesDeProductos = () => this.getCombinacionesDeProductos();

    ordenaCombinacionesDeProductos = (a,b) => {
        if (a.id > b.id) return -1;
        else if (a.id < b.id) return 1;
        else return 0;
    }

    renderCombinacionesDeProductos(){
        let combinacionesDeProductos = this.state.listaDeCombinacionesDeProductos;

        // Ordenamos las combinaciones de productos por id descendente
        combinacionesDeProductos.sort(this.ordenaCombinacionesDeProductos);

        return combinacionesDeProductos.map(item =>
            <div key={'cdp-' + item.id} className='soluciones-detail-combinacion-de-productos'>
                <CombinacionDeProductosBiovert
                    combinacionDeProductos={item}
                    productosGenericos={this.state.listaDeProductosGenericos}
                    cultivos={this.state.listaDeCultivos}
                    onChange={this.onChangeCombinacionesDeProductos}
                    idioma={this.state.idiomaSeleccionado}/>
            </div>
        );
    }

    render() {

        // Si no hay solucion volvemos al view
        if (this.state.redirect) return (<Redirect to={APP_CONFIG.RUTAS.SOLUCIONES_VIEW}/>);

        // Si aun no tenemos datos
        if ((null === this.state.solucion)
          || (null === this.state.listaDeCombinacionesDeProductos)
          || (null === this.state.listaDeProductosGenericos)
          || (null === this.state.listaDeCultivos)
        ) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Solucion
        return (
            <PaginaBiovert>
                <div className='solucion-detail-container'>
                    {/* Breadcrumb  + select idioma */}

                    <div className='solucion-detail-breadcrumb-seleccion-idioma'>
                        <BreadCrumbBiovert value = {[
                            'Soluciones',
                            this.state.solucion.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]
                        ]}/>
                        <SelectIdioma onSelect={idioma => this.setState({ idiomaSeleccionado: idioma })}/>
                    </div>

                    <HeaderBiovert type='t1'>{this.state.solucion.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</HeaderBiovert>

                    {/* Formulario */}
                    <GreyboxBiovert>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                            <HeaderBiovert type='t1'>Combinaciones de productos</HeaderBiovert>
                            <ButtonBiovert type='primary' label='Añadir combinación' handleClick={this.addCombinacionDeProductos}/>
                        </div>
                        <div className=''>
                            {this.renderCombinacionesDeProductos()}
                        </div>
                    </GreyboxBiovert>

                </div>
            </PaginaBiovert>
        );

    }

}

export default SolucionesDetailPage;
