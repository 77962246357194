import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Fontawesome
import FontAwesome from 'react-fontawesome';

// Images
import iconBottle from '../../static/icon-bottle.png';
import iconLeaf from '../../static/icon-leaf.png';
import iconDrop from '../../static/icon-drop.png';
import iconSquares from '../../static/icon-squares.png';
import iconEnsayos from '../../static/icon-ensayos.png';
import iconInforme from '../../static/solicitud.png';
class BarraLateralBiovert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenuProductos: false,
            showMenuMaestras: false
        };
        this.showMenuProductos = 'MENU_PRODUCTS';
        this.showMenuMaestras = 'MENU_MASTERS';

        // Comprobamos valores local storage del menu
        if (null === localStorage.getItem(this.showMenuProductos))
            localStorage.setItem(this.showMenuProductos, 'H');



        if (null === localStorage.getItem(this.showMenuMaestras))
            localStorage.setItem(this.showMenuMaestras, 'H');



    }

    onChangeShowMenuProductos = () => {
        if (localStorage.getItem(this.showMenuProductos) === 'S')
            localStorage.setItem(this.showMenuProductos, 'H');
        else
            localStorage.setItem(this.showMenuProductos, 'S');



        this.setState({
            showMenuProductos: !this.state.showMenuProductos
        });
    };

    onChangeShowMenuMaestras = () => {
        if (localStorage.getItem(this.showMenuMaestras) === 'S')
            localStorage.setItem(this.showMenuMaestras, 'H');
        else
            localStorage.setItem(this.showMenuMaestras, 'S');



        this.setState({
            showMenuMaestras: !this.state.showMenuMaestras
        });
    };

    render() {
        return (
            <div className='barra-lateral-biovert'>
                {/* Productos */}
                <div className='barra-lateral-biovert-elemento'
                    onClick={
                        this.onChangeShowMenuProductos
                    }>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconBottle}
                            alt='Biovert'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Productos</div>
                    <div className='barra-lateral-biovert-elemento-flecha'>
                        {
                            localStorage.getItem(this.showMenuProductos) === true ? (
                                <FontAwesome name='chevron-up'/>
                            ) : (
                                <FontAwesome name='chevron-down'/>
                            )
                        } </div>
                </div>

                {
                    localStorage.getItem(this.showMenuProductos) === 'S' ? (
                        <Link to={
                            APP_CONFIG.RUTAS.PRODUCTOS_GENERICOS_VIEW
                        }
                        className='barra-lateral-biovert-elemento-submenu'>
                            <div className='barra-lateral-biovert-elemento-texto-submenu'>
                            Genéricos
                            </div>
                        </Link>
                    ) : ('')
                }

                {
                    localStorage.getItem(this.showMenuProductos) === 'S' ? (
                        <Link to={
                            APP_CONFIG.RUTAS.PRODUCTOS_ACABADOS_VIEW
                        }
                        className='barra-lateral-biovert-elemento-submenu'>
                            <div className='barra-lateral-biovert-elemento-texto-submenu'>
                            Acabados
                            </div>
                        </Link>
                    ) : ('')
                }

                {/* Cultivos */}
                <Link to={
                    APP_CONFIG.RUTAS.CULTIVOS_VIEW
                }
                className='barra-lateral-biovert-elemento'>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconLeaf}
                            alt='Cultivos'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Cultivos</div>
                </Link>

                {/* Ensayos */}
                <Link to={
                    APP_CONFIG.RUTAS.ENSAYOS_VIEW
                }
                className='barra-lateral-biovert-elemento'>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconEnsayos}
                            alt='Ensayos'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Ensayos</div>
                </Link>

                {/* Soluciones */}
                <Link to={
                    APP_CONFIG.RUTAS.SOLUCIONES_VIEW
                }
                className='barra-lateral-biovert-elemento'>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconDrop}
                            alt='Soluciones'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Soluciones</div>
                </Link>

                {/* Soluciones */}
                <Link to={
                    APP_CONFIG.RUTAS.INFORME_VIEW
                }
                className='barra-lateral-biovert-elemento'>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconInforme}
                            alt='Informes'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Informes</div>
                </Link>

                {/* Maestras */}
                <div className='barra-lateral-biovert-elemento'
                    onClick={
                        this.onChangeShowMenuMaestras
                    }>
                    <div className='barra-lateral-biovert-elemento-icono'>
                        <img src={iconSquares}
                            alt='Biovert'/>
                    </div>
                    <div className='barra-lateral-biovert-elemento-texto'>Maestras</div>
                    <div className='barra-lateral-biovert-elemento-flecha'>
                        {
                            localStorage.getItem(this.showMenuMaestras) === true ? (
                                <FontAwesome name='chevron-up'/>
                            ) : (
                                <FontAwesome name='chevron-down'/>
                            )
                        } </div>
                </div>

                {
                    localStorage.getItem(this.showMenuMaestras) === 'S' ? (
                        <div className='barra-lateral-biovert-grupo-maestras'>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_FAMILIAS
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Familias
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_ICONOS
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Iconos
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_ENVASES
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Envases
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_COMPONENTESQUIMICOS
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Componentes químicos
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_MAGNITUDES
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Magnitudes
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_CULTIVOS
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Cultivos
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_ESTADOSFENOLOGICOS
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Estados fenológicos
                                </div>
                            </Link>
                            <Link to={
                                APP_CONFIG.RUTAS.MAESTRAS_SOLUCIONES
                            }
                            className='barra-lateral-biovert-elemento-submenu'>
                                <div className='barra-lateral-biovert-elemento-texto-submenu'>
                                Soluciones
                                </div>
                            </Link>
                        </div>
                    ) : ('')
                }

                {/* Usuarios */}
                {/*
                <Link to={APP_CONFIG.RUTAS.USUARIOS} className='barra-lateral-biovert-elemento'>
                    <div className='barra-lateral-biovert-elemento-icono'><img src={iconUsers} alt='Soluciones'/></div>
                    <div className='barra-lateral-biovert-elemento-texto'>Usuarios</div>
                </Link>
               */} </div>
        );
    }
}

export default BarraLateralBiovert;
