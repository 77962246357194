import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import HeaderBiovert from '../header-biovert';


class SelectEnvasesBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeEnvases: null,
            listaDeMagnitudes: null,
            envasesSeleccionados: this.props.envases,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de envases y magnitudes
        this.getEnvases();
        this.getMagnitudes();
    }

    // Get envases de la base de datos
    getEnvases(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENVASES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeEnvases: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get magnitudes de la base de datos
    getMagnitudes(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeMagnitudes: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get magnitud from id
    getMagnitudFromId = envase => {
        const magnitud = this.state.listaDeMagnitudes.filter(item => item.id === envase.magnitud)[0];
        if ((null === magnitud) || (undefined === magnitud)) return '';
        return magnitud.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
    }

    // Ordena envases por valor
    ordenaEnvasesPorNombre = (a, b) => {
        if (a.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] < b.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]) return -1;
        if (a.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] > b.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]) return 1;
        return 0;
    }

    // Calcula envases
    calculaEnvases = () => {

        // Si no hay envases
        if (null === this.state.listaDeEnvases) return [];

        // Ordenamos los envases
        let envases = [].concat(this.state.listaDeEnvases).sort(this.ordenaEnvasesPorNombre);
        let l = [];
        let className = 'select-envases-biovert-elemento';

        for (let i = 0; i < envases.length; ++i){
            if (this.state.envasesSeleccionados.includes(envases[i].id)) className = 'select-envases-biovert-elemento-active';
            else className = 'select-envases-biovert-elemento';
            l.push(
                <div
                    id={envases[i].id}
                    key={envases[i].id}
                    onClick={this.envaseOnClick}
                    className={className}>
                    <div id={envases[i].id} className='select-envases-biovert-elemento-imagen'>
                        {(null !== envases[i].imagen)
                            ? <img id={envases[i].id} src={envases[i].imagen} alt='' className='select-envases-biovert-imagen' onClick={this.envaseOnClick}/>
                            : ''}
                    </div>
                    <div id={envases[i].id} className='select-envases-biovert-elemento-texto'>
                        {envases[i].valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] + ' ' + this.getMagnitudFromId(envases[i])}
                    </div>
                </div>
            );
        }

        return l;
    }

    // Icono on click
    envaseOnClick = e => {
        let envases = [].concat(this.state.envasesSeleccionados);
        const envaseSeleccionado = Number(e.target.id);

        if (envases.includes(envaseSeleccionado)){
            envases = envases.filter(item => item !== envaseSeleccionado);
        } else {
            envases.push(envaseSeleccionado);
        }

        this.setState({ envasesSeleccionados: envases });
        this.props.onChange(envases);

    }

    render() {

        // Si no hay array de envases
        if ((!Array.isArray(this.props.envases))
            || (null === this.state.listaDeEnvases)
            || (null === this.state.listaDeMagnitudes)
        ) return (<GreyboxBiovert><HeaderBiovert type='t1'>Envases...</HeaderBiovert></GreyboxBiovert>);

        return (
            <GreyboxBiovert>
                <HeaderBiovert type='t1'>Envases</HeaderBiovert>
                <div className='select-envases-biovert-container'>
                    {this.calculaEnvases()}
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SelectEnvasesBiovert;
