import React, { Component } from 'react';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';

class SelectMultiplePastilla extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeElementos: this.props.listaDeElementos,
            elementosSeleccionados: this.props.elementosSeleccionados,
            elementoSeleccionadoDialogo: null,
            mostrarDialogo: false,
            asociarTodos: this.props.asociarTodos || false,
        };
    }

    componentDidMount(){}

    getValueFromId = id => {
        const value = this.state.listaDeElementos.filter(e => e.id === id);
        if (value.length > 0){
            return value[0].value;
        }
        return '';
    }

    getDescriptionFromId = id => {
        const description = this.state.listaDeElementos.filter(e => e.id === id);
        if (description.length > 0){
            if (description[0].description)
                return description[0].description;
        }
        return '';
    }

    renderElementosAsociados(){
        let result = [];

        this.state.elementosSeleccionados.sort((a,b) => {
            if (this.getValueFromId(a) > this.getValueFromId(b)) return 1;
            if (this.getValueFromId(a) < this.getValueFromId(b)) return -1;
            return 0;
        }).forEach(e => {
            result.push(
                <div className='select-multiple-pastilla-elemento' key={'p' + e}>
                    {/* Nombre */}
                    <div className='select-multiple-pastilla-elemento-texto' title={this.getDescriptionFromId(e)}>{this.getValueFromId(e)}</div>
                    {/* Eliminar */}
                    <div className='select-multiple-pastilla-elemento-eliminar'><span id={e} onClick={this.onElementoDelete}>&times;</span></div>
                </div>
            );
        });

        return result;
    }

    asociarTodos = () => {
        let elementosSeleccionadosNuevos = [];

        this.state.listaDeElementos.forEach(e => {
            elementosSeleccionadosNuevos.push(e.id);
        });

        this.setState({ elementosSeleccionados: elementosSeleccionadosNuevos });
        this.props.onChange(elementosSeleccionadosNuevos);
    }

    // Dialogo
    onDialogAceptar = () => {
        let elementosSeleccionadosNuevos = [].concat(this.state.elementosSeleccionados);

        if (null !== this.state.elementoSeleccionadoDialogo && this.state.elementoSeleccionadoDialogo > 0) {
            if (!elementosSeleccionadosNuevos.includes(this.state.elementoSeleccionadoDialogo))
                elementosSeleccionadosNuevos.push(this.state.elementoSeleccionadoDialogo);
        }

        this.setState({ mostrarDialogo: false, elementosSeleccionados: elementosSeleccionadosNuevos });
        this.props.onChange(elementosSeleccionadosNuevos);
    }

    // Eliminar elemento
    onElementoDelete = e => {
        let id = Number(e.target.id);
        let elementos = [].concat(this.state.elementosSeleccionados);

        if (elementos.includes(id)){
            // Si esta lo eliminamos
            elementos = elementos.filter(item => item !== id);
            this.setState({
                elementosSeleccionados: elementos,
            });
            this.props.onChange(elementos);
        }
    }

    render() {

        // Si no hay array datos
        if ((!Array.isArray(this.state.elementosSeleccionados)) || (null === this.state.listaDeElementos))
            return (<GreyboxBiovert><HeaderBiovert type='t1'>{this.props.titulo} ...</HeaderBiovert></GreyboxBiovert>);

        if (this.state.mostrarDialogo) {

            // Si mostrar dialogo
            const productoSeleccionado = this.state.elementoSeleccionadoDialogo || '';
            return (
                <div className='select-multiple-pastilla-dialogo-container'>
                    <div className='select-multiple-pastilla-dialogo-modal'>
                        <div className='select-multiple-pastilla-dialogo-content'>
                            <div className='select-multiple-pastilla-dialogo-content-titulo'>
                                <HeaderBiovert type='t1'>{this.props.tituloAsociar}</HeaderBiovert>
                                <div className='select-multiple-pastilla-dialogo-content-close' onClick={() => this.setState({ mostrarDialogo: false })}>
                                    <span className='select-multiple-pastilla-dialogo-content-close-icon'>&times;</span>
                                </div>
                            </div>

                            <div className='select-multiple-pastilla-vertical-spacer'></div>

                            {/* Seleccionar elemento*/}
                            <div className='select-multiple-pastilla-dialogo-content-select-elemento'>{this.props.tituloSeleccionar}</div>

                            <div className='select-multiple-pastilla-dialogo-content-selects'>
                                <select
                                    id='elementos'
                                    onChange={e => this.setState({ elementoSeleccionadoDialogo: Number(e.nativeEvent.target.value) })}
                                    onClick={e => this.setState({ elementoSeleccionadoDialogo: Number(e.nativeEvent.target.value) })}
                                    value={productoSeleccionado}
                                    className='select-multiple-pastilla-dialogo-select'
                                    title='Elemento'>
                                    <option key={'0'} value={-1}></option>
                                    {this.state.listaDeElementos.filter(e => !this.state.elementosSeleccionados.includes(e.id))
                                        .sort((a, b) => {
                                            if (a.value > b.value) return 1;
                                            if (a.value < b.value) return -1;
                                            return 0;
                                        }).map(
                                            item => <option
                                                key={item.id}
                                                value={item.id}>
                                                {item.value} {item.description ? '(' + item.description + ')' : ''}
                                            </option>
                                        )}
                                </select>
                            </div>

                            <div className='select-multiple-pastilla-vertical-spacer'></div>

                            <div className='select-multiple-pastilla-dialogo-button-right'>
                                <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

        // Mostramos elementos
        return (
            <GreyboxBiovert>
                <div className='select-multiple-pastilla-container'>
                    <div className='select-multiple-pastilla-titulo'>
                        <HeaderBiovert type='t1'>{this.props.titulo}</HeaderBiovert>
                        <div className='select-multiple-pastilla-titulo-botones'>
                            <div className='select-multiple-pastilla-titulo-boton'>
                                <ButtonBiovert
                                    type='primary'
                                    label={this.props.tituloAsociar}
                                    handleClick={() => this.setState({ mostrarDialogo: true })}
                                />
                            </div>

                            { this.state.asociarTodos
                                ? <div className='select-multiple-pastilla-titulo-boton'>
                                    <ButtonBiovert
                                        type='primary'
                                        label='Asociar todos'
                                        handleClick={() => this.asociarTodos()}/>
                                </div>
                                : ''}
                        </div>
                    </div>

                    <div className='select-multiple-pastilla-lista'>
                        {this.renderElementosAsociados()}
                    </div>

                    <div className='select-multiple-pastilla-small-vertical-spacer'></div>
                </div>
            </GreyboxBiovert>
        );
    }
}

export default SelectMultiplePastilla;
