import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../components/pagina-biovert';
import LoadingBiovert from '../../components/loading-biovert';
import BreadCrumbBiovert from '../../components/breadcrumb-biovert';
import HeaderBiovert from '../../components/header-biovert';
import GreyboxBiovert from '../../components/greybox-biovert';
import ButtonBiovert from '../../components/button-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class PerfilPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            usuario: null,
            new_password1: null,
            new_password2: null,
            show1: false,
            show2: false,
        };
    }

    componentDidMount(){
        this.getUsuario();
    }

    // Obtenesmos el usuario que ha iniciado sesión
    getUsuario(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.AUTH_USER,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ usuario: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    changePassword = () => {
        // Comprobamos campos no vacios
        if (('' === this.state.new_password1)
          || (null === this.state.new_password1)
          || ('' === this.state.new_password2)
          || (null === this.state.new_password2)){
            toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Debe introducir un valor valido para la contraseña</span></div>);
        } else {
            // Comprovamos longitud
            if ((this.state.new_password1.length < APP_CONFIG.MIN_PASSWORD_LENGTH)
              || (this.state.new_password2.length < APP_CONFIG.MIN_PASSWORD_LENGTH)){
                toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Las contraseña es demasiado corta, minimo {APP_CONFIG.MIN_PASSWORD_LENGTH} caracteres</span></div>);
            } else {
                // Comprobamos valores iguales
                if (this.state.new_password1 === this.state.new_password2) {

                    let requestData = {
                        endpoint: APP_CONFIG.ENDPOINTS.AUTH_CHANGE_PASSWORD,
                        method: 'POST',
                        authorized: true,
                        contentType: 'application/json',
                        data: {
                            new_password1: this.state.new_password1,
                            new_password2: this.state.new_password2,
                        },
                    };
                    ApiService.request(requestData)
                        .then(() => {
                            toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Contraseña cambiada con éxito</span></div>);
                            this.setState({
                                new_password1: '',
                                new_password2: '',
                            });
                        })
                        .catch((error) => {
                            console.log('Error: ' + JSON.stringify(error));
                        });

                } else {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Las contraseñas no coinciden</span></div>);
                }
            }
        }
    }

    render() {

        // Si aun no tenemos datos
        if (null === this.state.usuario) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        return (
            <PaginaBiovert>
                <div key='elemento_lista' className='perfil-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Perfil', this.state.usuario.nombre + ' ' + this.state.usuario.apellidos ]}/>

                    <HeaderBiovert type='t1'>Perfil</HeaderBiovert>

                    <GreyboxBiovert>
                        <div className='perfil-datos'>
                            <div className='perfil-texto-label'>Email:</div>
                            <div className='perfil-texto-texto'>{this.state.usuario.email}</div>

                            <div className='perfil-texto-label'>Nombre:</div>
                            <div className='perfil-texto-texto'>{this.state.usuario.nombre}</div>

                            <div className='perfil-texto-label'>Apellidos:</div>
                            <div className='perfil-texto-texto'>{this.state.usuario.apellidos}</div>
                        </div>
                    </GreyboxBiovert>

                    <div className='perfil-vertical-spacer'></div>

                    <HeaderBiovert type='t1'>Cambiar contraseña</HeaderBiovert>

                    <GreyboxBiovert>
                        <div className='perfil-datos'>
                            <div className='perfil-texto-label'>Nueva contranseña</div>
                            <div className='perfil-texto-texto'>
                                <input
                                    type={!this.state.show1 ? 'password' : 'text'}
                                    value={this.state.new_password1 || ''}
                                    onChange={e => this.setState({ new_password1: e.target.value.trim() })}
                                />
                                &nbsp;&nbsp;&nbsp;<div onClick={() => this.setState({ show1: !this.state.show1 })}><FontAwesome name={this.state.show1 ? 'eye' : 'eye-slash'}/></div>
                            </div>

                            <div className='perfil-texto-label'>Repetir contraseña</div>
                            <div className='perfil-texto-texto'>
                                <input
                                    type={!this.state.show2 ? 'password' : 'text'}
                                    value={this.state.new_password2 || ''}
                                    onChange={e => this.setState({ new_password2: e.target.value.trim() })}
                                />
                                &nbsp;&nbsp;&nbsp;<div onClick={() => this.setState({ show2: !this.state.show2 })}><FontAwesome name={this.state.show2 ? 'eye' : 'eye-slash'}/></div>
                            </div>
                        </div>

                        <div className='perfil-button-right'>
                            <ButtonBiovert type='primary' label='Cambiar contraseña' handleClick={this.changePassword}/>
                        </div>
                    </GreyboxBiovert>

                </div>
            </PaginaBiovert>
        );
    }
}

export default PerfilPage;
