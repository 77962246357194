
const MaxValue = 99999.99999;
const parteEntera = Math.floor(MaxValue).toString();
const parteDecimal = MaxValue.toString().split('.')[1];
const MaxValueLenght = parteEntera.length + parteDecimal.length;

module.exports = {
    MaxValue,
    MaxValueLenght,
};
