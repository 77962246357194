import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import HeaderBiovert from '../../../components/header-biovert';

class SolucionesViewPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeSoluciones: null,
            redirectId: null,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de soluciones
        this.getSoluciones();
    }

    // Get cultivos de la base de datos
    getSoluciones(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.SOLUCIONES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeSoluciones: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    calculaContenidoParaTabla(){
        // Vars
        let contenido = [];

        // Si no hay datos
        if (this.state.listaDeSoluciones == null) return [];

        // Contenido
        for (let i = 0; i < this.state.listaDeSoluciones.length; ++i){
            let item = this.state.listaDeSoluciones[i];
            contenido.push(
                {
                    id: item.id,
                    fields: [ item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] ],
                    acciones: []
                }
            );
        }
        return contenido;
    }

    render() {

        // Editar
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.SOLUCIONES_DETAIL + '/' + this.state.redirectId}/>);

        // Si aun no tenemos datos
        if (null === this.state.listaDeSoluciones) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        return (
            <PaginaBiovert>
                <div key='elemento_lista' className='solucion-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Soluciones' ]}/>

                    {/* Titulo */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                        <HeaderBiovert type='t1'>Soluciones</HeaderBiovert>
                    </div>

                    <GreyboxBiovert>
                        Seleccione la solución a configurar
                    </GreyboxBiovert>

                    <br></br>
                    <TablaBiovert
                        cabeceras={[ 'Nombre' ]}
                        contenido={this.calculaContenidoParaTabla()}
                        onRowClick={id => this.setState({ redirectId: id })}
                        noShorten={true}/>
                </div>
            </PaginaBiovert>
        );

    }

}

export default SolucionesViewPage;
