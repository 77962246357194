import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';
import DialogoAsociarCultivo from '../dialogo-asociar-cultivo';

class SelectCultivosBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeGruposDeCultivo: null,
            listaDeCultivos: null,
            listaDeEstadosFenologicos: null,
            estadosFenologicosSeleccionados: this.props.estados_fenologicos,
            dialogoAsociarCultivoShow: false,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de grupos de cultivo
        this.getGruposDeCultivo();

        //Obtenemos la lista de cultivos
        this.getCultivos();

        //Obtenemos la lista de estadosFenologicos
        this.getEstadosFenologicos();

    }

    getGruposDeCultivo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.GRUPOS_DE_CULTIVO,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeGruposDeCultivo: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeCultivos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getEstadosFenologicos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeEstadosFenologicos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getEstadoFenologicoFromId = id => this.state.listaDeEstadosFenologicos.filter(item => item.id === id)[0];
    getCultivoFromId = id => this.state.listaDeCultivos.filter(item => item.id === id)[0];
    getGrupoDeCultivoFromId = id => this.state.listaDeGruposDeCultivo.filter(item => item.id === id)[0];

    ordenaCutivos = (a,b) => {
        if (a.grupoDeCultivo > b.grupoDeCultivo) {
            return 1;
        } else if (a.grupoDeCultivo < b.grupoDeCultivo) {
            return -1;
        } else {
            // Mismo grupo de cultivo
            if (a.cultivo > b.cultivo) {
                return 1;
            } else if (a.cultivo < b.cultivo) {
                return -1;
            } else {
                // Mismo cultivo
                if (a.estadoFenologico > b.estadoFenologico) {
                    return 1;
                } else if (a.estadoFenologico < b.estadoFenologico) {
                    return -1;
                } else {
                    // Todo igual
                    return 0;
                }
            }
        }
    }

    calculaTodosLosDatosDeCultivos(){
        // Vars
        let cultivos = [];
        let estadoFenologico = null;
        let estadoFenologicoSelected = 0;
        let cultivo = null;
        let grupoDeCultivo = null;
        let cultivoAux = null;

        // Calculamos todos los datos
        for (let i = 0; i < this.state.estadosFenologicosSeleccionados.length; ++i){

            // Cultivo aux
            cultivoAux = { grupoDeCultivo: 0, cultivo: 0, estadoFenologico: 0, id: 0, };

            // Estado fenologico
            estadoFenologicoSelected = this.state.estadosFenologicosSeleccionados[i];
            estadoFenologico = this.getEstadoFenologicoFromId(estadoFenologicoSelected);
            cultivoAux.estadoFenologico = estadoFenologico.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
            cultivoAux.id = estadoFenologicoSelected;

            // Cultivo
            cultivo = this.getCultivoFromId(estadoFenologico.cultivo);
            cultivoAux.cultivo = cultivo.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];

            // Grupo de cultivo
            grupoDeCultivo = this.getGrupoDeCultivoFromId(cultivo.grupo_de_cultivo);
            cultivoAux.grupoDeCultivo = grupoDeCultivo.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];

            cultivos.push(cultivoAux);
        }

        // Ordenamos los resultados
        cultivos.sort(this.ordenaCutivos);

        return cultivos;
    }

    renderCultivosAsociados(){
        let pagina = [];
        let cultivos = [];

        // Calcula array con todos los datos
        cultivos = this.calculaTodosLosDatosDeCultivos();

        for (let i = 0; i < cultivos.length; ++i){
            pagina.push(
                <div key={cultivos[i].id} className='select-cultivos-biovert-linea-cultivo'>

                    {/* Grupo de cultivo */}
                    <div className='select-cultivos-biovert-linea-cultivo-elemento'>
                        {cultivos[i].grupoDeCultivo}
                    </div>
                    {/* Cultivo */}
                    <div className='select-cultivos-biovert-linea-cultivo-elemento'>
                        {cultivos[i].cultivo}
                    </div>
                    {/* Estado fenologico */}
                    <div className='select-cultivos-biovert-linea-cultivo-elemento'>
                        {cultivos[i].estadoFenologico}
                    </div>
                    <div className='select-cultivos-biovert-spacer'></div>
                    {/* Boton */}
                    <ButtonBiovert
                        type='primary-outline-only'
                        label='Eliminar'
                        handleClick={this.onCultivoDelete}
                        id={cultivos[i].id}
                    />

                </div>
            );
        }

        return (pagina);

    }

    // Nuevo cultivo asociado
    onSelectNuevoCultivo = id => {
        // Undefined
        if (undefined === id) return;

        // Si el cultivo ya estaba asociado no hacemos nada
        if (!this.state.estadosFenologicosSeleccionados.includes(id)){
            // Si no esta incluido lo añadimos
            let estadosFenologicos = [].concat(this.state.estadosFenologicosSeleccionados);
            estadosFenologicos.push(id);
            this.setState({
                estadosFenologicosSeleccionados: estadosFenologicos,
            });
            // onChange
            this.props.onChange(estadosFenologicos);
        }
    }

    // Eliminar cultivo
    onCultivoDelete = e => {
        let id = Number(e.target.id);
        if (this.state.estadosFenologicosSeleccionados.includes(id)){
            // Si esta lo eliminamos
            let estadosFenologicos = [].concat(this.state.estadosFenologicosSeleccionados);
            estadosFenologicos = estadosFenologicos.filter(item => item !== id);
            this.setState({
                estadosFenologicosSeleccionados: estadosFenologicos,
            });
            // onChange
            this.props.onChange(estadosFenologicos);
        }
    }

    render() {

        // Si no hay array datos
        if (
            !Array.isArray(this.props.estados_fenologicos) ||
            null === this.state.listaDeGruposDeCultivo ||
            null === this.state.listaDeCultivos ||
            null === this.state.listaDeEstadosFenologicos
        ) return (<GreyboxBiovert><HeaderBiovert type='t1'>Cultivos...</HeaderBiovert></GreyboxBiovert>);

        return (
            <GreyboxBiovert>
                <div className='select-cultivos-biovert-container'>
                    {/* Dialog asociar nuevo cultivo */}
                    <DialogoAsociarCultivo
                        show={this.state.dialogoAsociarCultivoShow}
                        onClose={() => this.setState({ dialogoAsociarCultivoShow: false })}
                        gruposDeCultivo={this.state.listaDeGruposDeCultivo}
                        cultivos={this.state.listaDeCultivos}
                        estadosFenologicos={this.state.listaDeEstadosFenologicos}
                        onSelect={this.onSelectNuevoCultivo}
                    />

                    <div className='select-cultivos-biovert-titulo'>
                        <HeaderBiovert type='t1'>Cultivos</HeaderBiovert>
                        <div className='select-cultivos-biovert-titulo-boton'>
                            <ButtonBiovert
                                type='primary'
                                label='Asociar cultivo'
                                handleClick={() => this.setState({ dialogoAsociarCultivoShow: true })}
                            />
                        </div>
                    </div>

                    <div className='select-cultivos-biovert-lista-de-cultivos'>
                        {this.renderCultivosAsociados()}
                    </div>

                    <div className='select-cultivos-biovert-small-vertical-spacer'></div>
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SelectCultivosBiovert;
