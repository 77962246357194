import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';

class InputLogin extends Component {

    static get propTypes(){
        return {
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            placeholder: PropTypes.string.isRequired
        };
    }

    constructor(props){
        super(props);
        this.state = {
            is_valid: true,
        };
    }

    handleOnChange = (e) => {
        this.setState({ is_valid: this.props.isValidFunction(e.target.value) });
        //Return value with callback
        this.props.onValueChange(e.target.value);
    }

    render() {
        return (
            <input
                name={this.props.name}
                type = {this.props.type}
                placeholder = {this.props.placeholder}
                className = {this.state.is_valid ? 'cmpt-input-login-estado-ok' : 'cmpt-input-login-estado-nok'}
                onChange = {this.handleOnChange}
            />
        );
    }

}

export default InputLogin;
