import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import HeaderBiovert from '../header-biovert';


class SelectIconosBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeIconos: null,
            iconosSeleccionados: this.props.iconos,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de iconos
        this.getIconos();
    }

    // Get iconos de la base de datos
    getIconos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ICONOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeIconos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Ordena iconos por nombre
    ordenaIconosPorNombre = (a, b) => {
        if (a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] < b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]) return -1;
        if (a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] > b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]) return 1;
        return 0;
    }

    // Calcula iconos
    renderIconos(){

        // Si no hay iconos
        if (null === this.state.listaDeIconos) return [];

        // Ordenamos los iconos
        let iconos = [].concat(this.state.listaDeIconos).sort(this.ordenaIconosPorNombre);
        let l = [];
        let className = 'select-iconos-biovert-elemento';

        for (let i = 0; i < iconos.length; ++i){
            if (this.state.iconosSeleccionados.includes(iconos[i].id)) className = 'select-iconos-biovert-elemento-active';
            else className = 'select-iconos-biovert-elemento';
            l.push(
                <div
                    id={iconos[i].id}
                    key={iconos[i].id}
                    onClick={this.iconoOnClick}
                    className={className}>
                    <div id={iconos[i].id} className='select-iconos-biovert-elemento-imagen'>
                        {(null !== iconos[i].imagen)
                            ? <img id={iconos[i].id} src={iconos[i].imagen} alt='' className='select-iconos-biovert-imagen' onClick={this.iconoOnClick}/>
                            : ''}
                    </div>
                    <div id={iconos[i].id} className='select-iconos-biovert-elemento-texto'>
                        {iconos[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}
                    </div>
                </div>
            );
        }

        return l;
    }

    // Icono on click
    iconoOnClick = e => {
        let iconos = [].concat(this.state.iconosSeleccionados);
        const iconoSeleccionado = Number(e.target.id);

        if (iconos.includes(iconoSeleccionado)){
            iconos = iconos.filter(item => item !== iconoSeleccionado);
        } else {
            iconos.push(iconoSeleccionado);
        }

        this.setState({ iconosSeleccionados: iconos });
        this.props.onChange(iconos);

    }

    render() {

        // Si no hay array de iconos
        if (!Array.isArray(this.props.iconos)) return (<GreyboxBiovert><HeaderBiovert type='t1'>Iconos...</HeaderBiovert></GreyboxBiovert>);

        return (
            <GreyboxBiovert>
                <HeaderBiovert type='t1'>Iconos</HeaderBiovert>
                <div className='select-iconos-biovert-container'>
                    {this.renderIconos()}
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SelectIconosBiovert;
