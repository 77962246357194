import React, { Component } from 'react';
import './index.css';

import FormLogin from '../../components/form-login';

class LoginPage extends Component {

    render() {
        return (
            <div>
                {/* Imagen de fondo efecto borroso */}
                <div className='frm-login-fondo'></div>

                {/* Contenedor del formulario de login */}
                <div className='frm-login-contenido'>
                    <FormLogin/>
                </div>

            </div>
        );
    }
}

export default LoginPage;
