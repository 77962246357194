import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import '../index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';
import SelectPaisesBiovert from '../../../components/select-paises-biovert';
import SelectIconosBiovert from '../../../components/select-iconos-biovert';
import SelectEnvasesBiovert from '../../../components/select-envases-biovert';
import LineasDeComposicionBiovert from '../../../components/lineas-de-composicion-biovert';
import SelectCultivosBiovert from '../../../components/select-cultivos-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';

// Editor WYSIWYG
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class ProductosEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            tipoDeProducto: this.props.match.params.tipo,
            producto: null,
            listaDeFamilias: null,
            listaDeComoSeAplica: null,
            redirect: false,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            // editorDescripcion: null,
            imagenCatalogoAux: null,
            imagenModoDeAccionAux: null,
            lineasDeComposicion: [],
            lineasDeComposicionAEliminar: [],
            loading: false,
        };
    }

    componentDidMount() {
    // Obtenemos la lista de productos y familias
        if (null !== this.state.id && undefined !== this.state.id) {
            if (
                'g' === this.state.tipoDeProducto ||
        'a' === this.state.tipoDeProducto
            ) {
                this.getProducto();
                this.getFamilias();
                this.getComoSeAplica();
            }
        }
    }

    // Get producto de la base de datos
    getProducto() {
        let requestData = {
            endpoint: '',
            method: 'GET',
            authorized: true,
            contentType: 'application/json',
        };

        // Endpoint
        if ('g' === this.state.tipoDeProducto)
            requestData.endpoint =
        APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS + this.state.id + '/';
        else
            requestData.endpoint =
        APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS + this.state.id + '/';

        ApiService.request(requestData)
            .then((response) => {
                let producto = JSON.parse(response);
                // Correcciones
                if (null === producto.nombre)
                    producto.nombre = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };
                if (null === producto.descripcion)
                    producto.descripcion = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.descripcion2)
                    producto.descripcion2 = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.caracteristicas)
                    producto.caracteristicas = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.aplicaciones)
                    producto.aplicaciones = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.observaciones)
                    producto.observaciones = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.modo_de_accion)
                    producto.modo_de_accion = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.seo_title)
                    producto.seo_title = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.seo_description)
                    producto.seo_description = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.seo_imagen_catalogo_alt)
                    producto.seo_imagen_catalogo_alt = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };

                if (null === producto.id_hexadecimal)
                    producto.id_hexadecimal = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };
                if (null === producto.slug)
                    producto.slug = {
                        [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                    };
                this.setState({ producto: producto });
                // Inicializamos el editor de descripcion
                // this.setStateEditorDescripcion(producto, APP_CONFIG.DEFAULT_SELECTED_LOCALE);
            })
            .catch((error) => {
                this.setState({ redirect: true });
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get familias de la base de datos
    getFamilias() {
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json',
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeFamilias: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get como se aplica de la base de datos
    getComoSeAplica() {
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA,
            method: 'GET',
            authorized: true,
            contentType: 'application/json',
        };
        ApiService.request(requestData)
            .then((response) => {
                let comoSeAplica = [];
                let r = JSON.parse(response);

                if ('g' === this.state.tipoDeProducto) {
                    comoSeAplica = r.filter(
                        (item) => Number(item.producto_generico) === Number(this.state.id)
                    );
                } else {
                    comoSeAplica = r.filter(
                        (item) => Number(item.producto_acabado) === Number(this.state.id)
                    );
                }

                this.setState({ listaDeComoSeAplica: comoSeAplica });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

  // Seleccionar idioma
  seleccionarIdioma = (idioma) => {
      this.setState({ idiomaSeleccionado: idioma });
      // this.setStateEditorDescripcion(this.state.producto, idioma);
  };

  handleChangeFamilia = (e) => {
      const s = this.state;
      s.producto.familia = Number(e.nativeEvent.target.value);
      if (0 === s.producto.familia) s.producto.familia = null;

      this.setState(s);
  };

  handleChangeEmpresa = (e) => {
      const s = this.state;
      s.producto.empresa = e.nativeEvent.target.value;
      this.setState(s);
  };

  // Editor
  /*
    onChangeEditorDescripcion = (editorState) => {
        const s = this.state;
        s.editorDescripcion = editorState;
        s.producto.descripcion[this.state.idiomaSeleccionado] = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState(s);
    };

    setStateEditorDescripcion = (producto, idioma) => {
        // Vars
        let html = '';

        // Comprobamos que el idioma existe
        if ((null !== producto.descripcion[idioma]) && (undefined !== producto.descripcion[idioma])) html = producto.descripcion[idioma];

        // Inicializamos el editor de descripcion
        let editorState = null;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        }
        this.setState({ editorDescripcion: editorState });
    }
    */

  // Imagenes
  handleChangeImagenCatalogo = (event) => {
      let file = event.target.files[0];
      if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES) {
          toast.warning(
              <div>
                  <FontAwesome name='times' />
          &nbsp;
                  <span>
            El archivo seleccionado es demasiado grande (límite{' '}
                      {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000}
            MB)
                  </span>
              </div>
          );
      } else {
          this.setState({ imagenCatalogoAux: file });
      }
  };

  handleChangeImagenModoDeAccion = (event) => {
      let file = event.target.files[0];
      if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES) {
          toast.warning(
              <div>
                  <FontAwesome name='times' />
          &nbsp;
                  <span>
            El archivo seleccionado es demasiado grande (límite{' '}
                      {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000}
            MB)
                  </span>
              </div>
          );
      } else {
          this.setState({ imagenModoDeAccionAux: file });
      }
  };

  handleChangeEliminarImagenCatalogo = () => {
      this.setState({ imagenCatalogoAux: 'delete' });
  };

  handleChangeEliminarImagenModoDeAccion = () => {
      this.setState({ imagenModoDeAccionAux: 'delete' });
  };

  // Guardar producto
  guardarProducto = () => {
      // Loading
      this.setState({ loading: true });

      // Llamamos al endpoint
      const requestData = {
          endpoint: '',
          method: 'PUT',
          authorized: true,
          contentType: 'application/json',
          data: this.state.producto,
      };

      // Endpoint
      if ('g' === this.state.tipoDeProducto)
          requestData.endpoint =
        APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS + this.state.producto.id + '/';
      else
          requestData.endpoint =
        APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS + this.state.producto.id + '/';

      ApiService.request(requestData)
          .then((response) => {
              this.guardarImagen1(Number(JSON.parse(response).id));
          })
          .catch((error) => {
              this.setState({ loading: false });
              if (undefined === error.detail)
                  toast.error(
                      <div>
                          <FontAwesome name='times' />
              &nbsp;<span>Se ha producido un error al guardar el producto</span>
                      </div>
                  );
              else
                  toast.error(
                      <div>
                          <FontAwesome name='times' />
              &nbsp;<span>{error.detail}</span>
                      </div>
                  );

              console.log('Error: ' + JSON.stringify(error));
          });
  };

  // Guardar primera imagen
  guardarImagen1 = (id) => {
      // Imagen catalogo
      if ('delete' === this.state.imagenCatalogoAux) {
      // Llamamos al endpoint para borrar imagen
          let requestData = {
              endpoint: '',
              method: 'DELETE',
              authorized: true,
              contentType: 'application/json',
          };

          // Endpoint
          if ('g' === this.state.tipoDeProducto)
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS +
          id +
          '/delete_imagen_catalogo/';
          else
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS +
          id +
          '/delete_imagen_catalogo/';

          ApiService.request(requestData)
              .then(() => {
                  this.guardarImagen2(id);
              })
              .catch((error) => {
                  this.setState({ loading: false });
                  console.log('Error: ' + JSON.stringify(error));
              });
      } else if (null !== this.state.imagenCatalogoAux) {
      // Upload imagen
          const formData = new FormData();
          formData.append('imagen_catalogo', this.state.imagenCatalogoAux);

          let requestData = {
              endpoint: '',
              method: 'POST',
              authorized: true,
              contentType: '',
              data: formData,
          };

          // Endpoint
          if ('g' === this.state.tipoDeProducto)
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS +
          id +
          '/upload_imagen_catalogo/';
          else
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS +
          id +
          '/upload_imagen_catalogo/';

          ApiService.request(requestData)
              .then(() => {
                  this.guardarImagen2(id);
              })
              .catch((error) => {
                  this.setState({ loading: false });
                  toast.error(
                      <div>
                          <FontAwesome name='times' />
              &nbsp;<span>{error.detail}</span>
                      </div>
                  );
                  console.log('Error: ' + JSON.stringify(error));
              });
      } else this.guardarImagen2(id);
  };

  // Guardar segunda imagen
  guardarImagen2 = (id) => {
      // Imagen modo de accion
      if ('delete' === this.state.imagenModoDeAccionAux) {
      // Llamamos al endpoint para borrar imagen
          let requestData = {
              endpoint: '',
              method: 'DELETE',
              authorized: true,
              contentType: 'application/json',
          };

          // Endpoint
          if ('g' === this.state.tipoDeProducto)
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS +
          id +
          '/delete_imagen_modo_de_accion/';
          else
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS +
          id +
          '/delete_imagen_modo_de_accion/';

          ApiService.request(requestData)
              .then(() => {
                  this.guardarLineasDeComposicion(id);
              })
              .catch((error) => {
                  this.setState({ loading: false });
                  toast.error(
                      <div>
                          <FontAwesome name='times' />
              &nbsp;<span>{error.detail}</span>
                      </div>
                  );
                  console.log('Error: ' + JSON.stringify(error));
              });
      } else if (null !== this.state.imagenModoDeAccionAux) {
      // Upload imagen
          const formData = new FormData();
          formData.append(
              'imagen_modo_de_accion',
              this.state.imagenModoDeAccionAux
          );

          let requestData = {
              endpoint:
          APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS +
          id +
          '/upload_imagen_modo_de_accion/',
              method: 'POST',
              authorized: true,
              contentType: '',
              data: formData,
          };

          // Endpoint
          if ('g' === this.state.tipoDeProducto)
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS +
          id +
          '/upload_imagen_modo_de_accion/';
          else
              requestData.endpoint =
          APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS +
          id +
          '/upload_imagen_modo_de_accion/';

          ApiService.request(requestData)
              .then(() => {
                  this.guardarLineasDeComposicion(id);
              })
              .catch((error) => {
                  this.setState({ loading: false });
                  toast.error(
                      <div>
                          <FontAwesome name='times' />
              &nbsp;<span>{error.detail}</span>
                      </div>
                  );
                  console.log('Error: ' + JSON.stringify(error));
              });
      } else this.guardarLineasDeComposicion(id);
  };

  // Guardar lineas de composicion
  guardarLineasDeComposicion = (id) => {
      // Guardamos las lineas de composición nuevas
      this.state.lineasDeComposicion.forEach((item) => {
          const requestData = {
              endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
              method: 'POST',
              authorized: true,
              contentType: 'application/json',
              data: {
                  componente: item.componente,
                  cantidad_1: item.cantidad_1,
                  magnitud_1: item.magnitud_1,
                  cantidad_2: item.cantidad_2,
                  magnitud_2: item.magnitud_2,
                  solubilidad: item.solubilidad,
                  valor: item.valor,
                  orden: item.orden,
                  producto_generico: null,
                  producto_acabado: null,
              },
          };

          // Producto acabado
          if ('g' === this.state.tipoDeProducto)
              requestData.data.producto_generico = id;
          else requestData.data.producto_acabado = id;

          ApiService.request(requestData)
              .then(() => {})
              .catch((error) => {
                  this.setState({ loading: false });
                  console.log('Error: ' + JSON.stringify(error));
              });
      });

      // Eliminamos las lineas antiguas
      this.state.lineasDeComposicionAEliminar.forEach((item) => {
          const requestData = {
              endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION + item + '/',
              method: 'DELETE',
              authorized: true,
              contentType: 'application/json',
          };
          ApiService.request(requestData)
              .then(() => {})
              .catch((error) => {
                  this.setState({ loading: false });
                  console.log('Error: ' + JSON.stringify(error));
              });
      });

      this.guardarComoSeAplica(id);
  };

  // Guardar como se aplica
  guardarComoSeAplica = (id) => {
      // Creamos los nuevos como se aplica de los cultivos seleccionados
      // -- Por cada estado fenologico seleccionado
      this.state.producto.estados_fenologicos.forEach((ef) => {
          if (
              this.state.listaDeComoSeAplica.filter(
                  (item) => Number(item.estado_fenologico) === Number(ef)
              ).length <= 0
          ) {
              // Si no existe el como se aplica lo creamos

              let requestData = {
                  endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA,
                  method: 'POST',
                  authorized: true,
                  contentType: 'application/json',
                  data: {
                      modo_de_aplicacion: 0,
                      momento_de_aplicacion: {
                          [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                      },
                      dosis: {
                          [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                      },
                      objetivo: {
                          [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                      },
                      estado_fenologico: ef,
                      producto_generico: 'g' === this.state.tipoDeProducto ? id : null,
                      producto_acabado: 'a' === this.state.tipoDeProducto ? id : null,
                  },
              };
              ApiService.request(requestData)
                  .then(() => {})
                  .catch((error) => {
                      console.log('Error: ' + JSON.stringify(error));
                  });
          }
      });

      // Eliminamos los como se aplica de cultivos que no esten asociados
      // -- Por cada como se aplica de este producto
      this.state.listaDeComoSeAplica.forEach((comoSeAplica) => {
          if (
              !this.state.producto.estados_fenologicos.includes(
                  comoSeAplica.estado_fenologico
              )
          ) {
              // Si no lo incluye lo eliminamos

              let requestData = {
                  endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA + comoSeAplica.id + '/',
                  method: 'DELETE',
                  authorized: true,
                  contentType: 'application/json',
              };
              ApiService.request(requestData)
                  .then(() => {})
                  .catch((error) => {
                      console.log('Error: ' + JSON.stringify(error));
                  });
          }
      });

      this.resultadoGuardarOk();
  };

  // Resultado de guardar
  resultadoGuardarOk = () => {
      toast.success(
          <div>
              <FontAwesome name='check' />
        &nbsp;<span>Producto guardado con éxito</span>
          </div>
      );
      this.setState({ redirect: true, loading: false });
  };

  // Calcular select familia
  calculaSelectFamilia = () => {
      if (
          Array.isArray(this.state.listaDeFamilias) &&
      this.state.listaDeFamilias.length > 0
      ) {
          return this.state.listaDeFamilias.map((item) => (
              <option key={item.id} value={item.id}>
                  {item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}{' '}
              </option>
          ));
      } else {
          return '';
      }
  };

  // Calcula imagen catalogo a mostrar
  calculaImagenCatalogoMostrar() {
      if ('delete' === this.state.imagenCatalogoAux) return '';

      if (null === this.state.imagenCatalogoAux)
          return this.state.producto.imagen_catalogo;
  }

  // Calcula imagen modo de accion a mostrar
  calculaImagenModoDeAccionMostrar() {
      if ('delete' === this.state.imagenModoDeAccionAux) return '';

      if (null === this.state.imagenModoDeAccionAux)
          return this.state.producto.imagen_modo_de_accion;
  }

  render() {
      // Si el tipo de producto no es generico o acabado
      if (
          !('g' === this.state.tipoDeProducto || 'a' === this.state.tipoDeProducto)
      )
          return <Redirect to={APP_CONFIG.RUTAS.HOME} />;

      // Si no hay producto volvemos al view
      if ('g' === this.state.tipoDeProducto && this.state.redirect)
          return <Redirect to={APP_CONFIG.RUTAS.PRODUCTOS_GENERICOS_VIEW} />;

      if ('a' === this.state.tipoDeProducto && this.state.redirect)
          return <Redirect to={APP_CONFIG.RUTAS.PRODUCTOS_ACABADOS_VIEW} />;

      // Si aun no tenemos los datos
      if (
          null === this.state.producto ||
      null === this.state.listaDeFamilias ||
      null === this.state.listaDeComoSeAplica
      )
          return (
              <PaginaBiovert>
                  <LoadingBiovert />
              </PaginaBiovert>
          );

      // Si estamos cargando
      if (this.state.loading)
          return (
              <PaginaBiovert>
                  <LoadingBiovert />
              </PaginaBiovert>
          );

      // Edit
      return (
          <PaginaBiovert>
              <div className='producto-container'>
                  {/* Breadcrumb + seleccionar idioma */}
                  <div className='producto-breadcrumb-idioma'>
                      {'g' === this.state.tipoDeProducto ? (
                          <BreadCrumbBiovert
                              value={[
                                  'Productos genéricos',
                                  'Editar',
                                  this.state.producto.codigo,
                              ]}
                          />
                      ) : (
                          <BreadCrumbBiovert
                              value={[
                                  'Productos acabados',
                                  'Editar',
                                  this.state.producto.codigo,
                              ]}
                          />
                      )}
                      <SelectIdioma onSelect={this.seleccionarIdioma} />
                  </div>
                  {/* Titulo */}
                  <HeaderBiovert type='t1'>{this.state.producto.codigo}</HeaderBiovert>
                  {/* Select paises */}
                  {'a' === this.state.tipoDeProducto ? (
                      <SelectPaisesBiovert
                          onChange={(paises) => {
                              const s = this.state;
                              s.producto.paises = paises;
                              this.setState(s);
                          }}
                          paises={this.state.producto.paises.sort()}
                      />
                  ) : (
                      ''
                  )}
                  {/* Formulario */}
                  <GreyboxBiovert>
                      <HeaderBiovert type='t1'>Datos generales</HeaderBiovert>
                      <div className='producto-formulario'>
                          {/* Nombre y codigo de articulo */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Nombre
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <input
                                          type='text'
                                          value={
                                              this.state.producto.nombre[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.nombre[this.state.idiomaSeleccionado] =
                          e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      />
                                  </div>
                              </div>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                                      {'g' === this.state.tipoDeProducto
                                          ? 'Código Art.'
                                          : 'Número de registro'}{' '}
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <input
                                          type='text'
                                          value={this.state.producto.codigo || ''}
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.codigo = e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      />
                                  </div>
                              </div>
                          </div>

                          {/* Familia empresa y estado */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Familia
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <select
                                          onChange={this.handleChangeFamilia}
                                          onClick={this.handleChangeFamilia}
                                          defaultValue={this.state.producto.familia || ''}
                                          className='producto-text-input'
                                      >
                                          <option key={'default-familia'} value={''}></option>
                                          {this.calculaSelectFamilia()}{' '}
                                      </select>
                                  </div>
                              </div>

                              {'a' === this.state.tipoDeProducto ? (
                                  <div className='producto-formulario-elemento'>
                                      <div className='producto-formulario-elemento-titulo'>
                      Empresa
                                      </div>
                                      <div className='producto-formulario-elemento-contenido'>
                                          <select
                                              onChange={this.handleChangeEmpresa}
                                              onClick={this.handleChangeEmpresa}
                                              defaultValue={this.state.producto.empresa || ''}
                                              className='producto-text-input'
                                          >
                                              <option value=''></option>
                                              <option value='manvert'>manvert</option>
                                              <option value='kweek'>kweek</option>
                                          </select>
                                      </div>
                                  </div>
                              ) : (
                                  ''
                              )}

                              {'g' === this.state.tipoDeProducto ? (
                                  <div className='producto-formulario-elemento'>
                                      <div className='producto-formulario-elemento-titulo'>
                      Prioridad
                                      </div>
                                      <div className='producto-formulario-elemento-contenido'>
                                          <input
                                              type='number'
                                              value={this.state.producto.prioridad}
                                              onChange={(e) => {
                                                  const s = this.state;
                                                  s.producto.prioridad = e.target.value;
                                                  this.setState(s);
                                              }}
                                              className='producto-text-input'
                                          />
                                      </div>
                                  </div>
                              ) : (
                                  ''
                              )}

                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Estado
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <select
                                          defaultValue={
                                              this.state.producto.activo ? 'true' : 'false'
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.activo =
                          'true' === e.nativeEvent.target.value ? true : false;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      >
                                          <option value='true'>Activo</option>
                                          <option value='false'>Inactivo</option>
                                      </select>
                                  </div>
                              </div>

                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Ecológico
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <select
                                          defaultValue={
                                              this.state.producto.ecologico ? 'true' : 'false'
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.ecologico =
                          'true' === e.nativeEvent.target.value ? true : false;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      >
                                          <option value='true'>Sí</option>
                                          <option value='false'>No</option>
                                      </select>
                                  </div>
                              </div>
                          </div>

                          {/* Descripcion2 */}
                          <div className='producto-formulario-fila'>
                              <div
                                  className={`producto-formulario-elemento ${
                                      'g' === this.state.tipoDeProducto
                                          ? ''
                                          : 'producto-formulario-elemento-titulo-and-two-elements'
                                  }`}
                              >
                                  <div className='producto-formulario-elemento-titulo'>
                    Título
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <input
                                          type='text'
                                          value={
                                              this.state.producto.descripcion2[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.descripcion2[this.state.idiomaSeleccionado] =
                          e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      />
                                  </div>
                              </div>
                              {/* Slug + internacional */}
                <>
                  {'g' === this.state.tipoDeProducto ? null : (
                      <div className='producto-formulario-elemento'>
                          <div className='producto-formulario-elemento-titulo'>
                        Internacional
                          </div>
                          <div className='producto-formulario-elemento-contenido'>
                              <select
                                  defaultValue={
                                      this.state.producto.internacional ? 'true' : 'false'
                                  }
                                  onChange={(e) => {
                                      const s = this.state;
                                      s.producto.internacional =
                              'true' === e.nativeEvent.target.value
                                  ? true
                                  : false;
                                      this.setState(s);
                                  }}
                                  className='producto-text-input'
                              >
                                  <option value='true'>Sí</option>
                                  <option value='false'>No</option>
                              </select>
                          </div>
                      </div>
                  )}

                  {this.state.producto.slug === undefined ? null : (
                      <div className='producto-formulario-elemento'>
                          <div className='producto-formulario-elemento-titulo'>
                        Slug
                          </div>
                          <div className='producto-formulario-elemento-contenido'>
                              <input
                                  type='text'
                                  value={this.state.producto.slug || ''}
                                  readOnly
                                  className='producto-text-input'
                              />
                          </div>
                      </div>
                  )}
                </>
                          </div>

                          {/* Descripcion */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Descripción
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <textarea
                                          type='text'
                                          value={
                                              this.state.producto.descripcion[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.descripcion[this.state.idiomaSeleccionado] =
                          e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-textarea-input'
                                      />{' '}
                                      {/*
                                        <Editor
                                            wrapperClassName='producto-formulario-editor-descripcion-wrapper-class'
                                            editorState={this.state.editorDescripcion}
                                            onEditorStateChange={this.onChangeEditorDescripcion}
                                            toolbar={{
                                                options: [ 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker' ],
                                                inline: { inDropdown: false, options: [ 'bold', 'italic', 'underline', 'strikethrough' ], },
                                                list: { inDropdown: false },
                                                textAlign: { inDropdown: false },
                                            }}
                                        />
                                        */}{' '}
                                  </div>
                              </div>
                          </div>

                          {/* Caracteristicas y aplicaciones */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Características
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <textarea
                                          type='text'
                                          value={
                                              this.state.producto.caracteristicas[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.caracteristicas[
                                                  this.state.idiomaSeleccionado
                                              ] = e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-textarea-input'
                                      />
                                  </div>
                              </div>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Aplicaciones
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <textarea
                                          type='text'
                                          value={
                                              this.state.producto.aplicaciones[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.aplicaciones[this.state.idiomaSeleccionado] =
                          e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-textarea-input'
                                      />
                                  </div>
                              </div>
                          </div>

                          {/* Observaciones y modo de accion */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Observaciones
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <textarea
                                          type='text'
                                          value={
                                              this.state.producto.observaciones[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.observaciones[
                                                  this.state.idiomaSeleccionado
                                              ] = e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-textarea-input'
                                      />
                                  </div>
                              </div>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Modo de acción
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <textarea
                                          type='text'
                                          value={
                                              this.state.producto.modo_de_accion[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.modo_de_accion[
                                                  this.state.idiomaSeleccionado
                                              ] = e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-textarea-input'
                                      />
                                  </div>
                              </div>
                          </div>

                          <div className='producto-formulario-vertical-spacer'></div>
                      </div>
                  </GreyboxBiovert>
                  {/* Select iconos */}
                  <SelectIconosBiovert
                      onChange={(iconos) => {
                          const s = this.state;
                          s.producto.iconos = iconos;
                          this.setState(s);
                      }}
                      iconos={this.state.producto.iconos}
                  />{' '}
                  {/* Lineas de composicion */}
                  <LineasDeComposicionBiovert
                      producto={this.state.producto.id}
                      tipo={
                          'g' === this.state.tipoDeProducto
                              ? 'producto_generico'
                              : 'producto_acabado'
                      }
                      onChange={(l, le) =>
                          this.setState({
                              lineasDeComposicion: l,
                              lineasDeComposicionAEliminar: le,
                          })
                      }
                  />{' '}
                  {/* Select cultivos */}
                  <SelectCultivosBiovert
                      onChange={(ef) => {
                          const s = this.state;
                          s.producto.estados_fenologicos = ef;
                          this.setState(s);
                      }}
                      estados_fenologicos={this.state.producto.estados_fenologicos}
                  />{' '}
                  {/* Select envases */}
                  {'a' === this.state.tipoDeProducto ? (
                      <SelectEnvasesBiovert
                          onChange={(envases) => {
                              const s = this.state;
                              s.producto.envases = envases;
                              this.setState(s);
                          }}
                          envases={this.state.producto.envases}
                      />
                  ) : (
                      ''
                  )}
                  {/* Imagen catalogo */}
                  <SeccionImagenBiovert
                      titulo='Imagen catálogo'
                      urlImagen={this.calculaImagenCatalogoMostrar()}
                      onDelete={this.handleChangeEliminarImagenCatalogo}
                      onUpload={this.handleChangeImagenCatalogo}
                  />{' '}
                  {/* Imagen modo de accion */}
                  <SeccionImagenBiovert
                      titulo='Imagen modo de acción'
                      urlImagen={this.calculaImagenModoDeAccionMostrar()}
                      onDelete={this.handleChangeEliminarImagenModoDeAccion}
                      onUpload={this.handleChangeImagenModoDeAccion}
                  />{' '}
                  {/* Formulario SEO
          seo_title
seo_description
seo_imagen_catalogo_alt

          */}
                  <GreyboxBiovert>
                      <HeaderBiovert type='t1'>SEO</HeaderBiovert>

                      <div className='producto-formulario'>
                          {/* Nombre y codigo de articulo */}
                          <div className='producto-formulario-fila'>
                              <div className='producto-formulario-elemento'>
                                  <div className='producto-formulario-elemento-titulo'>
                    Titulo SEO
                                  </div>
                                  <div className='producto-formulario-elemento-contenido'>
                                      <input
                                          type='text'
                                          value={
                                              this.state.producto.seo_title[
                                                  this.state.idiomaSeleccionado
                                              ] || ''
                                          }
                                          onChange={(e) => {
                                              const s = this.state;
                                              s.producto.seo_title[this.state.idiomaSeleccionado] =
                          e.target.value;
                                              this.setState(s);
                                          }}
                                          className='producto-text-input'
                                      />
                                  </div>
                              </div>
                              {/* Descripcion2 */}
                              <div className='producto-formulario-fila'>
                                  <div className='producto-formulario-elemento'>
                                      <div className='producto-formulario-elemento-titulo'>
                      Texto alternativo
                                      </div>
                                      <div className='producto-formulario-elemento-contenido'>
                                          <input
                                              type='text'
                                              value={
                                                  this.state.producto.seo_imagen_catalogo_alt[
                                                      this.state.idiomaSeleccionado
                                                  ] || ''
                                              }
                                              onChange={(e) => {
                                                  const s = this.state;
                                                  s.producto.seo_imagen_catalogo_alt[
                                                      this.state.idiomaSeleccionado
                                                  ] = e.target.value;
                                                  this.setState(s);
                                              }}
                                              className='producto-text-input'
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='producto-formulario-elemento'>
                              <div className='producto-formulario-elemento-titulo-seo'>
                  Descripción SEO
                              </div>
                              <div className='producto-formulario-elemento-contenido-SEO'>
                                  <textarea
                                      type='text'
                                      value={
                                          this.state.producto.seo_description[
                                              this.state.idiomaSeleccionado
                                          ] || ''
                                      }
                                      onChange={(e) => {
                                          const s = this.state;
                                          s.producto.seo_description[
                                              this.state.idiomaSeleccionado
                                          ] = e.target.value;
                                          this.setState(s);
                                      }}
                                      className='producto-textarea-input-SEO'
                                  />
                              </div>
                          </div>
                      </div>
                      <div className='producto-formulario-fila'>
                          <div className='producto-formulario-elemento'></div>
                      </div>
                  </GreyboxBiovert>
                  <div>
                      {this.state.producto.id_hexadecimal === undefined ? undefined : (
              <>
                <div>
                    <GreyboxBiovert>
                        <HeaderBiovert type='t1'>
                      Enlace redes sociales:{' '}
                        </HeaderBiovert>
                        <div className='producto-formulario-elemento-titulo'></div>
                        <div className='producto-formulario-elemento-contenido'>
                            <a
                                style={{ color: 'black', margin: '20px' }}
                                rel='noopener noreferrer'
                                target='_blank'
                                href={
                                    `https://manvert.com/prod/${this.state.producto.id_hexadecimal}` ||
                          ''
                                }
                                className='producto-text-input'
                            >
                                {' '}
                                {`https://manvert.com/prod/${this.state.producto.id_hexadecimal}` ||
                          ''}
                            </a>
                        </div>
                    </GreyboxBiovert>
                </div>
              </>
                      )}
                  </div>
                  {/* Aceptar cancelar */}
                  <SeccionAceptarCancelarBiovert
                      onCancel={() => {
                          this.setState({ redirect: true });
                      }}
                      onAccept={this.guardarProducto}
                  />
              </div>
          </PaginaBiovert>
      );
  }
}

export default ProductosEditPage;
