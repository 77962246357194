import React, { Component } from 'react';
import './index.css';

class ButtonLogin extends Component {

    render() {
        return (
            <button type='button' className={ (this.props.isEnabled) ? 'cmpt-button-login' : 'cmpt-button-login-disabled' } onClick={this.props.handleClick}>
                {this.props.label}
            </button>
        );
    }

}

export default ButtonLogin;
