import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/globales.js';
import { MaxValue,MaxValueLenght } from '../../utils/MaxValueLineaDeComposicion.js';

import './index.css';

// Componentes
import HeaderBiovert from '../header-biovert';
import ButtonBiovert from '../button-biovert';
import SelectIdioma from '../select-idioma';


class DialogoAsociarCultivo extends Component {

    constructor (props) {
        super(props);
        this.state = {
            componenteSeleccionado: '',
            solubilidadSeleccionada: -1,
            cantidad1: -1,
            magnitud1Seleccionada: '',
            cantidad2: -1,
            magnitud2Seleccionada: '',
            valor: { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: undefined, },
            tipoDeLineaComponente: 'default',
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Cambios de estado
    // -- Componente
    getValueComponente = () => {
        // Si no estamos editando
        if (null === this.props.lineaAEditar) return this.state.componenteSeleccionado;
        // Si estamos editando y no se ha seleccionado el campo
        if ('' === this.state.componenteSeleccionado){
            return (this.props.lineaAEditar.componente || '');
        }
        return this.state.componenteSeleccionado;
    }

    // -- Solubilidad
    getValueSolubilidad = () => {
        // Si no estamos editando
        if (null === this.props.lineaAEditar) {
            return Math.max(this.state.solubilidadSeleccionada, 0);
        }
        // Si estamos editando y no se ha seleccionado el campo
        if (-1 === this.state.solubilidadSeleccionada){
            return (this.props.lineaAEditar.solubilidad);
        }
        return this.state.solubilidadSeleccionada;
    }

    // -- Cantidad 1
    getValueCantidad1 = () => {
        const { cantidad1 } = this.state;
        const { lineaAEditar } = this.props;
        // Si no estamos editando
        if (null === lineaAEditar){
            if (cantidad1 === -1) return 0;
        }
        // Si estamos editando y no se ha seleccionado el campo
        if (-1 === cantidad1){
            return (lineaAEditar.cantidad_1 || 0.00000);
        }

        if (cantidad1.length >= MaxValueLenght){
            return MaxValue;
        }

        if (cantidad1 > 0){return cantidad1;}

        if (cantidad1 === -1){
            return 0;
        }

        return cantidad1;
    };
    // getValueCantidad1 = () => {
    //     // Si no estamos editando
    //     if (null === this.props.lineaAEditar) return Math.max(this.state.cantidad1, 0);
    //     // Si estamos editando y no se ha seleccionado el campo
    //     if (-1 === this.state.cantidad1){
    //         return (this.props.lineaAEditar.cantidad_1 || 0.00000);
    //     }
    //     return this.state.cantidad1;
    // }

    // -- Magnitud 1
    getValueMagnitud1 = () => {
        // Si no estamos editando
        if (null === this.props.lineaAEditar) return this.state.magnitud1Seleccionada;
        // Si estamos editando y no se ha seleccionado el campo
        if ('' === this.state.magnitud1Seleccionada){
            return (this.props.lineaAEditar.magnitud_1 || '');
        }
        return this.state.magnitud1Seleccionada;
    }

    // // -- Cantidad 2
    // getValueCantidad2 = () => {
    //     // Si no estamos editando
    //     if (null === this.props.lineaAEditar) return Math.max(this.state.cantidad2, 0);
    //     // Si estamos editando y no se ha seleccionado el campo
    //     if (-1 === this.state.cantidad2){
    //         return (this.props.lineaAEditar.cantidad_2 || 0.00000);
    //     }
    //     return this.state.cantidad2;
    // }

    getValueCantidad2 = () => {
        const { cantidad2 } = this.state;
        const { lineaAEditar } = this.props;
        // Si no estamos editando
        if (null === lineaAEditar){
            if (cantidad2 === -1) return 0;
        }
        // Si estamos editando y no se ha seleccionado el campo
        if (-1 === cantidad2){
            return (lineaAEditar.cantidad_2 || 0.00000);
        }

        if (cantidad2.length >= MaxValueLenght){
            return MaxValue;
        }

        if (cantidad2 > 0){return cantidad2;}

        if (cantidad2 === -1){
            return 0;
        }

        return cantidad2;
    };
    // -- Magnitud 2
    getValueMagnitud2 = () => {
        // Si no estamos editando
        if (null === this.props.lineaAEditar) return this.state.magnitud2Seleccionada;
        // Si estamos editando y no se ha seleccionado el campo
        if ('' === this.state.magnitud2Seleccionada){
            return (this.props.lineaAEditar.magnitud_2 || '');
        }
        return this.state.magnitud2Seleccionada;
    }

    // -- Valor
    getValueValor = () => {
        // Si no estamos editando
        if (null === this.props.lineaAEditar) return (this.state.valor[this.state.idiomaSeleccionado] || '');
        // Si estamos editando y no se ha seleccionado el campo
        if (undefined === this.state.valor[this.state.idiomaSeleccionado]){
            return (this.props.lineaAEditar.valor[this.state.idiomaSeleccionado] || '');
        }
        return (this.state.valor[this.state.idiomaSeleccionado] || '');
    }

    // -- Calcula tipo de linea
    calculaTipoDeLinea = () => {
        // true -> componente
        // false -> texto libre

        // Si no estamos editando
        if (null === this.props.lineaAEditar){
            if ('default' === this.state.tipoDeLineaComponente) return true;
            else return this.state.tipoDeLineaComponente;
        }

        // Si estamos editando y ya hemos seleccionado opcion
        if ('default' !== this.state.tipoDeLineaComponente) return this.state.tipoDeLineaComponente;

        // Si estamos editando y no hemos seleccionado opcion
        if (
            ('' === this.props.lineaAEditar.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE])
            || (undefined === this.props.lineaAEditar.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE])
        ) return true;
        else return false;

    }

    // On dialog aceptar
    onDialogAceptar = () => {
        // Recuperamos el id de linia original si hay
        let id = 't' + Math.round(Math.random() * 1000);
        if (null !== this.props.lineaAEditar){
            id = this.props.lineaAEditar.id;
        }

        // Devolvemos la linia de composicion
        let l = {
            id: id,
            orden: 99999,
            componente: this.getValueComponente(),
            solubilidad: this.getValueSolubilidad(),
            cantidad_1: this.getValueCantidad1(),
            magnitud_1: this.getValueMagnitud1(),
            cantidad_2: this.getValueCantidad2(),
            magnitud_2: this.getValueMagnitud2(),
            valor: this.state.valor,
        };
        // Merge valor de edicion si estamos editando
        if (null != this.props.lineaAEditar){
            l.valor = Object.assign({}, this.props.lineaAEditar.valor, this.state.valor);
            if (undefined === this.state.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]){
                l.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] = this.props.lineaAEditar.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
            }
        }

        // Arreglamos si hemos seleccionado combos vacios
        if (0 === l.componente) l.componente = null;
        if (0 === l.magnitud_1) l.magnitud_1 = null;
        if (0 === l.magnitud_2) l.magnitud_2 = null;

        // Pasamos al componente padre
        this.props.onAccept(l);

        // Reiniciamos los valores a por defecto
        this.setState({
            componenteSeleccionado: '',
            solubilidadSeleccionada: -1,
            cantidad1: -1,
            magnitud1Seleccionada: '',
            cantidad2: -1,
            magnitud2Seleccionada: '',
            valor: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: undefined,
            },
            tipoDeLineaComponente: 'default',
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        });

        // Cerramos el dialog
        this.props.onClose();
    }

    // On dialog cancelar
    onDialogCancelar = () => {
        // Reiniciamos los valores a por defecto
        this.setState({
            componenteSeleccionado: '',
            solubilidadSeleccionada: -1,
            cantidad1: -1,
            magnitud1Seleccionada: '',
            cantidad2: -1,
            magnitud2Seleccionada: '',
            valor: { [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: undefined, },
            tipoDeLineaComponente: 'default',
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        });

        // Cerramos el dialog
        this.props.onClose();
    }

    render(){

        // Si dialogo cerrado
        if (!this.props.show) return '';

        // Si no hay componentes o magnitudes
        if (!Array.isArray(this.props.componentes) || !Array.isArray(this.props.magnitudes)) return '';

        return (
            <div className='dialogo-linea-de-composicion-container'>
                <div className='dialogo-linea-de-composicion-modal'>
                    <div className='dialogo-linea-de-composicion-content'>

                        <div className='dialogo-linea-de-composicion-content-titulo'>
                            <HeaderBiovert type='t1'>Línea de composición</HeaderBiovert>
                            <div className='dialogo-linea-de-composicion-content-close' onClick={this.onDialogCancelar}>
                                <span className='dialogo-linea-de-composicion-content-close-icon'>&times;</span>
                            </div>
                        </div>

                        {/* Opcion linea de componente */}
                        <div className={
                            this.calculaTipoDeLinea()
                                ? 'dialogo-linea-de-composicion-content-opcion'
                                : 'dialogo-linea-de-composicion-content-opcion-hidden'
                        }>

                            {/* componente */}
                            <div className='dialogo-linea-de-composicion-content-bloque'>
                                <div className='dialogo-linea-de-composicion-content-label'>Componente:</div>
                                <div className='dialogo-linea-de-composicion-content-contenido'>
                                    <select
                                        id='linea-de-composicion-componente'
                                        value={this.getValueComponente()}
                                        onChange={e => this.setState({ componenteSeleccionado: Number(e.nativeEvent.target.value) })}
                                        onClick={e => this.setState({ componenteSeleccionado: Number(e.nativeEvent.target.value) })}
                                        className='dialogo-linea-de-composicion-content-select-componente'
                                        title='Componente químico'>
                                        <option key={'cmpnt'} value={''}></option>
                                        {this.props.componentes.sort((a,b)=> a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE].localeCompare(b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE])).map(
                                            item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            {/* Solubilidad */}
                            <div className='dialogo-linea-de-composicion-content-bloque'>
                                <div className='dialogo-linea-de-composicion-content-label'>Solubilidad:</div>
                                <div className='dialogo-linea-de-composicion-content-contenido'>
                                    <select
                                        id='linea-de-composicion-solubilidad'
                                        value={this.getValueSolubilidad()}
                                        onChange={e => this.setState({ solubilidadSeleccionada: Number(e.nativeEvent.target.value) })}
                                        onClick={e => this.setState({ solubilidadSeleccionada: Number(e.nativeEvent.target.value) })}
                                        className='dialogo-linea-de-composicion-content-select-componente'
                                        title='Solubilidad'>
                                        <option value='0'>--</option>
                                        <option value='1'>* Soluble en agua y complejado</option>
                                        <option value='2'>** Soluble en agua</option>
                                        <option value='3'>*** Soluble en agua y en citrato amónico neutro</option>
                                        <option value='4'>**** Soluble en agua y quelatado</option>
                                    </select>
                                </div>
                            </div>

                            {/* Cantidades */}
                            <div className='dialogo-linea-de-composicion-content-bloque'>
                                <div className='dialogo-linea-de-composicion-content-label'>Cantidad:</div>
                                <div className='dialogo-linea-de-composicion-content-contenido'>
                                    {/* cantidad1 */}
                                    <input
                                        id='linea-de-composicion-cantidad1'
                                        type='number'
                                        min={0}
                                        max={99999.99999}
                                        step={0.00001}
                                        value={this.getValueCantidad1()}
                                        // onChange={e => this.setState({ cantidad1: Math.min(99999.99999, Number(e.target.value)) })}
                                        onChange={e => this.setState({ cantidad1: e.target.value })}

                                        className='dialogo-linea-de-composicion-content-select-input-text'
                                        title='Cantidad 1'/>

                                    {/* magnitud1 */}
                                    <select
                                        id='linea-de-composicion-magnitud1'
                                        value={this.getValueMagnitud1()}
                                        onChange={e => this.setState({ magnitud1Seleccionada: Number(e.nativeEvent.target.value) })}
                                        onClick={e => this.setState({ magnitud1Seleccionada: Number(e.nativeEvent.target.value) })}
                                        className='dialogo-linea-de-composicion-content-select-magnitud'
                                        title='Magnitud 1'>
                                        <option key={'ldcm1'} value={''}></option>
                                        {this.props.magnitudes.map(
                                            item => <option key={item.id} value={item.id}>{item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                        )}
                                    </select>

                                    {/* cantidad2 */}
                                    <input
                                        id='linea-de-composicion-cantidad2'
                                        type='number'
                                        min={0}
                                        max={99999.99999}
                                        step={0.00001}
                                        value={this.getValueCantidad2()}
                                        //onChange={e => this.setState({ cantidad2: Math.min(99999.99999, Number(e.target.value)) })}
                                        onChange={e => this.setState({ cantidad2: e.target.value })}

                                        className='dialogo-linea-de-composicion-content-select-input-text'
                                        title='Cantidad 2'/>

                                    {/* magnitud2 */}
                                    <select
                                        id='linea-de-composicion-magnitud2'
                                        value={this.getValueMagnitud2()}
                                        onChange={e => this.setState({ magnitud2Seleccionada: Number(e.nativeEvent.target.value) })}
                                        onClick={e => this.setState({ magnitud2Seleccionada: Number(e.nativeEvent.target.value) })}
                                        className='dialogo-linea-de-composicion-content-select-magnitud'
                                        title='Magnitud 2'>
                                        <option key={'ldcm2'} value={''}></option>
                                        {this.props.magnitudes.map(
                                            item => <option key={item.id} value={item.id}>{item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                        </div>

                        {/* Opcion texto libre */}
                        <div className={
                            this.calculaTipoDeLinea()
                                ? 'dialogo-linea-de-composicion-content-opcion-hidden'
                                : 'dialogo-linea-de-composicion-content-opcion'
                        }>

                            {/* Seleccion de idioma */}
                            <div className='dialogo-linea-de-composicion-content-bloque'>
                                <div className='dialogo-linea-de-composicion-content-select-idioma'>
                                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                                </div>
                            </div>

                            {/* Texto libre */}
                            <textarea
                                id='linea-de-composicion-valor'
                                type='text'
                                value={this.getValueValor()}
                                key={'free_text' + this.state.idiomaSeleccionado}
                                onChange={e => {const s = this.state;s.valor[this.state.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                                title='Texto libre'
                                placeholder='Texto libre'
                                className='dialogo-linea-de-composicion-content-textarea'/>

                        </div>


                        <div className='dialogo-linea-de-composicion-buttons'>
                            <div className='dialogo-linea-de-composicion-buttons-opciones'>

                                <div onClick={
                                    () => {
                                        const s = this.state;
                                        s.tipoDeLineaComponente = true;
                                        s.valor = {};
                                        s.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] = '';
                                        this.setState(s);
                                    }}
                                className={
                                    this.calculaTipoDeLinea()
                                        ? 'dialogo-linea-de-composicion-buttons-opcion-componente-active'
                                        : 'dialogo-linea-de-composicion-buttons-opcion-componente'
                                }>Componente</div>

                                <div onClick={() => this.setState({ tipoDeLineaComponente: false })}
                                    className={
                                        this.calculaTipoDeLinea()
                                            ? 'dialogo-linea-de-composicion-buttons-opcion-texto-libre'
                                            : 'dialogo-linea-de-composicion-buttons-opcion-texto-libre-active'
                                    }>Texto libre</div>

                            </div>
                            <ButtonBiovert type='primary' label='Aceptar' handleClick={() => {this.onDialogAceptar();}}/>
                        </div>

                    </div>
                </div>
            </div>
        );

    }

}


export default DialogoAsociarCultivo;
