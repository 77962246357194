import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasComponentesQuimicosPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeComponentesQuimicos: null,
            idComponenteQuimico: null,
            componenteQuimico: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de componentes quimicos
        this.getComponentesQuimicos();
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });
    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idComponenteQuimico: null,
        componenteQuimico: {
            nombre: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
        },
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario
    handleChangeNombre = event => {
        const s = this.state;
        s.componenteQuimico.nombre[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    // Añadir subindice
    addSubindex = () => {
        const textInput = document.getElementById('componentes-quimico-add-edit');
        const s = this.state;
        let oVal = s.componenteQuimico.nombre[this.state.idiomaSeleccionado];
        let nVal = '';
        nVal = oVal.slice(0, textInput.selectionStart) + '<sub>' + oVal.slice(textInput.selectionStart);
        nVal = nVal.slice(0, textInput.selectionEnd + 5) + '</sub>' + nVal.slice(textInput.selectionEnd + 5);
        s.componenteQuimico.nombre[this.state.idiomaSeleccionado] = nVal;
        this.setState(s);
    }

    // Añadir subindice
    addSuperindex = () => {
        const textInput = document.getElementById('componentes-quimico-add-edit');
        const s = this.state;
        let oVal = s.componenteQuimico.nombre[this.state.idiomaSeleccionado];
        let nVal = '';
        nVal = oVal.slice(0, textInput.selectionStart) + '<sup>' + oVal.slice(textInput.selectionStart);
        nVal = nVal.slice(0, textInput.selectionEnd + 5) + '</sup>' + nVal.slice(textInput.selectionEnd + 5);
        s.componenteQuimico.nombre[this.state.idiomaSeleccionado] = nVal;
        this.setState(s);
    }

    // Añadir caracter subindice
    addUnicode = char => {
        const textInput = document.getElementById('componentes-quimico-add-edit');
        const s = this.state;
        let value = s.componenteQuimico.nombre[this.state.idiomaSeleccionado];
        value = value.slice(0, textInput.selectionStart) + char + value.slice(textInput.selectionStart);
        s.componenteQuimico.nombre[this.state.idiomaSeleccionado] = value;
        this.setState(s);
    }

    // Guardar formulario
    guardarComponenteQuimico = () => {
        // Comprobamos campo no vacio
        if (this.state.componenteQuimico.nombre[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.componenteQuimico.nombre[this.state.idiomaSeleccionado].length) return true;

        // Llamamos al endpoint
        let requestData = null;
        if (null === this.state.idComponenteQuimico){
            // Si es nuevo
            requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.COMPONENTES_QUIMICOS,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: this.state.componenteQuimico
            };
        } else {
            requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.COMPONENTES_QUIMICOS + this.state.idComponenteQuimico + '/',
                method: 'PUT',
                authorized: true,
                contentType: 'application/json',
                data: this.state.componenteQuimico
            };
        }
        ApiService.request(requestData)
            .then(() => {
                this.getComponentesQuimicos();
                toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Componente guardado con éxito</span></div>);
                this.setState({
                    idComponenteQuimico: null,
                    componenteQuimico: {
                        nombre: {
                            [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                        }
                    },
                    accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
                });
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el componente químico</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get componentes de la base de datos
    getComponentesQuimicos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMPONENTES_QUIMICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeComponentesQuimicos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Editar componente quimico
    editarComponenteQuimicoCallback = (event) => {return this.editarComponenteQuimico(Number(event.target.id));}

    editarComponenteQuimico = (id) => {
        // Cargamos los datos del componente quimico
        let auxComponenteQuimico = null;
        for (let i = 0; i < this.state.listaDeComponentesQuimicos.length; ++i){
            if (this.state.listaDeComponentesQuimicos[i].id === id){
                auxComponenteQuimico = JSON.parse(JSON.stringify(this.state.listaDeComponentesQuimicos[i]));
                break;
            }
        }
        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idComponenteQuimico: id,
            componenteQuimico: auxComponenteQuimico
        });
    }

    // Eliminar componente quimico
    eliminarComponenteQuimicoCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let nombre = null;
        for (let i = 0; i < this.state.listaDeComponentesQuimicos.length; ++i){
            if (this.state.listaDeComponentesQuimicos[i].id === Number(id)){
                nombre = this.state.listaDeComponentesQuimicos[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === nombre) return true;
        if (window.confirm('¿Desea eliminar el componente químico ' + nombre + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.COMPONENTES_QUIMICOS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Componente eliminado con éxito</span></div>);
                    this.getComponentesQuimicos();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el componente químico</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeComponentesQuimicos == null) return [];
        // Contenido
        return this.state.listaDeComponentesQuimicos.map(item =>
            ({
                id: item.id,
                fields: [ item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] ],
                acciones: [
                    { label: 'Editar', callback: this.editarComponenteQuimicoCallback },
                    { label: 'Eliminar', callback: this.eliminarComponenteQuimicoCallback }
                ]
            })
        );
    }

    renderListaDeElementos(){
        return ([
            <div key='componentes_quimicos_lista' className='componente-quimico-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Componentes químicos' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Componentes químicos</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir componente' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Nombre' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarComponenteQuimico} noShorten={true}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.componenteQuimico) return (<div key='eccq'> Error cargando el componente químico</div>);

        // Values
        return ([
            <div key='componentes_quimicos_add_edit' className='componente-quimico-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='componente-quimico-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Componentes químicos', 'Nuevo componente químico' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Componentes químicos', 'Editar componente químico' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nuevo componente químico</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.componenteQuimico.nombre[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='componente-quimico-form-lbl-nombre'>
                        Nombre componente químico
                    </div>
                    <div className='componente-quimico-formulario'>
                        <div className='componente-quimico-form-nombre'>
                            <input
                                id='componentes-quimico-add-edit'
                                type='text'
                                value={this.state.componenteQuimico.nombre[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeNombre}
                                size='50'
                                className='componentes-quimico-add-edit-text-input'
                            />
                            {/*<ButtonBiovert type='primary' label='Subíndice' handleClick={this.addSubindex}/>*/}
                            {/*<ButtonBiovert type='primary' label='Superíndice' handleClick={this.addSuperindex}/>*/}
                        </div>
                        <div className='componente-quimico-form-botones-indices'>
                            <div className='componente-quimico-form-botones-indices-fila'>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('¹')}>¹</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('²')}>²</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('³')}>³</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁴')}>⁴</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁵')}>⁵</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁶')}>⁶</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁷')}>⁷</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁸')}>⁸</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁹')}>⁹</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁺')}>⁺</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('⁻')}>⁻</div>
                            </div>
                            <div className='componente-quimico-form-botones-indices-fila'>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₁')}>₁</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₂')}>₂</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₃')}>₃</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₄')}>₄</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₅')}>₅</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₆')}>₆</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₇')}>₇</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₈')}>₈</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₉')}>₉</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₊')}>₊</div>
                                <div className='componente-quimico-form-botones-indices-boton' onClick={() => this.addUnicode('₋')}>₋</div>
                            </div>
                        </div>
                    </div>
                </GreyboxBiovert>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarComponenteQuimico}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasComponentesQuimicosPage;
