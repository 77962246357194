import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../../services/Api.js';
import { APP_CONFIG } from '../../../../utils/globales.js';
import '../../index.css';

// Componentes
import PaginaBiovert from '../../../../components/pagina-biovert';
import LoadingBiovert from '../../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../../components/tabla-biovert';
import HeaderBiovert from '../../../../components/header-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';


class ProductosGenericosViewPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeProductos: null,
            listaDeFamilias: null,
            redirectId: null,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de productos y familias
        this.getProductosGenericos();
        this.getFamilias();
    }

    // Get familias de la base de datos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS_LIGHT,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get familias de la base de datos
    getFamilias(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeFamilias: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Editar
    editarProductoCallback = event => {return this.editarProducto(Number(event.target.id));}

    editarProducto = id => {this.setState({ redirectId: id });}

    eliminarProductoCallback = event => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let codigo = null;
        for (let i = 0; i < this.state.listaDeProductos.length; ++i){
            if (this.state.listaDeProductos[i].id === Number(id)){
                codigo = this.state.listaDeProductos[i].codigo;
                break;
            }
        }
        if (null === codigo) return true;
        if (window.confirm('¿Desea eliminar el producto ' + codigo + '? Atención, este cambio es irreversible.')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Producto eliminado con éxito</span></div>);
                    this.getProductosGenericos();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el producto</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    }

    // Calcula familia a mostrar
    calculaFamiliaAMostrar(item){
        if (null === item.familia) return '';
        if (null === this.state.listaDeFamilias) return '';
        for (let i = 0; i < this.state.listaDeFamilias.length; ++i){
            if (Number(this.state.listaDeFamilias[i].id) === Number(item.familia)) return (this.state.listaDeFamilias[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
        }
        return '';
    }

    // Calcula estado a mostrar
    calculaEstadoAMostrar(item){
        if (item.activo) return 'Activo';
        else return '--';
    }

    // Calcula ecologico a mostrar
    calculaEcologicoAMostrar(item){
        if (item.ecologico) return 'Ecológico';
        else return '--';
    }

    // Calcula nombre a mostrar
    calculaNombreAMostrar(item){
        if (null === item.nombre) return '';
        else return item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
    }

    // Calcula prioridad a mostrar
    calculaPrioridadAMostrar(item){
        if (null === item.prioridad) return '';
        else return item.prioridad.toString();
    }

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeProductos == null) return [];
        // Contenido
        return this.state.listaDeProductos.map(item =>
            ({
                id: item.id,
                fields: [
                    item.codigo,
                    this.calculaNombreAMostrar(item),
                    this.calculaFamiliaAMostrar(item),
                    this.calculaEstadoAMostrar(item),
                    this.calculaEcologicoAMostrar(item),
                    this.calculaPrioridadAMostrar(item)
                ],
                acciones: [
                    { label: 'Editar', callback: this.editarProductoCallback },
                    { label: 'Eliminar', callback: this.eliminarProductoCallback }
                ]
            })
        );
    }

    render() {

        // Editar
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.PRODUCTOS_EDIT + '/g/' + this.state.redirectId}/>);

        // Si aun no tenemos datos
        if ((null === this.state.listaDeProductos) || (null === this.state.listaDeFamilias)) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Lista
        return (
            <PaginaBiovert>
                <div className='producto-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Productos genéricos' ]}/>

                    {/* Titulo */}
                    <HeaderBiovert type='t1'>Productos genéricos</HeaderBiovert>

                    <br></br>
                    <TablaBiovert cabeceras={[ 'Código art.', 'Nombre', 'Familia', 'Estado', 'Ecológico', 'Prioridad' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarProducto} paginaFiltro={'filtroProductoGenericos'} saveScrollValue={true}/>
                </div>
            </PaginaBiovert>
        );

    }

}

export default ProductosGenericosViewPage;
