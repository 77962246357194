import React, { Component } from 'react';
import './index.css';

import { APP_CONFIG } from '../../utils/globales.js';
import { LOCALES } from '../../utils/locales.js';

class SelectIdioma extends Component {

    onItemSelected = e => {
        this.props.onSelect(e.nativeEvent.target.value);
    }

    calculaOpciones(){
        var availableLocales = [];

        for (var key in LOCALES) {
            if (key === 'ddddd') availableLocales.push(<option key={'dd_DD_dd'} disabled>────────────────</option>);
            else availableLocales.push(<option key={key} value={key}>{LOCALES[key]}</option>);
        }

        return availableLocales;
    }

    render() {

        return (
            <div className='styled-select green semi-square'>
                <select id='lang_select' onChange={this.onItemSelected} defaultValue={APP_CONFIG.DEFAULT_SELECTED_LOCALE} title='Seleccione el idioma deseado'>
                    {this.calculaOpciones()}
                </select>
            </div>
        );
    }

}

export default SelectIdioma;
