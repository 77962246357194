import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import ComoSeAplicaBiovert from '../como-se-aplica-biovert';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class EstadoFenologicoBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            estadoFenologico: this.props.estadoFenologico,
            listaDeComoSeAplica: this.props.comoSeAplica,
            listaDeProductosGenericos: this.props.productosGenericos,
            listaDeProductosAcabados: this.props.productosAcabados,
            showContent: true,
        };
    }

    getNombreDeProducto(comoSeAplica){
        if (null !== comoSeAplica.producto_generico){
            const productoGenerico = this.state.listaDeProductosGenericos.filter(item => Number(item.id) === Number(comoSeAplica.producto_generico))[0];
            if ((null !== productoGenerico) && (undefined !== productoGenerico)) return productoGenerico.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] + ' - ' + productoGenerico.codigo;
        }
        if (null !== comoSeAplica.producto_acabado){
            const productoAcabado = this.state.listaDeProductosAcabados.filter(item => Number(item.id) === Number(comoSeAplica.producto_acabado))[0];
            if ((null !== productoAcabado) && (undefined !== productoAcabado)) return productoAcabado.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] + ' - ' + productoAcabado.codigo;
        }
        return '';
    }

    renderComoSeAplica(){

        return this.state.listaDeComoSeAplica
            .filter(item => Number(item.estado_fenologico) === Number(this.state.estadoFenologico.id))
            .map(item =>
                <div key={'efb-' + item.id} className='estado-fenealogico-biovert-container'>
                    <ComoSeAplicaBiovert
                        nombreProducto={this.getNombreDeProducto(item)}
                        comoSeAplica={item}
                        idiomaSeleccionado={this.props.idiomaSeleccionado}
                    />
                </div>
            );

    }

    render() {
        return (
            <GreyboxBiovert>
                <div key={this.state.estadoFenologico.id} className='estado-fenealogico-biovert-container'>

                    {/* Cabecera */}
                    <div className={true === this.state.showContent ? 'estado-fenealogico-biovert-cabecera' : 'estado-fenealogico-biovert-cabecera-hide'}>
                        <div className='estado-fenealogico-biovert-foto-titulo'>
                            <div>
                                {null !== this.state.estadoFenologico.imagen
                                    ? <img src={this.state.estadoFenologico.imagen} alt='' className='estado-fenealogico-biovert-titulo-imagen'/>
                                    : ''
                                }
                            </div>
                            <div className='estado-fenealogico-biovert-titulo-arrow'
                                onClick={() => this.props.onArrowClick(this.state.estadoFenologico.id, 'up')}>
                                <FontAwesome name='arrow-up'/>
                            </div>
                            <div className='estado-fenealogico-biovert-titulo-arrow'
                                onClick={() => this.props.onArrowClick(this.state.estadoFenologico.id, 'down')}>
                                <FontAwesome name='arrow-down'/>
                            </div>
                            <div className='estado-fenealogico-biovert-titulo-texto'>{this.state.estadoFenologico.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</div>
                        </div>
                        <div className='estado-fenealogico-biovert-colapse' onClick={() => this.setState({ showContent: !this.state.showContent })}>
                            {true === this.state.showContent
                                ? <FontAwesome name='chevron-up'/>
                                : <FontAwesome name='chevron-down'/>
                            }
                        </div>
                    </div>

                    {/* Contenido */}
                    {true === this.state.showContent
                        ? <div className='estado-fenealogico-biovert-content'>
                            <div className='estado-fenealogico-biovert-vertical-spacer'></div>
                            {this.renderComoSeAplica()}
                            <div className='estado-fenealogico-biovert-vertical-spacer'></div>
                        </div>
                        : ''
                    }

                </div>
            </GreyboxBiovert>
        );
    }

}

export default EstadoFenologicoBiovert;
