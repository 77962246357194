import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import ButtonBiovert from '../button-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class ComoSeAplicaBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            nombreProducto: this.props.nombreProducto,
            comoSeAplica: this.props.comoSeAplica,
            comoSeAplicaOrigen: JSON.parse(JSON.stringify(this.props.comoSeAplica)),
        };
    }

    onGuardar = () => {

        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA + this.state.comoSeAplica.id + '/',
            method: 'PUT',
            authorized: true,
            contentType: 'application/json',
            data: this.state.comoSeAplica
        };
        ApiService.request(requestData)
            .then(() => {
                this.setState({ comoSeAplicaOrigen: JSON.parse(JSON.stringify(this.state.comoSeAplica)) });
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar los cambios</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });

    }

    render() {
        return (
            <div key={'csa-' + this.state.comoSeAplica.id} className='como-se-aplica-biovert-container'>

                <div className='como-se-aplica-biovert-content'>
                    {/* Titulo */}
                    <div className='como-se-aplica-biovert-bloque-titulo'>
                        <div className='como-se-aplica-biovert-titulo'>{this.state.nombreProducto}</div>
                        {null !== this.state.comoSeAplica.producto_generico
                            ? <div className='como-se-aplica-biovert-subtitulo'>(Producto genérico)</div>
                            : <div className='como-se-aplica-biovert-subtitulo'>(Producto acabado)</div>}
                    </div>

                    {/* Dosis + mode de aplicacion */}
                    <div className='como-se-aplica-biovert-bloque-dosis'>
                        <input id='csa-dosis' type='text'
                            placeholder='Dosis'
                            value={this.state.comoSeAplica.dosis[this.props.idiomaSeleccionado] || ''}
                            onChange={e => {const s = this.state;s.comoSeAplica.dosis[this.props.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                            className='como-se-aplica-biovert-text-input'
                            title='Dosis'
                        />

                        <select
                            id='csa-modo-de-aplicacion'
                            value={this.state.comoSeAplica.modo_de_aplicacion}
                            onChange={e => {const s = this.state; s.comoSeAplica.modo_de_aplicacion = parseInt(e.nativeEvent.target.value);this.setState(s);}}
                            onClick={e => {const s = this.state; s.comoSeAplica.modo_de_aplicacion = parseInt(e.nativeEvent.target.value);this.setState(s);}}
                            className='como-se-aplica-biovert-text-select'
                            title='Modo de aplicación'>
                            <option value={'0'}>Aplicación foliar</option>
                            <option value={'1'}>Aplicación al riego</option>
                            <option value={'2'}>Aplicación foliar y al riego</option>
                        </select>
                    </div>

                    {/* Objetivo + momento de aplicacion */}
                    <div className='como-se-aplica-biovert-bloque-objetivo'>
                        <textarea id='csa-objetivo' type='text'
                            placeholder='Objetivo'
                            value={this.state.comoSeAplica.objetivo[this.props.idiomaSeleccionado] || ''}
                            onChange={e => {const s = this.state;s.comoSeAplica.objetivo[this.props.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                            className='como-se-aplica-biovert-text-area'
                            title='Objetivo'
                        />

                        <textarea id='csa-momento-de-aplicacion' type='text'
                            placeholder='Momento de aplicación'
                            value={this.state.comoSeAplica.momento_de_aplicacion[this.props.idiomaSeleccionado] || ''}
                            onChange={e => {const s = this.state;s.comoSeAplica.momento_de_aplicacion[this.props.idiomaSeleccionado] = e.target.value;this.setState(s);}}
                            className='como-se-aplica-biovert-text-area'
                            title='Momento de aplicación'
                        />
                    </div>

                    {/* Guardar solo si hay cambios */}
                    {(JSON.stringify(this.state.comoSeAplica) !== JSON.stringify(this.state.comoSeAplicaOrigen))
                        ? <div className='como-se-aplica-biovert-boton-guardar'>
                            <ButtonBiovert type='primary' label='Guardar cambios' handleClick={this.onGuardar}/>
                        </div>
                        : ''
                    }

                    <div className='como-se-aplica-biovert-vertical-spacer'></div>

                </div>




            </div>

        );
    }

}

export default ComoSeAplicaBiovert;
