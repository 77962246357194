import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import HeaderBiovert from '../../../components/header-biovert';

class CultivosViewPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeCultivos: null,
            listaDeGruposDeCultivo: null,
            redirectId: null,
        };
    }

    componentDidMount(){
        //Obtenemos la lista de cultivos
        this.getCultivos();
        this.getGruposDeCultivo();
    }

    // Get cultivos de la base de datos
    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeCultivos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get grupo de cultivo de la base de datos
    getGruposDeCultivo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.GRUPOS_DE_CULTIVO,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeGruposDeCultivo: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    calculaGrupoDeCultivoAMostrar(item){
        if (null === item.grupo_de_cultivo) return '';
        for (let i = 0; i < this.state.listaDeGruposDeCultivo.length; ++i){
            if (Number(this.state.listaDeGruposDeCultivo[i].id) === Number(item.grupo_de_cultivo)) return (this.state.listaDeGruposDeCultivo[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
        }
        return '';
    }

    calculaContenidoParaTabla(){
        // Vars
        let contenido = [];

        // Si no hay datos
        if (this.state.listaDeCultivos == null) return [];
        if (this.state.listaDeGruposDeCultivo == null) return [];

        // Contenido
        for (let i = 0; i < this.state.listaDeCultivos.length; ++i){
            let item = this.state.listaDeCultivos[i];
            contenido.push(
                {
                    id: item.id,
                    fields: [ item.imagen, item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE], this.calculaGrupoDeCultivoAMostrar(item) ],
                    acciones: []
                }
            );
        }
        return contenido;
    }

    calculaImagenMostrar(){
        if ('delete' === this.state.imagenAux) return '';
        if (null === this.state.imagenAux) return this.state.cultivo.imagen;
    }

    render() {

        // Editar
        if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.CULTIVOS_DETAIL + '/' + this.state.redirectId}/>);

        // Si aun no tenemos datos
        if ((null === this.state.listaDeCultivos) || (null === this.state.listaDeGruposDeCultivo)) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        return (
            <PaginaBiovert>
                <div key='elemento_lista' className='cultivo-container'>
                    {/* Breadcrumb */}
                    <BreadCrumbBiovert value = {[ 'Cultivos' ]}/>

                    {/* Titulo + boton add */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                        <HeaderBiovert type='t1'>Cultivos</HeaderBiovert>
                    </div>

                    <br></br>
                    <TablaBiovert
                        cabeceras={[ 'Imagen', 'Nombre', 'Grupo de cultivo' ]}
                        contenido={this.calculaContenidoParaTabla()}
                        onRowClick={id => this.setState({ redirectId: id })}/>
                </div>
            </PaginaBiovert>
        );

    }

}

export default CultivosViewPage;
