import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasMagnitudesPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeMagnitudes: null,
            idMagnitud: null,
            magnitud: {
                valor: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de magnitudes
        this.getMagnitudes();
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });
    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idMagnitud: null,
        magnitud: {
            valor: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            }
        },
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario
    handleChangeValor = event => {
        const s = this.state;
        s.magnitud.valor[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    // Guardar formulario
    guardarMagnitud = () => {
        // Comprobamos campo no vacio
        if (this.state.magnitud.valor[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.magnitud.valor[this.state.idiomaSeleccionado].length) return true;

        // Llamamos al endpoint
        let requestData = null;
        if (null === this.state.idMagnitud){
            // Si es nuevo
            requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES,
                method: 'POST',
                authorized: true,
                contentType: 'application/json',
                data: this.state.magnitud
            };
        } else {
            requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES + this.state.idMagnitud + '/',
                method: 'PUT',
                authorized: true,
                contentType: 'application/json',
                data: this.state.magnitud
            };
        }
        ApiService.request(requestData)
            .then(() => {
                this.getMagnitudes();
                toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Magnitud guardada con éxito</span></div>);
                this.setState({
                    idMagnitud: null,
                    magnitud: {
                        valor: {
                            [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                        },
                    },
                    accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
                });
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar la magnitud</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get magnitudes de la base de datos
    getMagnitudes(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeMagnitudes: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Editar magnitud
    editarMagnitudCallback = (event) => {this.editarMagnitud(Number(event.target.id));}

    editarMagnitud = (id) => {
        // Cargamos los datos de la magnitud
        let auxMagnitud = null;
        for (let i = 0; i < this.state.listaDeMagnitudes.length; ++i){
            if (this.state.listaDeMagnitudes[i].id === id){
                auxMagnitud = JSON.parse(JSON.stringify(this.state.listaDeMagnitudes[i]));
                break;
            }
        }

        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idMagnitud: id,
            magnitud: auxMagnitud
        });
    }

    // Eliminar magnitud
    eliminarMagnitudCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let valor = null;
        for (let i = 0; i < this.state.listaDeMagnitudes.length; ++i){
            if (this.state.listaDeMagnitudes[i].id === Number(id)){
                valor = this.state.listaDeMagnitudes[i].valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === valor) return true;
        if (window.confirm('¿Desea eliminar la magnitud ' + valor + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Magnitud eliminada con éxito</span></div>);
                    this.getMagnitudes();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar la magnitud</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeMagnitudes == null) return [];
        // Contenido
        return this.state.listaDeMagnitudes.map(item =>
            ({
                id: item.id,
                fields: [ item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE] ],
                acciones: [
                    { label: 'Editar', callback: this.editarMagnitudCallback },
                    { label: 'Eliminar', callback: this.eliminarMagnitudCallback }
                ]
            })
        );
    }

    renderListaDeElementos(){
        return ([
            <div key='elemento_lista' className='magnitud-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Magnitudes' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Magnitudes</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir magnitud' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Valor' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarMagnitud}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.icono) return (<div key='eclm'> Error cargando la magnitud</div>);

        // Values
        return ([
            <div key='magnitud_add_edit' className='magnitud-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='magnitud-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Magnitudes', 'Nueva magnitud' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Magnitudes', 'Editar magnitud' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nueva magnitud</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.magnitud.valor[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='magnitud-formulario'>
                        <div className='magnitud-form-lbl-valor'>
                            Valor magnitud
                        </div>
                        <div className='magnitud-form-valor'>
                            <input
                                id='magnitud-add-edit'
                                type='text'
                                value={this.state.magnitud.valor[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeValor}
                                size='50'
                                className='magnitud-add-edit-text-input'
                            />
                        </div>
                    </div>
                </GreyboxBiovert>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarMagnitud}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasMagnitudesPage;
