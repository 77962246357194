import React, { Component } from 'react';
import ApiService from '../../services/Api.js';
import { APP_CONFIG } from '../../utils/globales.js';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import ButtonBiovert from '../button-biovert';
import SelectMultiplePastilla from '../select-multiple-pastilla';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class CombinacionDeProductosBiovert extends Component {

    constructor (props) {
        super(props);
        this.state = {
            combinacionDeProductos: this.props.combinacionDeProductos,
            combinacionDeProductosOrigin: null,
            listaDeProductosGenericos: this.props.productosGenericos,
            listaDeCultivos: this.props.cultivos,
            productoSeleccionado: 0,
            showDropDownProducto: false,
        };
    }

    componentDidMount(){
        // Copiamos la combinación de productos para detectar cambios
        this.setState({ combinacionDeProductosOrigin: JSON.parse(JSON.stringify(this.props.combinacionDeProductos)) });
    }

    hayCambiosCombinacionDeProductos(){
        // Devuelve cierto si se han producido cambios en la combinación de productos
        if (JSON.stringify(this.state.combinacionDeProductos) !== JSON.stringify(this.state.combinacionDeProductosOrigin)) return true;
        else return false;
    }

    handleChangeProductos = productos => {
        let combinacionDeProductos = this.state.combinacionDeProductos;
        combinacionDeProductos.productos_genericos = productos;
        this.setState({ combinacionDeProductos });
    }

    handleChangeCultivos = cultivos => {
        let combinacionDeProductos = this.state.combinacionDeProductos;
        combinacionDeProductos.cultivos = cultivos;
        this.setState({ combinacionDeProductos });
    }

    handleOnChangeObservaciones = e => {
        const s = this.state;
        s.combinacionDeProductos.observaciones[this.props.idioma] = e.target.value;
        this.setState(s);
    }

    guardarCambios = () => {
        const idDeProducto = Number(this.state.productoSeleccionado);
        let listaDeProductos = [].concat(this.state.combinacionDeProductos.productos_genericos);

        // Si hay producto
        if (0 !== idDeProducto){
            // Si el producto es nuevo
            if (!listaDeProductos.includes(idDeProducto)){
                listaDeProductos.push(idDeProducto);
            }
        }

        // Si no hay productos y las observaciones estan vacias borramos.
        if ((listaDeProductos.length <= 0) && this.state.combinacionDeProductos.observaciones[APP_CONFIG.DEFAULT_SELECTED_LOCALE] === ''){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.COMBINACION_DE_PRODUCTOS + this.state.combinacionDeProductos.id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json',
            };
            ApiService.request(requestData)
                .then(() => {
                    this.props.onChange();
                    this.setState({
                        productoSeleccionado: 0,
                        showDropDownProducto: false,
                        combinacionDeProductos: null,
                        combinacionDeProductosOrigin: null,
                    });
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar los datos</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
            return true;
        }

        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMBINACION_DE_PRODUCTOS + this.state.combinacionDeProductos.id + '/',
            method: 'PUT',
            authorized: true,
            contentType: 'application/json',
            data: {
                solucion: this.state.combinacionDeProductos.solucion,
                productos_genericos: listaDeProductos,
                observaciones: this.state.combinacionDeProductos.observaciones,
                cultivos: this.state.combinacionDeProductos.cultivos
            },
        };
        ApiService.request(requestData)
            .then(() => {
                let combinacionDeProductosNueva = JSON.parse(JSON.stringify(this.state.combinacionDeProductos));
                combinacionDeProductosNueva.productos_genericos = listaDeProductos;

                this.props.onChange();
                this.setState({
                    productoSeleccionado: 0,
                    showDropDownProducto: false,
                    combinacionDeProductos: JSON.parse(JSON.stringify(combinacionDeProductosNueva)),
                    combinacionDeProductosOrigin: JSON.parse(JSON.stringify(combinacionDeProductosNueva)),
                });
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar los datos</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });

    }

    render() {

        // Si no tenemos datos
        if ((null === this.state.combinacionDeProductos)
          || (null === this.state.listaDeProductosGenericos)
          || (this.state.listaDeCultivos.length <= 0)
        ) return '';

        return (
            <GreyboxBiovert>
                <div key={this.state.combinacionDeProductos.id} className='combinacion-de-productos-biovert-container'>

                    <div className='combinacion-de-productos-biovert-contenido'>

                        <div className='combinacion-de-productos-biovert-vertical-spacer'></div>

                        {/* Productos */}
                        <div className='combinacion-de-productos-biovert-select-multiple-cultivos'>
                            <SelectMultiplePastilla
                                titulo='Productos'
                                tituloAsociar='Asociar producto'
                                tituloSeleccionar='Seleccione un producto'
                                elementosSeleccionados={this.state.combinacionDeProductos.productos_genericos}
                                listaDeElementos={this.state.listaDeProductosGenericos.map(producto => ({
                                    id: producto.id,
                                    value: producto.nombre ? producto.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] : '',
                                    description: producto.codigo
                                }))}
                                onChange={this.handleChangeProductos}
                            />
                        </div>

                        <div className='combinacion-de-productos-biovert-vertical-spacer'></div>

                        {/* Cultivos */}
                        <div className='combinacion-de-productos-biovert-select-multiple-cultivos'>
                            <SelectMultiplePastilla
                                titulo='Cultivos'
                                tituloAsociar='Asociar cultivo'
                                tituloSeleccionar='Seleccione un cultivo'
                                asociarTodos={true}
                                elementosSeleccionados={this.state.combinacionDeProductos.cultivos}
                                listaDeElementos={this.state.listaDeCultivos.map(cultivo => ({
                                    id: cultivo.id,
                                    value: cultivo.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]
                                }))}
                                onChange={this.handleChangeCultivos}
                            />
                        </div>

                        <div className='combinacion-de-productos-biovert-vertical-spacer'></div>

                        {/* Observaciones */}
                        <textarea
                            type='text'
                            value={this.state.combinacionDeProductos.observaciones[this.props.idioma] || ''}
                            onChange={this.handleOnChangeObservaciones}
                            className='combinacion-de-productos-biovert-textarea-input'
                            placeholder='Observaciones'
                        />

                        {this.hayCambiosCombinacionDeProductos() || this.state.showDropDownProducto
                            ? <div className='combinacion-de-productos-biovert-vertical-spacer'></div>
                            : ''}
                        {this.hayCambiosCombinacionDeProductos() || this.state.showDropDownProducto
                            ? <div className='combinacion-de-productos-biovert-button-right'>
                                <ButtonBiovert type='primary' label='Guardar' handleClick={this.guardarCambios}/>
                            </div>
                            : ''}

                    </div>

                </div>
            </GreyboxBiovert>
        );

    }

}

export default CombinacionDeProductosBiovert;
