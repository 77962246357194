import authService from './AuthService';
import { APP_CONFIG } from '../utils/globales.js';

class Api {

    static request({ endpoint, method, authorized, contentType, params, data }) {
        // Calculamos url
        let url = `${APP_CONFIG.API_BASE_URL}${endpoint}`;
        if (params) {
            const query = Api.urlEncode(params);
            url = `${url}?${query}`;
        }

        // Calculamos headers
        const headers = new Headers();
        // - Authorization
        if (authorized) {
            headers.append('Authorization', 'Token ' + authService.getToken());
        }
        // - Content type
        if ('' !== contentType) headers.append('Content-Type', contentType);

        // Parse data
        let parsedData = null;
        if (data !== undefined && data !== null) {
            if ('application/json' === contentType) parsedData = JSON.stringify(data);
            else parsedData = data;
        }

        return new Promise((resolve, reject) => {
            fetch(url, { method: method, body: parsedData, headers: headers })
                .then((response) => {
                    if (response.ok) {
                        return response.text();
                    }
                    else {
                        if (response.status === 401) {
                            authService.reset();
                            if (!window.location.pathname.includes('/login')) {
                                window.location.reload();
                            }
                        }
                        else {
                            console.log('Something went wrong');
                            return response.json().then((error) =>
                                reject(error)
                            );
                        }
                    }
                })
                .then(resolve)
                .catch((error) => {
                    console.log('There has been a problem: ' + error.message);
                    reject(error);
                });
        });
    }

    static urlEncode(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }

}

export default Api;
