import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../../services/Api.js';
import { APP_CONFIG } from '../../../../utils/globales.js';
import '../../index.css';

// Componentes
import PaginaBiovert from '../../../../components/pagina-biovert';
import LoadingBiovert from '../../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../../components/tabla-biovert';
import HeaderBiovert from '../../../../components/header-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class ProductosAcabadosViewPage extends Component {

    constructor (props) {
        super(props);

        this.state = {
            listaDeProductos: null,
            listaDeProductosGenericos: null,
            listaDeFamilias: null,
            redirectId: null, loading: false

        };
    }

    componentDidMount(){

        //Obtenemos la lista de productos y familias
        this.getProductosAcabados();
        this.getFamilias();
        this.getProductosGenericos();

    }

    // Get familias de la base de datos
    getProductosAcabados(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS_LIGHT,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductos: JSON.parse(response) });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get familias de la base de datos
    getFamilias(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeFamilias: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get productos genericos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS_LIGHT,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductosGenericos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Editar
    editarProductoCallback = e => {return this.editarProducto(Number(e.target.id));}

    editarProducto = id => {this.setState({ redirectId: id });}

    eliminarProductoCallback = e => {
        let id = e.target.id;

        // Get información del elemento a eliminar
        let codigo = null;
        for (let i = 0; i < this.state.listaDeProductos.length; ++i){
            if (this.state.listaDeProductos[i].id === Number(id)){
                codigo = this.state.listaDeProductos[i].codigo;
                break;
            }
        }
        if (null === codigo) return true;
        if (window.confirm('¿Desea eliminar el producto ' + codigo + '? Atención, este cambio es irreversible.')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Producto eliminado con éxito</span></div>);
                    this.getProductosAcabados();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el producto</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }

    }

    // Upload Imagen
    async uploadImage(productoOrigen, imageType, productoDuplicado) {
        if (productoOrigen[imageType] !== null) {

            await fetch(productoOrigen[imageType])
                .then(response => response.blob())
                .then(blob => {
                    const parts = productoOrigen[imageType].split('/');
                    const lastPart = parts[parts.length - 1];
                    const file = new File([ blob ], lastPart, { type: 'image/jpeg' });
                    const formData = new FormData();
                    formData.append(imageType, file);
                    let requestDataImagen = {
                        endpoint: `${APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS}${productoDuplicado.id}/upload_${imageType}/`,
                        method: 'POST',
                        authorized: true,
                        contentType: '',
                        data: formData,
                    };

                    ApiService.request(requestDataImagen)
                        .then(() => {})
                        .catch(error => {
                            console.log('Error: ' + JSON.stringify(error));
                            this.setState({ loading: false });
                        });
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                    // handle the error
                    this.setState({ loading: false });
                });
        }
    }

    // Duplicar producto
     duplicarProductoCallback = async e =>{
         this.setState({ loading: true });
         const idProductoOrigen = e.target.id;

         // Obtenemos el producto origen
         const productoOrigen = await this.obtenerProductoOrigen(idProductoOrigen);

         // Post para generar el producto duplicado con la informacion del producto original
         const productoDuplicado = await this.duplicarProducto(productoOrigen);

         // Duplicar lineas de composicion del producto original
         await this.duplicarLineasDeComposicioProducto(idProductoOrigen,productoDuplicado);

         // Subimos las imagenes
         await this.uploadImage(productoOrigen, 'imagen_catalogo', productoDuplicado);
         await this.uploadImage(productoOrigen, 'imagen_modo_de_accion', productoDuplicado);

         toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Producto dupicado con éxito</span></div>);

         // Actualizamos los productos
         this.getProductosAcabados();
     }

     async duplicarProducto(producto) {

         if (producto.nombre.en_US !== null){
             producto.nombre.en_US = `DUPLICADO ${producto.nombre.en_US} `;
         }

         if (producto.nombre.en_US !== null){
             producto.nombre.es_ES = `DUPLICADO ${producto.nombre.es_ES} `;
         }

         // Post para generar el producto duplicado con la informacion del producto original
         const requestData = {
             endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS,
             method: 'POST',
             authorized: true,
             contentType: 'application/json',
             data: producto
         };

         return await ApiService.request(requestData)
             .then((response) => {
                 return JSON.parse(response);

             }).catch((error) => {
                 if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al duplicar el producto</span></div>);
                 else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                 this.setState({ loading: false });
             });
     }

     async obtenerProductoOrigen(idProductoOrigen){
         let requestData = {
             endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS + idProductoOrigen + '/',
             method: 'GET',
             authorized: true,
             contentType: 'application/json'
         };

         // Obtenemos el producto completo con el id
         return await ApiService.request(requestData).then((response) => {
             return JSON.parse(response);
         }).catch((error) => {
             if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al duplicar el producto</span></div>);
             else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
             this.setState({ loading: false });
         });}

     async duplicarLineasDeComposicioProducto(idProductoOrigen,productoDuplicado) {
         let requestDataLineasDeComposicion = {
             endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
             method: 'GET',
             authorized: true,
             contentType: 'application/json'
         };

         const lineasDeComposicioProductoOrigen = await ApiService.request(requestDataLineasDeComposicion)
             .then((response) => {
                 const lineas = JSON.parse(response);
                 if (lineas){
                     return lineas.filter(item => item.producto_acabado === Number(idProductoOrigen));
                 }
             })
             .catch((error) => {
                 if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al duplicar el producto</span></div>);
                 else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                 this.setState({ loading: false });});
         // Post del producto obtenido
         if (lineasDeComposicioProductoOrigen){
             lineasDeComposicioProductoOrigen.forEach(item => {
                 const requestData = {
                     endpoint: APP_CONFIG.ENDPOINTS.LINEAS_DE_COMPOSICION,
                     method: 'POST',
                     authorized: true,
                     contentType: 'application/json',
                     data: {
                         componente: item.componente,
                         cantidad_1: item.cantidad_1,
                         magnitud_1: item.magnitud_1,
                         cantidad_2: item.cantidad_2,
                         magnitud_2: item.magnitud_2,
                         solubilidad: item.solubilidad,
                         valor: item.valor,
                         orden: item.orden,
                         producto_generico: null,
                         producto_acabado: productoDuplicado.id,
                     }
                 };
                 ApiService.request(requestData)
                     .then(() => {})
                     .catch((error) => {
                         if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al duplicar el producto</span></div>);
                         else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                         this.setState({ loading: false });
                     });
             });


         }
     }

     // Calcula los paises a mostrar
     calculaPaisesAMostrar(item){
         const paises = [].concat(item.paises);
         return paises.toString();
     }

     // Calcula familia a mostrar
     calculaFamiliaAMostrar(item){
         if (null === item.familia) return '';
         if (null === this.state.listaDeFamilias) return '';
         for (let i = 0; i < this.state.listaDeFamilias.length; ++i){
             if (Number(this.state.listaDeFamilias[i].id) === Number(item.familia)) return (this.state.listaDeFamilias[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
         }
         return '';
     }

     // Calcula empresa a mostrar
     calculaEmpresaAMostrar(item){
         return item.empresa;
     }

     // Calcula estado a mostrar
     calculaEstadoAMostrar(item){
         if (item.activo) return 'Activo';
         else return '--';
     }

     // Calcula ecologico a mostrar
     calculaEcologicoAMostrar(item){
         if (item.ecologico) return 'Ecológico';
         else return '--';
     }

     // Calcula nombre a mostrar
     calculaNombreAMostrar(item){
         if (null === item.nombre) return '';
         else return item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
     }

     // Calcula producto generico a mostrar
     calculaProductoGenericoAMostrar(item){
         const pg = this.state.listaDeProductosGenericos.filter(elem => elem.id === item.producto_generico)[0];
         if (null !== pg) return pg.codigo;
         return '';
     }

     calculaContenidoParaTabla(){
         // Si no hay datos
         if (this.state.listaDeProductos == null) return [];
         // Contenido
         return this.state.listaDeProductos.map(item =>
             ({
                 id: item.id,
                 fields: [
                     item.codigo,
                     this.calculaNombreAMostrar(item),
                     this.calculaProductoGenericoAMostrar(item),
                     this.calculaPaisesAMostrar(item),
                     this.calculaFamiliaAMostrar(item),
                     this.calculaEmpresaAMostrar(item),
                     this.calculaEstadoAMostrar(item),
                     this.calculaEcologicoAMostrar(item)
                 ],
                 acciones: [
                     { label: 'Editar', callback: this.editarProductoCallback },
                     { label: 'Eliminar', callback: this.eliminarProductoCallback },
                     { label: 'Duplicar', callback: this.duplicarProductoCallback }
                 ]
             })
         );
     }

     render() {

         // Editar
         if (null !== this.state.redirectId) return (<Redirect to={APP_CONFIG.RUTAS.PRODUCTOS_EDIT + '/a/' + this.state.redirectId}/>);

         // Si aun no tenemos datos
         if (
             (null === this.state.listaDeProductos)
            || (null === this.state.listaDeProductosGenericos)
            || (null === this.state.listaDeFamilias)
         ) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);
         if (this.state.loading)
             return (
                 <PaginaBiovert>
                     <LoadingBiovert/>
                 </PaginaBiovert>
             );
         // Lista
         return (
             <PaginaBiovert >
                 <div className='producto-container' >

                     {/* Breadcrumb */}
                     <BreadCrumbBiovert value = {[ 'Productos acabados' ]}/>

                     {/* Titulo */}
                     <HeaderBiovert type='t1'>Productos acabados</HeaderBiovert>

                     <br></br>
                     <TablaBiovert cabeceras={[ 'N. Registro', 'Nombre', 'Producto genérico', 'Países', 'Familia', 'Empresa', 'Estado', 'Ecológico' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarProducto} paginaFiltro={'filtroProductoAcabados'} saveScrollValue={true}/>
                 </div>
             </PaginaBiovert>
         );

     }

}

export default ProductosAcabadosViewPage;
