import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import HeaderBiovert from '../../../components/header-biovert';
import SelectIdioma from '../../../components/select-idioma';

class InformesViewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listaDeEnsayos: null,
            redirectId: null,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idiomaSeleccionadoGenericos: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            pais: 'es',
            listaDePaises: null,
            paisesSeleccionados: this.props.paises,
            dialogoAsociarPaisShow: true,
            getidtg: null,
        };
        // Este enlace es necesario para hacer que `this` funcione en el callback
        this.handleClick = this.handleClick.bind(this);
        this.handleClickGenericos = this.handleClickGenericos.bind(this);
    }

    componentDidMount() {
    // Obtenemos la lista de ensayos

        this.getPaises();
    }

    getPaises() {
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PAISES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json',
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDePaises: JSON.parse(response) });
                let x = this.state.listaDePaises.sort((a, b) =>
                    a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] >
          b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]
                        ? 1
                        : b.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE] >
              a.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]
                            ? -1
                            : 0
                );
                this.setState({ listaDePaises: x });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    getFechaActual() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        var yyyy = today.getFullYear();
        let hour = today.getHours();
        let minute = today.getMinutes();
        return (today = yyyy + dd + mm + '-' + hour + minute);
    }

  generateExpot = (nombreIdioma, paisIdima) => {
      let requestData = {
          endpoint:
        APP_CONFIG.ENDPOINTS.INFORME_PRODUCTOS_ACABADOS +
        `?idioma=${nombreIdioma}&pais=${paisIdima}`,
          method: 'GET',
          authorized: true,
      };

      ApiService.request(requestData)
          .then((response) => {
              let listaDeEnsayos = response;
              var today = this.getFechaActual();

              const a = document.createElement('a');
              a.href = `data:text/csv;charset=utf-8,${listaDeEnsayos}`;
              a.textContent = 'download';
              a.download = `${today}_productos_acabados.csv`;
              a.click();
          })
          .catch((error) => {
              console.log('Error: ' + JSON.stringify(error));
          });
  };

  generateExpotGenericos = (nombreIdioma) => {
      let requestData = {
          endpoint:
        APP_CONFIG.ENDPOINTS.INFORME_PRODUCTOS_GENERICOS +
        `?idioma=${nombreIdioma}`,
          method: 'GET',
          authorized: true,
      };

      ApiService.request(requestData)
          .then((response) => {
              let listaDeEnsayos = response;
              var today = this.getFechaActual();

              const a = document.createElement('a');
              a.href = `data:text/csv;charset=utf-8,${listaDeEnsayos}`;
              a.textContent = 'download';
              a.download = `${today}_productos_genericos.csv`;
              a.click();
          })
          .catch((error) => {
              console.log('Error: ' + JSON.stringify(error));
          });
  };
  // Editar
  handleClick = () => {
      this.generateExpot(this.state.idiomaSeleccionado, this.state.pais);
  };

  handleClickGenericos = () => {
      this.generateExpotGenericos(this.state.idiomaSeleccionadoGenericos);
  };

  render() {
      if (null === this.state.listaDePaises)
          return (
              <PaginaBiovert>
                  <LoadingBiovert />
              </PaginaBiovert>
          );

      return (
          <PaginaBiovert>
              <div key='elemento_lista' className='ensayo-container'>
                  {/* Breadcrumb */}
                  <BreadCrumbBiovert value={[ 'INFORMES' ]} /> {/* Titulo + boton add */}
                  <div
                      style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginRight: '20px',
                          alignItems: 'flex-end',
                      }}
                  >
                      <HeaderBiovert type='t1'>INFORMES</HeaderBiovert>
                  </div>
                  <br></br>
                  <div className='tabla-biovert-container'>
                      <div className='tabla-biovert-cabecera'>
                          <div className='tabla-biovert-elemento-cabecera estilowidth'>
                N. Informes
                          </div>
                      </div>
                      <div className='tabla-biovert-divider'></div>
                      <div className='tabla-biovert-fila ELEMENTOPRUEBA'>
                          <div id='1' className='tabla-biovert-elemento-fila'>
                              <div id='1' className='tabla-biovert-elemento-fila-content'>
                                  <div id='1'>1</div>
                              </div>
                          </div>
                          <div id='1' className='tabla-biovert-elemento-fila'>
                              <div id='1' className='tabla-biovert-elemento-fila-content'>
                                  <div id='1'>Productos Acabados</div>
                              </div>
                          </div>
                          <div id='1' className='tabla-biovert-elemento-fila'>
                              <div id='1' className='tabla-biovert-elemento-fila-content'>
                                  <div id='1'>
                                      <SelectIdioma
                                          onSelect={(idioma) =>
                                              this.setState({ idiomaSeleccionado: idioma })
                                          }
                                      />
                                  </div>
                              </div>
                          </div>
                          <div id='1' className='tabla-biovert-elemento-fila'>
                              <div id='1' className='tabla-biovert-elemento-fila-content'>
                                  <div id='1'>
                                      <div className='styled-select green semi-square'>
                                          <select
                                              id='paises2'
                                              onChange={(e) =>
                                                  this.setState({ pais: e.nativeEvent.target.value })
                                              }
                                              onClick={(e) =>
                                                  this.setState({ pais: e.nativeEvent.target.value })
                                              }
                                              value={this.state.pais}
                                              className='dialogo-asociar-pais-select'
                                              title='Pais'
                                          >
                                              {this.state.listaDePaises.map((item) => (
                                                  <option
                                                      key={item.codigo_iso_alfa_2}
                                                      value={item.codigo_iso_alfa_2}
                                                  >
                                                      {item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}(
                                                      {item.codigo_iso_alfa_2})
                                                  </option>
                                              ))}{' '}
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='tabla-biovert-elemento-fila-align-right'>
                              <div className='button-dropdown'>
                                  <button className='button-dropbtn' onClick={this.handleClick}>
                    Exportar &nbsp;
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className='tabla-biovert-divider'></div>
                      <div className='tabla-biovert-fila ELEMENTOPRUEBA'>
                          <div id='2' className='tabla-biovert-elemento-fila'>
                              <div id='2' className='tabla-biovert-elemento-fila-content'>
                                  <div id='2'>2</div>
                              </div>
                          </div>
                          <div id='2' className='tabla-biovert-elemento-fila'>
                              <div id='2' className='tabla-biovert-elemento-fila-content'>
                                  <div id='2'>Productos Genéricos</div>
                              </div>
                          </div>
                          <div id='2' className='tabla-biovert-elemento-fila'>
                              <div id='2' className='tabla-biovert-elemento-fila-content'>
                                  <div id='2'>
                                      <SelectIdioma
                                          onSelect={(idioma) =>
                                              this.setState({ idiomaSeleccionadoGenericos: idioma })
                                          }
                                      />
                                  </div>
                              </div>
                          </div>
                          <div id='2' className='tabla-biovert-elemento-fila'>
                              <div id='2' className='tabla-biovert-elemento-fila-content'>
                                  <div id='2'>
                                      <div style={{ width: '300px' }}></div>
                                  </div>
                              </div>
                          </div>
                          <div className='tabla-biovert-elemento-fila-align-right'>
                              <div className='button-dropdown'>
                                  <button
                                      className='button-dropbtn'
                                      onClick={this.handleClickGenericos}
                                  >
                    Exportar &nbsp;
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </PaginaBiovert>
      );
  }
}

export default InformesViewPage;
