import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasEnvasesPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeEnvases: null,
            listaDeMagnitudes: null,
            idEnvase: null,
            envase: {
                valor: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                magnitud: null,
                imagen: null,
            },
            imagenAux: null,
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de envases
        this.getEnvases();
        this.getMagnitudes();
    }

    // Get envases de la base de datos
    getEnvases(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENVASES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeEnvases: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get magnitudes de la base de datos
    getMagnitudes(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.MAGNITUDES,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeMagnitudes: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });

    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idEnvase: null,
        envase: {
            valor: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
        },
        imagenAux: null,
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario
    handleChangeNombre = event => {
        const s = this.state;
        s.envase.valor[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    handleChangeMagnitud = event => {
        const s = this.state;
        s.envase.magnitud = Number(event.nativeEvent.target.value);
        this.setState(s);
    }

    handleChangeImagen = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            this.setState({ imagenAux: file });
        }
    }

    handleChangeEliminarImagen = () => {
        this.setState({ imagenAux: 'delete' });
    }

    // Guardar envase
    guardarFormularioEnvase = () => {
        // Comprobamos campo no vacio
        if (this.state.envase.valor[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.envase.valor[this.state.idiomaSeleccionado].length) return true;

        // Llamamos al endpoint
        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ENVASES,
            method: '',
            authorized: true,
            contentType: 'application/json',
            data: this.state.envase
        };

        if (null === this.state.idEnvase){
            // Si es nuevo
            requestData.method = 'POST';
        } else {
            // Si estamos editando
            requestData.endpoint = requestData.endpoint + this.state.idEnvase + '/';
            requestData.method = 'PUT';
        }
        ApiService.request(requestData)
            .then(response => {
                this.compruebaImagenes(Number(JSON.parse(response).id));
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el envase</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });

    }

    // Comprobamos si hay imagen a guardar
    compruebaImagenes = id => {

        // Comprobamos si hay que eliminar
        if ('delete' === this.state.imagenAux){

            // Llamamos al endpoint para borrar imagen
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENVASES + id + '/delete_imagen/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });

        } else if (null !== this.state.imagenAux) {

            // Upload imagen
            const formData = new FormData();
            formData.append('imagen', this.state.imagenAux);

            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENVASES + id + '/upload_imagen/',
                method: 'POST',
                authorized: true,
                contentType: '',
                data: formData
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        } else this.resultadoGuardarOk();

    }

    // Resultado de guardar
    resultadoGuardarOk = () => {
        this.getEnvases();
        toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Envase guardado con éxito</span></div>);
        this.setState({
            idEnvase: null,
            envase: {
                valor: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                imagen: null,
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
        });
    }

    // Calcular select magnitudes
    calculaOpcionMagnitudes = () => {
        if (Array.isArray(this.state.listaDeMagnitudes) && this.state.listaDeMagnitudes.length > 0){
            return (
                this.state.listaDeMagnitudes.map(
                    item => <option key={item.id} value={item.id}>{item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
                )
            );
        } else {
            return '';
        }
    }

    // Editar envase
    editarEnvaseCallback = (event) => {this.editarEnvase(Number(event.target.id));}

    editarEnvase = (id) => {
        // Cargamos los datos del envase
        let auxEnvase = null;
        for (let i = 0; i < this.state.listaDeEnvases.length; ++i){
            if (this.state.listaDeEnvases[i].id === id){
                auxEnvase = JSON.parse(JSON.stringify(this.state.listaDeEnvases[i]));
                break;
            }
        }

        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idEnvase: id,
            envase: auxEnvase,
            imagenAux: null,
        });
    }

    // Eliminar envase
    eliminarEnvaseCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let nombre = null;
        for (let i = 0; i < this.state.listaDeEnvases.length; ++i){
            if (this.state.listaDeEnvases[i].id === Number(id)){
                nombre = this.state.listaDeEnvases[i].valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === nombre) return true;
        if (window.confirm('¿Desea eliminar el envase ' + nombre + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.ENVASES + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Envase eliminado con éxito</span></div>);
                    this.getEnvases();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el envase</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaMagnitudAMostrar(item){
        if (null === item.magnitud) return '';
        for (let i = 0; i < this.state.listaDeMagnitudes.length; ++i){
            if (Number(this.state.listaDeMagnitudes[i].id) === Number(item.magnitud)) return (this.state.listaDeMagnitudes[i].valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
        }
        return '';
    }

    calculaContenidoParaTabla(){
        // Vars
        let contenido = [];

        // Si no hay datos
        if (this.state.listaDeEnvases == null) return [];
        if (this.state.listaDeMagnitudes == null) return [];

        // Contenido
        for (let i = 0; i < this.state.listaDeEnvases.length; ++i){
            let item = this.state.listaDeEnvases[i];
            contenido.push(
                {
                    id: item.id,
                    fields: [ item.imagen, item.valor[APP_CONFIG.DEFAULT_SELECTED_LOCALE], this.calculaMagnitudAMostrar(item) ],
                    acciones: [
                        { label: 'Editar', callback: this.editarEnvaseCallback },
                        { label: 'Eliminar', callback: this.eliminarEnvaseCallback }
                    ]
                }
            );
        }
        return contenido;
    }

    calculaImagenMostrar(){
        if ('delete' === this.state.imagenAux) return '';
        if (null === this.state.imagenAux) return this.state.envase.imagen;
    }

    renderListaDeElementos(){
        return ([
            <div key='elemento_lista' className='envase-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Envases' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Envases</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir envase' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Imagen', 'Valor', 'Magnitud' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarEnvase}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.envase) return (<div key='ecee'> Error cargando el envase</div>);

        // Values
        return ([
            <div key='envase_add_edit' className='envase-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='envase-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Envases', 'Nuevo envase' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Envases', 'Editar envase' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nuevo envase</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.envase.valor[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='envase-formulario'>
                        <div className='envase-form-bloque'>
                            <div className='envase-form-lbl-nombre'>Nombre envase</div>
                            <div className='envase-form-nombre'>
                                <input
                                    id='envase-add-edit'
                                    type='text'
                                    value={this.state.envase.valor[this.state.idiomaSeleccionado] || ''}
                                    onChange={this.handleChangeNombre}
                                    className='envase-add-edit-text-input'
                                />
                            </div>
                        </div>
                        <div className='envase-form-bloque'>
                            <div className='envase-form-lbl-nombre'>Magnitud</div>
                            <div className='envase-form-nombre'>
                                <select
                                    onMouseDown={this.handleChangeMagnitud}
                                    defaultValue={this.state.envase.magnitud || ''}
                                    className='envase-add-edit-magnitud-select'>
                                    {this.calculaOpcionMagnitudes()}
                                </select>
                            </div>
                        </div>
                    </div>
                </GreyboxBiovert>

                <SeccionImagenBiovert
                    titulo='Imagen del envase'
                    urlImagen={this.calculaImagenMostrar()}
                    onDelete={this.handleChangeEliminarImagen}
                    onUpload={this.handleChangeImagen}/>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarFormularioEnvase}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasEnvasesPage;
