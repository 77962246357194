import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import LoadingBiovert from '../../../components/loading-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import GreyboxBiovert from '../../../components/greybox-biovert';
import EstadoFenologicoBiovert from '../../../components/estado-fenologico-biovert';

class CultivosDetailPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            cultivo: null,
            listaDeGruposDeCultivo: null,
            listaDeComoSeAplica: null,
            listaDeEstadosFenologicos: null,
            listaDeProductosGenericos: null,
            listaDeProductosAcabados: null,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            redirect: false,
        };
    }

    componentDidMount(){
        // Obtenemos el cultivo, grupos de cultivo, la lista de estados fenologicos y de como se aplica
        if ((null !== this.state.id) && (undefined !== this.state.id)) {
            this.getCultivo();
            this.getGruposDeCultivo();
            this.getComoSeAplica();
            this.getEstadosFenologicos();
            this.getProductosGenericos();
            this.getProductosAcabados();
        }
    }

    // Get cultivo de la base de datos
    getCultivo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS + this.state.id + '/',
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };

        ApiService.request(requestData)
            .then((response) => {
                this.setState({ cultivo: JSON.parse(response) });
            })
            .catch((error) => {
                this.setState({ redirect: true });
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get grupo de cultivo de la base de datos
    getGruposDeCultivo(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.GRUPOS_DE_CULTIVO,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeGruposDeCultivo: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get como se aplica de la base de datos
    getComoSeAplica(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.COMO_SE_APLICA,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeComoSeAplica: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get estados fenologicos de la base de datos
    getEstadosFenologicos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                const estadosFenologicos = JSON.parse(response).filter(item => Number(item.cultivo) === Number(this.state.id));
                this.fixOrderEstadosFenologicos(estadosFenologicos);
                this.setState({ listaDeEstadosFenologicos: estadosFenologicos });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get productos genericos de la base de datos
    getProductosGenericos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_GENERICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductosGenericos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get productos acabados de la base de datos
    getProductosAcabados(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.PRODUCTOS_ACABADOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeProductosAcabados: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    fixOrderEstadosFenologicos(estadosFenologicos){
        estadosFenologicos.sort(this.ordenaEstadosFenologicos);

        for (let i = 0; i < estadosFenologicos.length; ++i){
            if (estadosFenologicos[i].orden !== i){

                estadosFenologicos[i].orden = i;
                const requestData = {
                    endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + estadosFenologicos[i].id + '/',
                    method: 'PUT',
                    authorized: true,
                    contentType: 'application/json',
                    data: estadosFenologicos[i]
                };
                ApiService.request(requestData)
                    .then(() => {
                        this.getEstadosFenologicos();
                    })
                    .catch((error) => {console.log('Error: ' + JSON.stringify(error));});
            }
        }

    }

    getGrupoDeCultivoFromId = id => this.state.listaDeGruposDeCultivo.filter(item => item.id === id)[0].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];

    ordenaEstadosFenologicos = (a,b) => {
        if (a.orden > b.orden) return 1;
        else if (a.orden < b.orden) return -1;
        else return 0;
    }

    getEstadoFenologicoFromId = id => this.state.listaDeEstadosFenologicos.filter(item => Number(item.id) === Number(id))[0];

    onArrowClick = (id, direction) => {
        let requestData = null;
        let ordenAux = null;
        const lef = this.state.listaDeEstadosFenologicos;

        // Si subimos
        if ('up' === direction){

            // Comprobamos que no sea el primero
            if (0 === this.getEstadoFenologicoFromId(id).orden) return;

            for (let i = 0; i < lef.length; ++i){
                if (lef[i].id === id){

                    // Intercambiamos el orden
                    ordenAux = lef[i].orden;
                    lef[i].orden = lef[i - 1].orden;
                    lef[i - 1].orden = ordenAux;

                    requestData = {
                        endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + id + '/',
                        method: 'PUT',
                        authorized: true,
                        contentType: 'application/json',
                        data: lef[i]
                    };
                    ApiService.request(requestData)
                        .then(() => {this.getEstadosFenologicos();}).catch((error) => {console.log('Error: ' + JSON.stringify(error));});

                    requestData = {
                        endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + lef[i - 1].id + '/',
                        method: 'PUT',
                        authorized: true,
                        contentType: 'application/json',
                        data: lef[i - 1]
                    };
                    ApiService.request(requestData)
                        .then(() => {this.getEstadosFenologicos();}).catch((error) => {console.log('Error: ' + JSON.stringify(error));});

                }
            }

        }

        if ('down' === direction){
            // Comprobamos que no sea el ultimo
            if ((this.state.listaDeEstadosFenologicos.length - 1) === this.getEstadoFenologicoFromId(id).orden) return;

            for (let i = 0; i < lef.length; ++i){
                if (lef[i].id === id){

                    // Intercambiamos el orden
                    ordenAux = lef[i].orden;
                    lef[i].orden = lef[i + 1].orden;
                    lef[i + 1].orden = ordenAux;

                    requestData = {
                        endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + id + '/',
                        method: 'PUT',
                        authorized: true,
                        contentType: 'application/json',
                        data: lef[i]
                    };
                    ApiService.request(requestData)
                        .then(() => {this.getEstadosFenologicos();}).catch((error) => {console.log('Error: ' + JSON.stringify(error));});

                    requestData = {
                        endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + lef[i + 1].id + '/',
                        method: 'PUT',
                        authorized: true,
                        contentType: 'application/json',
                        data: lef[i + 1]
                    };
                    ApiService.request(requestData)
                        .then(() => {this.getEstadosFenologicos();}).catch((error) => {console.log('Error: ' + JSON.stringify(error));});

                }
            }

        }

    }

    renderEstadosFenologicos(){
        return this.state.listaDeEstadosFenologicos.sort(this.ordenaEstadosFenologicos).map(item => this.renderEstadoFenologico(item));
    }

    renderEstadoFenologico = ef => {
        return <div key={'ef-' + ef.id} className='cultivo-detalle-estados-fenologicos-container'>
            <EstadoFenologicoBiovert
                estadoFenologico={ef}
                comoSeAplica={this.state.listaDeComoSeAplica}
                productosGenericos={this.state.listaDeProductosGenericos}
                productosAcabados={this.state.listaDeProductosAcabados}
                idiomaSeleccionado={this.state.idiomaSeleccionado}
                onArrowClick={this.onArrowClick}
            />
        </div>;
    }

    render() {

        // Si no hay cultivo volvemos al view
        if (this.state.redirect) return (<Redirect to={APP_CONFIG.RUTAS.CULTIVOS_VIEW}/>);

        // Si aun no tenemos datos
        if ((null === this.state.cultivo)
          || (null === this.state.listaDeGruposDeCultivo)
          || (null === this.state.listaDeEstadosFenologicos)
          || (null === this.state.listaDeProductosGenericos)
          || (null === this.state.listaDeProductosAcabados)
          || (null === this.state.listaDeComoSeAplica)
        ) return (<PaginaBiovert><LoadingBiovert/></PaginaBiovert>);

        // Cultivos
        return (
            <PaginaBiovert>
                <div className='cultivo-detail-container'>
                    {/* Breadcrumb  + select idioma */}

                    <div className='cultivo-detail-breadcrumb-seleccion-idioma'>
                        <BreadCrumbBiovert value = {[
                            'Cultivos',
                            this.getGrupoDeCultivoFromId(this.state.cultivo.grupo_de_cultivo),
                            this.state.cultivo.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]
                        ]}/>
                        <SelectIdioma onSelect={idioma => this.setState({ idiomaSeleccionado: idioma })}/>
                    </div>

                    {/* Titulo */}
                    <HeaderBiovert type='t1'>Cultivo {this.state.cultivo.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</HeaderBiovert>

                    {/* Formulario */}
                    <GreyboxBiovert>
                        <HeaderBiovert type='t1'>Estados fenológicos</HeaderBiovert>
                        <div className='cultivo-detalle-estados-fenologicos-container'>
                            {this.renderEstadosFenologicos()}
                        </div>
                    </GreyboxBiovert>

                </div>
            </PaginaBiovert>
        );

    }

}

export default CultivosDetailPage;
