import React, { Component } from 'react';
import './index.css';

class HeaderBiovert extends Component {

    render() {
        let headerClass = null;

        // Default
        headerClass = 'default';

        // t1
        if ('t1' === this.props.type) headerClass = 'header-biovert-t1';


        if ('default' !== headerClass) return (<div className={headerClass}>{this.props.children}</div>);
        else return (<h1>{this.props.children}</h1>);
    }

}

export default HeaderBiovert;
