import React, { Component } from 'react';
import './index.css';

// Componentes
import GreyboxBiovert from '../greybox-biovert';
import InputFileBiovert from '../input-file-biovert';
import ButtonBiovert from '../button-biovert';
import HeaderBiovert from '../header-biovert';

class SeccionImagenBiovert extends Component {

    render() {
        // Imagen
        let imagenUrl = false;
        if ((undefined !== this.props.urlImagen) && (null !== this.props.urlImagen) && ('' !== this.props.urlImagen)) imagenUrl = this.props.urlImagen;

        return (
            <GreyboxBiovert>
                <div className='seccion-imagen-biovert-container'>
                    <HeaderBiovert type='t1'>{this.props.titulo}</HeaderBiovert>
                    <div>
                        <div className='seccion-imagen-biovert'>
                            <div className='seccion-imagen-biovert-elemento-imagen'>
                                {imagenUrl !== false
                                    ? <img src={imagenUrl} alt='Biovert' className='seccion-imagen-biovert-imagen'/>
                                    : <div className='seccion-imagen-biovert-imagen-placeholder'>&nbsp;</div>}
                            </div>
                            <div className='seccion-imagen-biovert-elemento-botones'>
                                <div className='seccion-imagen-biovert-elemento-botones-eliminar'>
                                    <ButtonBiovert type='primary' label='Borrar imagen' handleClick={this.props.onDelete}/>
                                </div>
                                <div>
                                    <InputFileBiovert handleChange={this.props.onUpload}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GreyboxBiovert>
        );
    }

}

export default SeccionImagenBiovert;
