import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasEstadosFenologicosPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeEstadosFenologicos: null,
            listaDeCultivos: null,
            idEstadoFenologico: null,
            estadoFenologico: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                cultivo: null,
                imagen: null,
            },
            imagenAux: null,
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de estadoFenologicos
        this.getEstadosFenologicos();
        this.getCultivos();
    }

    // Get estadoFenologicos de la base de datos
    getEstadosFenologicos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeEstadosFenologicos: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Get grupo de estadoFenologico de la base de datos
    getCultivos(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.CULTIVOS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                const s = this.state;
                // Assignamos por defecto el primer cultivo
                const listaDeCultivos = JSON.parse(response);
                let cultivoPorDefecto = null;
                if (listaDeCultivos.length > 0) cultivoPorDefecto = listaDeCultivos[0].id;

                s.listaDeCultivos = listaDeCultivos;
                s.estadoFenologico.cultivo = cultivoPorDefecto;
                this.setState(s);
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

  // Navegar dentro de la página
  nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });

  nav2PageAdd = () => this.setState({
      accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
      idEstadoFenologico: null,
      estadoFenologico: {
          nombre: {
              [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
          },
          cultivo: this.state.estadoFenologico.cultivo,
      },
      imagenAux: null,
      idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
  });

  // Seleccionar idioma
  seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

  // Formulario
  handleChangeNombre = event => {
      const s = this.state;
      s.estadoFenologico.nombre[this.state.idiomaSeleccionado] = event.target.value;
      this.setState(s);
  }

  handleChangeCultivo = event => {
      const s = this.state;
      s.estadoFenologico.cultivo = Number(event.nativeEvent.target.value);
      this.setState(s);
  }

  handleChangeImagen = event => {
      let file = event.target.files[0];
      if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
          toast.warning(
              <div>
                  <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
              </div>
          );
      } else {
          this.setState({ imagenAux: file });
      }
  }

  handleChangeEliminarImagen = () => {
      this.setState({ imagenAux: 'delete' });
  }

  // Guardar estadoFenologico
  guardarFormularioEstadoFenologico = () => {
      // Comprobamos campo no vacio
      if (this.state.estadoFenologico.nombre[this.state.idiomaSeleccionado] == null
        || 0 >= this.state.estadoFenologico.nombre[this.state.idiomaSeleccionado].length) return true;

      // Llamamos al endpoint
      const requestData = {
          endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS,
          method: '',
          authorized: true,
          contentType: 'application/json',
          data: this.state.estadoFenologico
      };

      if (null === this.state.idEstadoFenologico){
          // Si es nuevo
          requestData.method = 'POST';
      } else {
          // Si estamos editando
          requestData.endpoint = requestData.endpoint + this.state.idEstadoFenologico + '/';
          requestData.method = 'PUT';
      }
      ApiService.request(requestData)
          .then(response => {
              this.compruebaImagenes(Number(JSON.parse(response).id));
          })
          .catch((error) => {
              if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar el estado fenológico</span></div>);
              else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
              console.log('Error: ' + JSON.stringify(error));
          });

  }

  // Comprobamos si hay imagen a guardar
  compruebaImagenes = id => {

      // Comprobamos si hay que eliminar
      if ('delete' === this.state.imagenAux){

          // Llamamos al endpoint para borrar imagen
          let requestData = {
              endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + id + '/delete_imagen/',
              method: 'DELETE',
              authorized: true,
              contentType: 'application/json'
          };
          ApiService.request(requestData)
              .then(() => {
                  this.resultadoGuardarOk();
              })
              .catch((error) => {
                  console.log('Error: ' + JSON.stringify(error));
              });

      } else if (null !== this.state.imagenAux) {

          // Upload imagen
          const formData = new FormData();
          formData.append('imagen', this.state.imagenAux);

          let requestData = {
              endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + id + '/upload_imagen/',
              method: 'POST',
              authorized: true,
              contentType: '',
              data: formData
          };
          ApiService.request(requestData)
              .then(() => {
                  this.resultadoGuardarOk();
              })
              .catch((error) => {
                  toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                  console.log('Error: ' + JSON.stringify(error));
              });
      } else this.resultadoGuardarOk();

  }

  // Resultado de guardar
  resultadoGuardarOk = () => {
      this.getEstadosFenologicos();
      toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Estado fenológico guardado con éxito</span></div>);
      this.setState({
          idEstadoFenologico: null,
          estadoFenologico: {
              nombre: {
                  [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
              },
              cultivo: this.state.estadoFenologico.cultivo,
              imagen: null,
          },
          accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
      });
  }

  // Calcular select grupos de estadoFenologico
  calculaOpcionCultivos = () => {
      if (Array.isArray(this.state.listaDeCultivos) && this.state.listaDeCultivos.length > 0){
          return (
              this.state.listaDeCultivos.map(
                  item => <option key={item.id} value={item.id}>{item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]}</option>
              )
          );
      } else {
          return '';
      }
  }

  // Editar estadoFenologico
  editarEstadoFenologicoCallback = (event) => {this.editarEstadoFenologico(Number(event.target.id));}

  editarEstadoFenologico = (id) => {
      // Cargamos los datos del estadoFenologico
      let auxCultivo = null;
      for (let i = 0; i < this.state.listaDeEstadosFenologicos.length; ++i){
          if (this.state.listaDeEstadosFenologicos[i].id === id){
              auxCultivo = JSON.parse(JSON.stringify(this.state.listaDeEstadosFenologicos[i]));
              break;
          }
      }

      this.setState({
          accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
          idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
          idEstadoFenologico: id,
          estadoFenologico: auxCultivo,
          imagenAux: null,
      });
  }

  // Eliminar estadoFenologico
  eliminarEstadoFenologicoCallback = (event) => {
      let id = event.target.id;

      // Get información del elemento a eliminar
      let nombre = null;
      for (let i = 0; i < this.state.listaDeEstadosFenologicos.length; ++i){
          if (this.state.listaDeEstadosFenologicos[i].id === Number(id)){
              nombre = this.state.listaDeEstadosFenologicos[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
              break;
          }
      }
      if (null === nombre) return true;
      if (window.confirm('¿Desea eliminar el estado fenológico ' + nombre + '?')){
          let requestData = {
              endpoint: APP_CONFIG.ENDPOINTS.ESTADOS_FENOLOGICOS + id + '/',
              method: 'DELETE',
              authorized: true,
              contentType: 'application/json'
          };
          ApiService.request(requestData)
              .then(() => {
                  toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Estado fenológico eliminado con éxito</span></div>);
                  this.getEstadosFenologicos();
              })
              .catch((error) => {
                  if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar el estadoFenologico</span></div>);
                  else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                  console.log('Error: ' + JSON.stringify(error));
              });
      }
  };

  calculaCultivoAMostrar(item){
      if (null === item.cultivo) return '';
      for (let i = 0; i < this.state.listaDeCultivos.length; ++i){
          if (Number(this.state.listaDeCultivos[i].id) === Number(item.cultivo)) return (this.state.listaDeCultivos[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE]);
      }
      return '';
  }

  calculaContenidoParaTabla(){
      // Vars
      let contenido = [];

      // Si no hay datos
      if (this.state.listaDeEstadosFenologicos == null) return [];
      if (this.state.listaDeCultivos == null) return [];

      // Contenido
      for (let i = 0; i < this.state.listaDeEstadosFenologicos.length; ++i){
          let item = this.state.listaDeEstadosFenologicos[i];
          contenido.push(
              {
                  id: item.id,
                  fields: [ item.imagen, item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE], this.calculaCultivoAMostrar(item) ],
                  acciones: [
                      { label: 'Editar', callback: this.editarEstadoFenologicoCallback },
                      { label: 'Eliminar', callback: this.eliminarEstadoFenologicoCallback }
                  ]
              }
          );
      }
      return contenido;
  }

  calculaImagenMostrar(){
      if ('delete' === this.state.imagenAux) return '';
      if (null === this.state.imagenAux) return this.state.estadoFenologico.imagen;
  }

  renderListaDeElementos(){
      return ([
          <div key='elemento_lista' className='estado-fenologico-container'>
              {/* Breadcrumb */}
              <BreadCrumbBiovert value = {[ 'Maestras', 'Estados fenológicos' ]}/>

              {/* Titulo + boton add */}
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                  <HeaderBiovert type='t1'>Estados fenológicos</HeaderBiovert>
                  <ButtonBiovert type='primary' label='Añadir estado fenológico' handleClick={this.nav2PageAdd}/>
              </div>

              <br></br>
              <TablaBiovert cabeceras={[ 'Imagen', 'Nombre', 'Cultivo' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarEstadoFenologico}/>
          </div>
      ]);
  }

  renderFormulario(){

      if (null === this.state.estadoFenologico) return (<div key='ecee'> Error cargando el estadoFenologico</div>);

      // Values
      return ([
          <div key='estadoFenologico_add_edit' className='estado-fenologico-container'>

              {/* Breadcrumb + seleccionar idioma */}
              <div className='estado-fenologico-breadcrumb-seleccion-idioma'>
                  {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                      ? <BreadCrumbBiovert value = {[ 'Maestras', 'Estados fenológicos', 'Nuevo estado fenólogico' ]}/>
                      : <BreadCrumbBiovert value = {[ 'Maestras', 'Estados fenológicos', 'Editar estado fenólogico' ]}/>
                  }
                  <SelectIdioma onSelect={this.seleccionarIdioma}/>
              </div>

              {/* Titulo */}
              {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                  ? <HeaderBiovert type='t1'>Nuevo estado fenológico</HeaderBiovert>
                  : <HeaderBiovert type='t1'><RawHTML>{this.state.estadoFenologico.nombre[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
              }

              {/* Formulario */}
              <GreyboxBiovert>
                  <div className='estado-fenologico-formulario'>
                      <div className='estado-fenologico-form-bloque'>
                          <div className='estado-fenologico-form-lbl-nombre'>Nombre estado fenológico</div>
                          <div className='estado-fenologico-form-nombre'>
                              <input
                                  id='estado-fenologico-add-edit'
                                  type='text'
                                  value={this.state.estadoFenologico.nombre[this.state.idiomaSeleccionado] || ''}
                                  onChange={this.handleChangeNombre}
                                  className='estado-fenologico-add-edit-text-input'
                              />
                          </div>
                      </div>
                      <div className='estado-fenologico-form-bloque'>
                          <div className='estado-fenologico-form-lbl-nombre'>Cultivo</div>
                          <div className='estado-fenologico-form-nombre'>
                              <select
                                  onMouseDown={this.handleChangeCultivo}
                                  defaultValue={this.state.estadoFenologico.cultivo || ''}
                                  className='estado-fenologico-add-edit-cultivo-select'>
                                  {this.calculaOpcionCultivos()}
                              </select>
                          </div>
                      </div>
                  </div>
              </GreyboxBiovert>

              <SeccionImagenBiovert
                  titulo='Imagen del estado fenológico'
                  urlImagen={this.calculaImagenMostrar()}
                  onDelete={this.handleChangeEliminarImagen}
                  onUpload={this.handleChangeImagen}/>

              <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarFormularioEstadoFenologico}/>

          </div>
      ]);
  }

  render() {
      //Vars
      let pagina = [];

      // Añadimos los componentes en funcion de la acción seleccionada
      if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
      if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
      if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

      return (<PaginaBiovert>{pagina}</PaginaBiovert>);

  }

}

export default MaestrasEstadosFenologicosPage;
