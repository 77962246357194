import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './components/AuthRoute.js';
import { APP_CONFIG } from './utils/globales.js';

// Normalize.css
import 'normalize.css';

// Notificaciones
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Importamos pages
import MaestrasMagnitudesPage from './pages/Maestras/Magnitudes';
import MaestrasIconosPage from './pages/Maestras/Iconos';
import MaestrasFamiliasPage from './pages/Maestras/Familias';
import MaestrasEnvasesPage from './pages/Maestras/Envases';
import MaestrasComponentesQuimicosPage from './pages/Maestras/ComponentesQuimicos';
import MaestrasCultivosPage from './pages/Maestras/Cultivos';
import MaestrasEstadosFenologicosPage from './pages/Maestras/EstadosFenologicos';
import MaestrasSolucionesPage from './pages/Maestras/Soluciones';
import ProductosGenericosAddPage from './pages/Productos/Genericos/Add';
import ProductosGenericosViewPage from './pages/Productos/Genericos/View';
import ProductosAcabadosAddPage from './pages/Productos/Acabados/Add';
import ProductosAcabadosViewPage from './pages/Productos/Acabados/View';
import ProductosEditPage from './pages/Productos/Edit';
import CultivosViewPage from './pages/Cultivos/View';
import CultivosDetailPage from './pages/Cultivos/Detail';
import EnsayosAddPage from './pages/Ensayos/Add';
import EnsayosViewPage from './pages/Ensayos/View';
import EnsayosDetailPage from './pages/Ensayos/Detail';
import SolucionesViewPage from './pages/Soluciones/View';
import SolucionesDetailPage from './pages/Soluciones/Detail';
import InformesViewPage from './pages/Informe/View';
import PrincipalPage from './pages/Principal';
// import UsuariosPage from './pages/Usuarios';
import PerfilPage from './pages/Perfil';
// import RestaurarClavePage from './pages/RestaurarClave';
// import RecuperarClavePage from './pages/RecuperarClave';
import LogoutPage from './pages/Logout';
import LoginPage from './pages/Login';

// Override css
import './app.css';

// Clase App principal
class App extends Component {
    render() {
        return (
            <div className='app-container'>
                {/* Notificaciones */}
                <ToastContainer position='bottom-left'
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                    transition={Slide}/> {/* Contenido */}
                <Switch> {/* Rutas maestras */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_MAGNITUDES
                    }
                    component={MaestrasMagnitudesPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_ICONOS
                    }
                    component={MaestrasIconosPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_FAMILIAS
                    }
                    component={MaestrasFamiliasPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_ENVASES
                    }
                    component={MaestrasEnvasesPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_COMPONENTESQUIMICOS
                    }
                    component={MaestrasComponentesQuimicosPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_CULTIVOS
                    }
                    component={MaestrasCultivosPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_ESTADOSFENOLOGICOS
                    }
                    component={MaestrasEstadosFenologicosPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.MAESTRAS_SOLUCIONES
                    }
                    component={MaestrasSolucionesPage}></AuthRoute>

                    {/* Rutas gestion de productos */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PRODUCTOS_GENERICOS_ADD
                    }
                    component={ProductosGenericosAddPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PRODUCTOS_GENERICOS_VIEW
                    }
                    component={ProductosGenericosViewPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PRODUCTOS_ACABADOS_ADD
                    }
                    component={ProductosAcabadosAddPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PRODUCTOS_ACABADOS_VIEW
                    }
                    component={ProductosAcabadosViewPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PRODUCTOS_EDIT + '/:tipo/:id'
                    }
                    component={ProductosEditPage}></AuthRoute>

                    {/* Rutas gestión de cultivos */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.CULTIVOS_VIEW
                    }
                    component={CultivosViewPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.CULTIVOS_DETAIL + '/:id'
                    }
                    component={CultivosDetailPage}></AuthRoute>

                    {/* Rutas gestión de ensayos */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.ENSAYOS_ADD
                    }
                    component={EnsayosAddPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.ENSAYOS_VIEW
                    }
                    component={EnsayosViewPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.ENSAYOS_DETAIL + '/:id'
                    }
                    component={EnsayosDetailPage}></AuthRoute>

                    {/* Rutas gestión de soluciones */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.SOLUCIONES_VIEW
                    }
                    component={SolucionesViewPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.SOLUCIONES_DETAIL + '/:id'
                    }
                    component={SolucionesDetailPage}></AuthRoute>
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.INFORME_VIEW
                    }
                    component={InformesViewPage}></AuthRoute>

                    {/* Rutas gestión de usuarios */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.PERFIL
                    }
                    component={PerfilPage}></AuthRoute>

                    {/* <AuthRoute path={APP_CONFIG.RUTAS.USUARIOS} component={UsuariosPage}></AuthRoute> */}
                    {/* <Route path={APP_CONFIG.RUTAS.LOGIN_CLAVE_RESTAURAR} component={RestaurarClavePage}></Route> */}
                    {/* <Route path={APP_CONFIG.RUTAS.LOGIN_CLAVE_RECUPERAR} component={RecuperarClavePage}></Route> */}
                    <AuthRoute path={
                        APP_CONFIG.RUTAS.USUARIOS
                    }
                    component={LoginPage}></AuthRoute>
                    <Route path={
                        APP_CONFIG.RUTAS.LOGIN_CLAVE_RESTAURAR
                    }
                    component={LoginPage}></Route>
                    <Route path={
                        APP_CONFIG.RUTAS.LOGIN_CLAVE_RECUPERAR
                    }
                    component={LoginPage}></Route>
                    <Route path={
                        APP_CONFIG.RUTAS.LOGOUT
                    }
                    component={LogoutPage}></Route>
                    <Route path={
                        APP_CONFIG.RUTAS.LOGIN
                    }
                    component={LoginPage}></Route>

                    <AuthRoute path={
                        APP_CONFIG.RUTAS.HOME
                    }
                    component={PrincipalPage}></AuthRoute>
                </Switch>
            </div>
        );
    }
}

export default App;
