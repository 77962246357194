import React, { Component } from 'react';
import './index.css';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class BreadCrumbBiovert extends Component {

    calculaSeparadores(){
        let result = [];
        for (let i = 0; i < this.props.value.length; ++i){
            if (i === this.props.value.length - 1) {
                result.push(
                    <div key={btoa(this.props.value[i])} className='breadcrumb-biovert-active'>
                        {this.props.value[i]}
                    </div>
                );
            }
            else {
                result.push(
                    <div key={btoa(this.props.value[i])} className='breadcrumb-biovert-default'>
                        {this.props.value[i]}&nbsp;&nbsp;<FontAwesome name='angle-right'/>&nbsp;&nbsp;
                    </div>
                );
            }
        }
        return result;
    }

    render() {
        if (Array.isArray(this.props.value) && this.props.value.length > 0){
            return (
                <div className='breadcrumb-biovert'> {this.calculaSeparadores()}</div>
            );
        }
        else return ('');
    }

}

export default BreadCrumbBiovert;
