import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../services/AuthService';
import { APP_CONFIG } from '../utils/globales.js';

const AuthRoute = ref => (
    !authService.isAuthenticated() ?
        (
            <Redirect
                to={{
                    pathname: APP_CONFIG.RUTAS.LOGIN,
                }}
            />
        ) : (
            <Route
                path={ref.path}
                component={ref.component}
                render={ref.render}
            />
        )
);

export default AuthRoute;
