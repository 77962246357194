import React, { Component } from 'react';
import ApiService from '../../../services/Api.js';
import { APP_CONFIG, ACCION_SELECCIONADA } from '../../../utils/globales.js';
import './index.css';

// Componentes
import PaginaBiovert from '../../../components/pagina-biovert';
import BreadCrumbBiovert from '../../../components/breadcrumb-biovert';
import TablaBiovert from '../../../components/tabla-biovert';
import SelectIdioma from '../../../components/select-idioma';
import HeaderBiovert from '../../../components/header-biovert';
import ButtonBiovert from '../../../components/button-biovert';
import RawHTML from '../../../components/raw-html';
import GreyboxBiovert from '../../../components/greybox-biovert';
import SeccionAceptarCancelarBiovert from '../../../components/seccion-aceptar-cancelar-biovert';
import SeccionImagenBiovert from '../../../components/seccion-imagen-biovert';

// Notificaciones
import { toast } from 'react-toastify';

// Fontawesome
import FontAwesome from 'react-fontawesome';

class MaestrasFamiliasPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            listaDeFamilias: null,
            idFamilia: null,
            familia: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                descripcion: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                imagen: null,
            },
            imagenAux: null,
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
        };
    }

    componentDidMount(){
        //Obtenemos la lista de familias
        this.getFamilias();
    }

    // Get familias de la base de datos
    getFamilias(){
        let requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: 'GET',
            authorized: true,
            contentType: 'application/json'
        };
        ApiService.request(requestData)
            .then((response) => {
                this.setState({ listaDeFamilias: JSON.parse(response) });
            })
            .catch((error) => {
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Navegar dentro de la página
    nav2PageList = () => this.setState({ accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST });

    nav2PageAdd = () => this.setState({
        accionSeleccionada: ACCION_SELECCIONADA.ACCION_ADD,
        idFamilia: null,
        familia: {
            nombre: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
            descripcion: {
                [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
            },
        },
        imagenAux: null,
        idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE
    });

    // Seleccionar idioma
    seleccionarIdioma = idioma => this.setState({ idiomaSeleccionado: idioma });

    // Formulario gestionar cambios
    handleChangeNombre = event => {
        const s = this.state;
        s.familia.nombre[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    handleChangeDescripcion = event => {
        const s = this.state;
        s.familia.descripcion[this.state.idiomaSeleccionado] = event.target.value;
        this.setState(s);
    }

    handleChangeColor = event => {
        const s = this.state;
        s.familia.color = event.target.value;
        this.setState(s);
    }

    handleChangePrioridad = event => {
        const s = this.state;
        s.familia.prioridad = event.target.value;
        this.setState(s);
    }

    handleChangeImagen = event => {
        let file = event.target.files[0];
        if (Number(file.size) > APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES){
            toast.warning(
                <div>
                    <FontAwesome name='times'/>&nbsp;<span>El archivo seleccionado es demasiado grande (límite {APP_CONFIG.MAX_UPLOAD_MEDIA_SIZE_BYTES / 1000000} MB)</span>
                </div>
            );
        } else {
            this.setState({ imagenAux: file });
        }
    }

    handleChangeEliminarImagen = () => {
        this.setState({ imagenAux: 'delete' });
    }

    // Guardar familia
    guardarFormularioFamilia = () => {
        // Comprobamos campo no vacio
        if (this.state.familia.nombre[this.state.idiomaSeleccionado] == null
          || 0 >= this.state.familia.nombre[this.state.idiomaSeleccionado].length) return true;

        // Llamamos al endpoint
        const requestData = {
            endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS,
            method: '',
            authorized: true,
            contentType: 'application/json',
            data: this.state.familia
        };

        if (null === this.state.idFamilia){
            // Si es nuevo
            requestData.method = 'POST';
        } else {
            // Si estamos editando
            requestData.endpoint = requestData.endpoint + this.state.idFamilia + '/';
            requestData.method = 'PUT';
        }
        ApiService.request(requestData)
            .then(response => {
                this.compruebaImagenes(Number(JSON.parse(response).id));
            })
            .catch((error) => {
                if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al guardar la familia</span></div>);
                else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                console.log('Error: ' + JSON.stringify(error));
            });
    }

    // Comprobamos si hay imagen a guardar
    compruebaImagenes = id => {

        // Comprobamos si hay que eliminar
        if ('delete' === this.state.imagenAux){

            // Llamamos al endpoint para borrar imagen
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS + id + '/delete_imagen/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    console.log('Error: ' + JSON.stringify(error));
                });

        } else if (null !== this.state.imagenAux) {

            // Upload imagen
            const formData = new FormData();
            formData.append('imagen', this.state.imagenAux);

            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS + id + '/upload_imagen/',
                method: 'POST',
                authorized: true,
                contentType: '',
                data: formData
            };
            ApiService.request(requestData)
                .then(() => {
                    this.resultadoGuardarOk();
                })
                .catch((error) => {
                    toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        } else this.resultadoGuardarOk();

    }

    // Resultado de guardar
    resultadoGuardarOk = () => {
        this.getFamilias();
        toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Familia guardada con éxito</span></div>);
        this.setState({
            idFamilia: null,
            familia: {
                nombre: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                descripcion: {
                    [APP_CONFIG.DEFAULT_SELECTED_LOCALE]: '',
                },
                imagen: null,
            },
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_LIST
        });
    }

    // Editar familia
    editarFamiliaCallback = (event) => {return this.editarFamilia(Number(event.target.id));}

    editarFamilia = (id) => {
        // Cargamos los datos del familia
        let auxFamilia = null;
        for (let i = 0; i < this.state.listaDeFamilias.length; ++i){
            if (this.state.listaDeFamilias[i].id === id){
                auxFamilia = JSON.parse(JSON.stringify(this.state.listaDeFamilias[i]));
                break;
            }
        }
        this.setState({
            accionSeleccionada: ACCION_SELECCIONADA.ACCION_EDIT,
            idiomaSeleccionado: APP_CONFIG.DEFAULT_SELECTED_LOCALE,
            idFamilia: id,
            familia: auxFamilia,
            imagenAux: null,
        });
    }

    // Eliminar familia
    eliminarFamiliaCallback = (event) => {
        let id = event.target.id;

        // Get información del elemento a eliminar
        let nombre = null;
        for (let i = 0; i < this.state.listaDeFamilias.length; ++i){
            if (this.state.listaDeFamilias[i].id === Number(id)){
                nombre = this.state.listaDeFamilias[i].nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE];
                break;
            }
        }
        if (null === nombre) return true;
        if (window.confirm('¿Desea eliminar la familia ' + nombre + '?')){
            let requestData = {
                endpoint: APP_CONFIG.ENDPOINTS.FAMILIAS + id + '/',
                method: 'DELETE',
                authorized: true,
                contentType: 'application/json'
            };
            ApiService.request(requestData)
                .then(() => {
                    toast.success(<div><FontAwesome name='check'/>&nbsp;<span>Familia eliminada con éxito</span></div>);
                    this.getFamilias();
                })
                .catch((error) => {
                    if (undefined === error.detail) toast.error(<div><FontAwesome name='times'/>&nbsp;<span>Se ha producido un error al eliminar la familia</span></div>);
                    else toast.error(<div><FontAwesome name='times'/>&nbsp;<span>{error.detail}</span></div>);
                    console.log('Error: ' + JSON.stringify(error));
                });
        }
    };

    calculaContenidoParaTabla(){
        // Si no hay datos
        if (this.state.listaDeFamilias == null) return [];
        // Contenido
        return this.state.listaDeFamilias.map(item =>
            ({
                id: item.id,
                fields: [ item.imagen, item.nombre[APP_CONFIG.DEFAULT_SELECTED_LOCALE], item.prioridad.toString() ],
                acciones: [
                    { label: 'Editar', callback: this.editarFamiliaCallback },
                    { label: 'Eliminar', callback: this.eliminarFamiliaCallback }
                ]
            })
        );
    }

    calculaImagenMostrar(){
        if ('delete' === this.state.imagenAux) return '';
        if (null === this.state.imagenAux) return this.state.familia.imagen;
    }

    renderListaDeElementos(){
        return ([
            <div key='elemento_lista' className='familia-container'>
                {/* Breadcrumb */}
                <BreadCrumbBiovert value = {[ 'Maestras', 'Familias' ]}/>

                {/* Titulo + boton add */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'flex-end' }}>
                    <HeaderBiovert type='t1'>Familias</HeaderBiovert>
                    <ButtonBiovert type='primary' label='Añadir familia' handleClick={this.nav2PageAdd}/>
                </div>

                <br></br>
                <TablaBiovert cabeceras={[ 'Imagen', 'Nombre', 'Prioridad' ]} contenido={this.calculaContenidoParaTabla()} onRowClick={this.editarFamilia}/>
            </div>
        ]);
    }

    renderFormulario(){

        if (null === this.state.familia) return (<div key='eci'> Error cargando la familia</div>);

        // Values
        return ([
            <div key='familia_add_edit' className='familia-container'>

                {/* Breadcrumb + seleccionar idioma */}
                <div className='familia-breadcrumb-seleccion-idioma'>
                    {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                        ? <BreadCrumbBiovert value = {[ 'Maestras', 'Familias', 'Nueva familia' ]}/>
                        : <BreadCrumbBiovert value = {[ 'Maestras', 'Familias', 'Editar familia' ]}/>
                    }
                    <SelectIdioma onSelect={this.seleccionarIdioma}/>
                </div>

                {/* Titulo */}
                {this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD
                    ? <HeaderBiovert type='t1'>Nueva familia</HeaderBiovert>
                    : <HeaderBiovert type='t1'><RawHTML>{this.state.familia.nombre[this.state.idiomaSeleccionado] || '&nbsp;'}</RawHTML></HeaderBiovert>
                }

                {/* Formulario */}
                <GreyboxBiovert>
                    <div className='familia-formulario'>
                        <div className='familia-form-lbl-nombre'>
                            Nombre familia
                        </div>
                        <div className='familia-form-nombre'>
                            <input
                                id='familia-add-edit'
                                type='text'
                                value={this.state.familia.nombre[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeNombre}
                                size='50'
                                className='familia-add-edit-text-input'
                            />
                        </div>

                        <div className='familia-form-grupo-h'>
                            <div className='familia-form-grupo-v'>
                                <div className='familia-form-lbl-nombre'>Color familia</div>
                                <div className='familia-form-nombre'>
                                    <input
                                        id='familia-add-edit'
                                        type='text'
                                        value={this.state.familia.color || ''}
                                        onChange={this.handleChangeColor}
                                        maxLength='7'
                                        size='10'
                                        className='familia-add-edit-text-input'
                                    />
                                </div>
                            </div>
                            <div className='familia-form-grupo-v'>
                                <div className='familia-form-lbl-nombre'>Prioridad</div>
                                <div className='familia-form-nombre'>
                                    <input
                                        id='familia-add-edit'
                                        type='number'
                                        value={this.state.familia.prioridad}
                                        onChange={this.handleChangePrioridad}
                                        maxLength='7'
                                        size='10'
                                        className='familia-add-edit-text-input'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='familia-form-lbl-nombre'>Descripción familia</div>
                        <div className='familia-form-nombre'>
                            <textarea
                                type='text'
                                value={this.state.familia.descripcion[this.state.idiomaSeleccionado] || ''}
                                onChange={this.handleChangeDescripcion}
                                className='familia-add-edit-textarea-input'
                                placeholder='Descripción'
                            />
                        </div>

                    </div>
                </GreyboxBiovert>

                <SeccionImagenBiovert
                    titulo='Imagen de la familia'
                    urlImagen={this.calculaImagenMostrar()}
                    onDelete={this.handleChangeEliminarImagen}
                    onUpload={this.handleChangeImagen}/>

                <SeccionAceptarCancelarBiovert onCancel={this.nav2PageList} onAccept={this.guardarFormularioFamilia}/>

            </div>
        ]);
    }

    render() {
        //Vars
        let pagina = [];

        // Añadimos los componentes en funcion de la acción seleccionada
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_LIST) pagina.push(this.renderListaDeElementos());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_ADD) pagina.push(this.renderFormulario());
        if (this.state.accionSeleccionada === ACCION_SELECCIONADA.ACCION_EDIT) pagina.push(this.renderFormulario());

        return (<PaginaBiovert>{pagina}</PaginaBiovert>);
    }

}

export default MaestrasFamiliasPage;
